<form [formGroup]="actulDateOfTxnFrm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mt-2">
                        <!-- <div class="form-group col-xl-3">
                            <strong class="col-form-label">Material By</strong><span class="text-danger"><sup>*</sup></span>
                            <div class="form-check form-check-inline mt-2 p-l-25">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="badge" formControlName="isMatBdge"
                                    (click)="onClkRdb('productBadgeId')" [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="name" formControlName="isMatBdge"
                                    (click)="onClkRdb('productId')" [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div *ngIf="isMatBdge=='badge'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                    [data]="lstMatTags" formControlName="productBadgeId"
                                    (onSelect)="onMultiSlct('s', 'n', $event)" (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'n', $event)" (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.productBadgeId.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.productBadgeId.errors" class="invalid-feedback">
                                    <div *ngIf="fc.productBadgeId.errors?.required">Material tag is required</div>
                                </div>
                            </div>
                            <div *ngIf="isMatBdge=='name'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats"
                                    [(ngModel)]="slctdMatName" formControlName="productId"
                                    (onSelect)="onMultiSlct('s', 'm', $event)" (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'm', $event)" (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                                    <div *ngIf="fc.productId.errors?.required">Material name is required</div>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group col-md-4_5 mt-1">
                            <strong class="col-form-label">Material By<span class="text-danger"><sup>*</sup></span></strong>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="category"
                                    [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('category')"
                                    [checked]="isMatBdge=='category'" formControlName="isMatBdge">
                                <label class="form-check-label" for="mat">Category</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="badge"
                                    [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('productBadgeId')"
                                    formControlName="isMatBdge" [checked]="isMatBdge=='badge'">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="name"
                                    [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('productId')"
                                    [checked]="isMatBdge=='name'" formControlName="isMatBdge">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div class="">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="stkMtrlCat"
                                    (onSelect)="onMultiSlct('s', 'c', $event)" (onSelectAll)="onMultiSlct('a', 'c', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'c', $event)" (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                                    [data]="lstMatMainCtgrs" *ngIf="isMatBdge=='category'"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.stkMtrlCat.errors }">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                    (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags" *ngIf="isMatBdge=='badge'"
                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="productBadgeId"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.productBadgeId.errors }">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats"
                                    (onSelect)="onMultiSlct('s', 'm', $event)" *ngIf="isMatBdge=='name'"
                                    (onSelectAll)="onMultiSlct('a', 'm', $event)" (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'm', $event)" formControlName="productId"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc?.stkMtrlCat?.errors && isMatBdge=='category'" class="invalid-feedback">
                                    <div *ngIf="fc?.stkMtrlCat?.errors?.required">Material category is required</div>
                                </div>
                                <div *ngIf="isSbmtd && fc?.productBadgeId?.errors && isMatBdge=='badge'" class="invalid-feedback">
                                    <div *ngIf="fc?.productBadgeId?.errors?.required">Material tag is required</div>
                                </div>
                                <div *ngIf="isSbmtd && fc?.productId?.errors && isMatBdge=='name'" class="invalid-feedback">
                                    <div *ngIf="fc?.productId?.errors?.required">Material name is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-xl-3 pt-3 mt-1">
                            <strong for="tagName"><b>Facility Category</b><span class="text-danger"><sup>*</sup></span></strong>
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                formControlName="storeBadgeId" (onSelect)="onMultiSlct('s', 'f', $event)"
                                (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)" [(ngModel)]="slctdFac"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.storeBadgeId.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.storeBadgeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeBadgeId.errors?.required">Facility Category is required</div>
                            </div>
                            <!--<span class="text-danger"><sup>*</sup></span>   [ngClass]="{ 'is-invalid': isSbmtd && fc.storeBadgeId.errors }"
                                <div *ngIf="isSbmtd && fc.storeBadgeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeBadgeId.errors?.required">Facility tag is required</div>
                            </div> -->
                        </div>
                        <div class="col-xl-3 form-group pt-3 mt-1">
                            <strong for="textBox">Transaction Type</strong>
                            <select id="inputState" class="form-select form-select-sm" [(ngModel)]="txnType" formControlName="transType">
                                <option value="null">All</option>
                                <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}</option>
                            </select>
                        </div>
                        <div class="col-xl-3 form-group pt-3">
                                <strong >Date of actual transaction<span class="text-danger"><sup>*</sup></span></strong>
                            <div class="input-group">    
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', containerClass:
                                        'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' placeholder="Select Date" name="dop"
                                    id="datePickValue" #dop formControlName="todate" [maxDate]="dt2day" ([ngModel])="frmDate"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.todate.errors }"> <!--  [dateCustomClasses]="cstmDt" -->
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"><i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('todate')"><i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <div *ngIf="isSbmtd && fc.todate.errors" class="invalid-feedback">
                                    <div *ngIf="fc.todate.errors?.required">Date of actual transaction is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-12 text-end pt-4 mt-2">
                            <button class="btn btn-light btn-sm" (click)="crntPage=1;isSbmtd=false;isExprt=true;lstGrid = [];
                                this.lstDTs=[];onReset()">Reset</button> &nbsp;
                            <button class="btn btn-sm btn-primary" (click)="crntPage=1;isSbmtd=true;isExprt=false;locId='';isCcpLvl = false;
                                isBredCrumNatnl='';isBredCrumState='';isBredCrumDist='';isDstLvl = false;isBackToState =true;
                                isBackToCountry =true;LoadGrid('a')">Filter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <!-- <div class="row mt-3">
        <div class="form-group col-xl-6">
            <h5>India</h5>
        </div> 
        <div class="col-xl-6 text-end">
            <div class="float-xl-end">
                <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                    <li class="nav-item pt-1">As of 22/4/21 2:31 PM </li>
                    <li class="nav-item"><a class="nav-link btn btn-sm" id="Map-1-tab" data-bs-toggle="tab"
                            href="#Map-1" role="tab" aria-controls="Map-1" aria-selected="false"><i
                                class="fa fa-refresh" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
    </div> -->
    <div class="row" *ngIf="lstGrid?.length!=0">
        <div class="col-xl-12 mb-1">
            <div class="row">
                <div class="col-xl-4">
                    <button class="btn btn-sm btn-success" (click)="isExprt=false;onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
                <div class="col-xl-8">
                    <nav aria-label="breadcrumb" class="float-end">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" *ngIf="isBredCrumNatnl !='' && slctDmnDetails?.districtId == null"
                                (click)="crntPage=1;isBredCrumState=''; isBredCrumDist='';locId='';isCcpLvl = false;
                                isDstLvl = false;this.isBackToState =true;LoadGrid('a')"><a>{{isBredCrumNatnl}}</a></li>
                            <li id="isAct" class="breadcrumb-item" [ngClass]="[ isBredCrumDist =='' ? 'active' : '']"
                                *ngIf="isBredCrumState !='' && (slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null)"
                                (click)="crntPage=1;isBredCrumDist='';isCcpLvl=false;LoadGrid('s');">
                                <a>{{isBredCrumState}}</a></li>
                            <li class="breadcrumb-item active" aria-current="page"
                                *ngIf="isBredCrumDist !=''">{{isBredCrumDist}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <div class="col-xl-6" >
            <div *ngIf="lstGrid?.length!=0" class="mt-1">{{pageEntrs}}</div>
        </div>
        <div class="col-xl-6" >
            <div class="float-end" *ngIf="lstGrid?.length!=0">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="lstGrid?.length !=0">
        <div class="col-xl-12 col-sm-12  mt-1 table-responsive table_spacing">
            <table class="table table-sm table-bordered table-hover border-primary f-14"
                style="min-width: 300;">
                <thead class="bg-light">
                    <tr>
                        <th colspan="24" class="text-center font-weight-bold">Actual Date of Transaction</th>
                    </tr>
                    <tr>
                        <th class="text-center" rowspan="2" valign="middle">Item No.</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId ==''">State</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId !='' && isCcpLvl== true && isDstLvl == false">District</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId !='' && isCcpLvl== true && isDstLvl == true">Facility</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId == null) && locId ==''">District</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId == null) && locId !=''" >Facility</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId != null)">Facility</th>
                        <th class="text-center" rowspan="2" valign="middle" *ngIf="isBredCrumDist != ''">Facility Category</th>
                        <th *ngFor="let dt of lstDTs" colspan="3" class="text-center">{{dt | date: 'dd-MM-YYYY'}}</th>
                    </tr>
                    <tr>                        
                        <th *ngFor="let x of lstDTs" colspan="3">
                            <table class="table table-sm table-bordered border-0">
                                <tr class="border-top-0 border-0">
                                    <th class="border-bottom-0 border-top-0">1d</th>
                                    <th class="border-bottom-0 border-top-0">2d</th>
                                    <th class="border-bottom-0 border-top-0">>2d</th>
                                </tr>
                            </table>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of lstTtl">
                        <td class="text-center"></td>
                        <td class="text-center"><b>TOTAL</b></td>
                        <td class="text-center" *ngIf="isBredCrumDist != ''"></td>
                        <td class="text-center" [title]="item.lstTTTs[k]" *ngFor="let val of item.lstVals; let k = index"><b>{{val}}</b></td>
                    </tr>
                    <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems }; let i=index">
                        <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                        <td class="text-center"><a (click)="onLocDetails(item.name, item.id, $event)">{{item.name}}</a></td>
                        <td class="text-center" *ngIf="isBredCrumDist != ''">{{item.badgeName}}</td>
                        <td class="text-center p-0" [title]="item.lstTTTs[j].values" *ngFor="let val of item.lstVals; let j = index"
                            [ngStyle]="{ 'backgroundColor': ((item.lstTTTs[j].clrType)%2==0) ? '#ddd' : '#ffffff' }">{{val}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="pb-3 pt-3" >
        <div class="row" *ngIf="lstGrid?.length!=0">
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</form>