import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-facility-create',
  templateUrl: './facility-create.component.html',
  styleUrls: ['./facility-create.component.css']
})

export class FacilityCreateComponent extends BaseComponent implements OnInit {
  
  usrLvl = ''; dupMsg = ''; selectedOwners: any; usrDetails :any[] = []; lst_Tag = ''; facilityCreateForm = this.formBuilder.group({
    address: [''], block_id: [null], city: ['', Validators.required], country_id: ['', Validators.required], id: [''], district_id: [null],
    name: ['', Validators.required], pin: ['', [Validators.minLength(6), Validators.maxLength(6)]], state_id: [null, Validators.required],
    store_badges: ['', Validators.required], store_users: ['', Validators.required], latitude: [''], longitude: [''] }); strsId = '';
  lstFacTags: any[] = []; dropdownSettings = {}; slctdIncldFac: any[] = []; isSbmtd = false; cntryLst: any[] = []; pranthIds:any; usrId:any
  stsLst: any[] = []; blkLst: any[] = []; userLst: any[] = []; slctdusers: any[] = []; userDropdown = {}; dstrtLst: any[] = [];
  geoLoc: any[] = [];

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.cntryLst = s.cntriesLst, this.stsLst = s.statesLst;}); 
      this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility);
    });

    this.glblSvc.sbjctFacStrId.subscribe(s => { this.dupMsg = '';
      if (s != '')
        this.editStrDtls(s)
      else {
        this.facilityCreateForm.reset(), this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } }),
          setTimeout(() => { this.locBinding(); }, 1000);
      }
    });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.LoadUsers(), this.dropdowns(); //this.locBinding();
  }

  get fc() { return this.facilityCreateForm.controls; }
  get fgrv() { return this.facilityCreateForm.getRawValue(); }

  locBinding(){
    this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst, this.stsLst = s.statesLst; });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};      
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.facilityCreateForm.get('country_id')?.setValue(cntryId);
      this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
    }
  }

  onChngFN(evnt: any) {
    let facName: string = evnt?.target?.value ?? '';
    if (facName != '') {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.chkDupFacNme + facName, null, 7, true, 2).
          then(() => {
        if (this.result) {
          if (this.result?.message == 'Store already exists with this name')
            this.dupMsg = `${this.result?.message} '${facName}'`, this.facilityCreateForm.get('name')?.setValue('');
        }
      })
    }
  }

  getGeoCds() {
    this.geoLoc = [];
    if (this.facilityCreateForm.get('state_id')?.value != 'null' && this.facilityCreateForm.get('state_id')?.value != null && 
      this.facilityCreateForm.get('district_id')?.value != 'null' && this.facilityCreateForm.get('district_id')?.value != null && 
      this.facilityCreateForm.get('city')?.value != '' && this.facilityCreateForm.get('city')?.value != null) {
      let payLoad = this.facilityCreateForm.getRawValue(), ste: any = '', dst: any = '';
        this.stsLst.length !=0 ? (this.stsLst.filter((s: any) => { if (s.id == payLoad.state_id) ste = s.name})) : '';
        this.dstrtLst.length !=0 ? (this.dstrtLst.filter((s: any) => { if (s.districtid == payLoad.district_id) dst=s.districtname})) : '';
      let adrs = `India,${ste},${dst},${payLoad.city}`;
      this.glblSvc.GetGeoCds(adrs).subscribe((s: any) =>{
        this.geoLoc = s.results ?? [];
        if (this.geoLoc.length !=0) {
          this.facilityCreateForm.get('latitude')?.setValue(this.geoLoc[0].geometry?.location?.lat),
          this.facilityCreateForm.get('longitude')?.setValue(this.geoLoc[0].geometry?.location?.lng);
        }
      })
    } else {
      this.glblSvc.onTstr('w', 'Please Select State, District and Village');
    }
    
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.facilityCreateForm.get('state_id')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.facilityCreateForm.get('district_id')?.setValue('null'),
          this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.facilityCreateForm.get('state_id')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onNestedSlct(+this.
          slctDmnDetails?.stateId ?? null, 'state', true), this.onNestedSlct(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).
        then(() => { setTimeout(() => { this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('district_id')?.
          setValue(+this.slctDmnDetails?.districtId ?? 'null'), this.facilityCreateForm.get('block_id')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
        });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onNestedSlct(+this.slctDmnDetails?.
          districtId ?? null, 'district', true)]).then(() => { this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.
          get('state_id')?.setValue(this.slctDmnDetails?.stateId ?? null), this.facilityCreateForm.get('district_id')?.setValue(this.
            slctDmnDetails?.districtId ?? 'null'), this.facilityCreateForm.get('block_id')?.setValue(blockID ?? null), setTimeout(() => {
            this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
        });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.facilityCreateForm.get('state_id')?.setValue('null'), this.facilityCreateForm.get('district_id')?.setValue('null'),
          this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  dropdowns() {
    this.dropdownSettings = {
      singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
      unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
    }, this.userDropdown = {
      singleSelection: false,
      idField: 'id', textField: 'userData', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'user') {
      if (evntType == 'os')
        this.slctdusers.push({ user_id: item.id })
      else if (evntType == 'osa')
        this.slctdusers = [], item.filter((a: any) => this.slctdusers.push({ user_id: a.id }));
      else if (evntType == 'ds')
        this.slctdusers = this.slctdusers.filter(s => s.user_id != item.id)
      else
        this.slctdusers = [];
    }
    if (type == 'badge') {
      if (evntType == 'os')
        this.slctdIncldFac.push({ badge_id: item.id })
      else if (evntType == 'osa')
        this.slctdIncldFac = [], item.filter((a: any) => this.slctdIncldFac.push({ badge_id: a.id }));
      else if (evntType == 'ds')
        this.slctdIncldFac = this.slctdIncldFac.filter(s => s.badge_id != item.id)
      else
        this.slctdIncldFac = [];
    }
  }

  // onItemSelect(type: any, item: any) {
  //   if (type == 'user')
  //     this.slctdusers.push({ user_id: item.id })
  //   else
  //     this.slctdIncldFac.push({ badge_id: item.id })
  // }

  // onSelectAll(type: any, items: any) {
  //   if (type == 'user')
  //     items.filter((a: any) => this.slctdusers.push({ user_id: a.id }));
  //   else
  //     items.filter((a: any) => this.slctdIncldFac.push({ badge_id: a.id }));
  // }

  LoadUsers() {
    let myUserData: any[] = [];
    this.userLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.str, environment.apiMthds.getUsrsByPrnth.replace('pranthid', `${this.chsnDmnId}`), null).then(() => {
      if (this.result) {
        if (this.result.data)
          myUserData = this.result.data != null ? this.result.data : [],
            this.userLst = myUserData.map(e => ({ ...e, userData: e.userName + " - " + e.userId }));
      }
    })
  }

  onNestedSlct($event: any, type: any, isId: boolean = false) {
    let cid = '', sid = '', did = '', fId = '';
    if (type == 'country')
      cid = isId ? $event : $event.target.value, fId = isId ? $event : $event.target.value;
    else if (type == 'state')
      sid = isId ? $event : $event.target.value, fId = isId ? $event : $event.target.value;
    else
      did = isId ? $event : $event.target.value, fId = isId ? $event : $event.target.value;
    if (fId != 'null') {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.
        getStates.replace('ccc', cid) : (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) :
          environment.apiMthds.getBlocks.replace('ddd', did))), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'country')
              this.stsLst = this.stsLst.length == 0 ? (this.result.data != null ? this.result.data : []) : this.stsLst;
            else if (type == 'state')
              this.dstrtLst = this.result.data != null ? this.result.data : [], this.facilityCreateForm.get('district_id')?.
                setValue(null), this.facilityCreateForm.get('district_id')?.enable(), this.facilityCreateForm.get('block_id')?.
                disable(), this.facilityCreateForm.get('block_id')?.setValue(null);
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.facilityCreateForm.get('block_id')?.enable(),
                this.facilityCreateForm.get('block_id')?.setValue(null);
          }
        }
      });
    }
    else {
      if (type == 'country')
        this.stsLst = [];
      else if (type == 'state')
        this.dstrtLst = [], this.blkLst = [], this.facilityCreateForm.get('district_id')?.setValue(null), this.facilityCreateForm.
          get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.disable(), this.facilityCreateForm.get('block_id')?.
          setValue(null);
      else
        this.blkLst = [], this.facilityCreateForm.get('block_id')?.disable(), this.facilityCreateForm.get('block_id')?.setValue(null);
    }
    this.facilityCreateForm.get('latitude')?.setValue(''), this.facilityCreateForm.get('longitude')?.setValue('');
  }

  cancel() {
    this.glblSvc.sbjctFacStrId.next(''); this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } });
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
  }

  saveFacilities() {
    this.checkFormValidations(this.facilityCreateForm);
    if (this.facilityCreateForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.facilityCreateForm.getRawValue(), badge = [];
      if (payLoad.store_badges != '')
        badge.push({ badge_id: +payLoad.store_badges });
      payLoad.block_id = (payLoad.block_id != null && payLoad.block_id != '') ? +payLoad.block_id : null,
        payLoad.country_id = (payLoad.country_id != null && payLoad.country_id != '') ? +payLoad.country_id : null,
        payLoad.district_id = (payLoad.district_id != null && payLoad.district_id != '') ? +payLoad.district_id : null,
        payLoad.state_id = (payLoad.state_id != null && payLoad.state_id != '') ? +payLoad.state_id : null,
        payLoad.store_users = this.slctdusers, payLoad.store_badges = payLoad.store_badges != '' ? badge : null, // this.slctdIncldFac,
        payLoad.user_id = this.usrLgnDtls.userId, payLoad.pranth_id = this.usrId ? this.pranthIds : this.chsnDmnId;
        this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.creatStr, payLoad, 7).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              localStorage.setItem('facltyCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message), this.pranthIds = '', this.usrId = '',
                this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    }
  }

  //Edit Store
  editStrDtls(strId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getByStrId + strId, null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          let resultData: any;
          resultData = this.result.data != null ? this.result.data : [], this.usrDetails = resultData, this.usrId = resultData?.userList != null ?? resultData?.userList[0]?.userId,
           this.pranthIds = resultData?.pranthId;
          if (resultData.length != 0) {
             if (resultData?.userList != null) 
                this.selectedOwners = resultData?.userList.map((e: { name: string; userId: string; }) => ({ ...e, userData: e.name + " - " + e.userId })),
                this.selectedOwners.filter((e: { id: any; }) => { this.slctdusers.push({ user_id: e.id }) });
            this.facilityCreateForm.patchValue({
              address: resultData?.address,
              store_badges: resultData?.storeBadgeId,
              //block_id: resultData?.blockId,
              city: resultData?.city,
              country_id: resultData?.countryId,
              //district_id: resultData?.districtId,
              name: resultData?.name,
              pin: resultData?.pin,
              state_id: resultData?.stateId,
              latitude: resultData?.latitude,
              longitude: resultData?.longitude,
              id: resultData?.id
            })
          }
          else
            this.glblSvc.onTstr('i', this.result.message);

          if (resultData?.stateId != null && resultData?.districtId == null) {
            Promise.all([this.onNestedSlct(resultData?.stateId ?? null, 'state', true)]).then(() => {
              this.facilityCreateForm.get('district_id')?.setValue(null), setTimeout(() => { this.facilityCreateForm.get('state_id')?.enable(),
                this.facilityCreateForm.get('district_id')?.enable(), this.facilityCreateForm.get('block_id')?.disable() }, 1000);
            });
          }
          if (resultData?.districtId != null) {
            Promise.all([this.onNestedSlct(resultData?.stateId ?? null, 'state', true),
              this.onNestedSlct(resultData?.districtId ?? null, 'district', true)]).then(() => {
              setTimeout(() => { this.facilityCreateForm.get('district_id')?.setValue(resultData?.districtId ?? null),
                this.facilityCreateForm.get('state_id')?.enable(), this.facilityCreateForm.get('district_id')?.enable(),
                this.facilityCreateForm.get('block_id')?.enable();
              }, 1000);
            });
          }

          setTimeout(() => {
            if (this.usrLgnDtls) {
              let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};      
              this.usrLvl = usrLvlDtls?.userLevel ?? '';
              if (this.usrLvl == 'National')
                this.facilityCreateForm.get('country_id')?.disable();
              if (this.usrLvl == 'State')
                this.facilityCreateForm.get('country_id')?.disable(), this.facilityCreateForm.get('state_id')?.disable();
              if (this.usrLvl == 'District') {
                this.facilityCreateForm.get('country_id')?.disable(), this.facilityCreateForm.get('state_id')?.disable();
                  this.facilityCreateForm.get('district_id')?.disable();
              }
              if (this.usrLvl == 'Block')
                this.facilityCreateForm.get('country_id')?.disable(), this.facilityCreateForm.get('state_id')?.disable(),
                  this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.disable();
            }
          }, 1500);
        
          // if (this.usrLgnDtls) {
          //   let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
          //     usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
          //   this.usrLvl = usrLvlDtls?.userLevel ?? '', this.facilityCreateForm.get('country')?.setValue(cntryId);
          //   if (this.usrLvl == 'National') {
          //     if (this.usrDetails[0]?.stateId != null && this.usrDetails[0]?.districtId == null) {
          //       this.facilityCreateForm.get('state_id')?.setValue(this.usrDetails[0]?.stateId ?? null),
          //         Promise.all([this.onNestedSlct(this.usrDetails[0]?.stateId ?? null, 'state', true)]).then(() => {
          //           this.facilityCreateForm.get('district_id')?.setValue(this.usrDetails[0]?.districtId ?? null),
          //             setTimeout(() => { this.facilityCreateForm.get('district_id')?.enable() }, 1000)
          //         });
          //     } else if (this.usrDetails[0]?.stateId != null && this.usrDetails[0]?.districtId != null && this.usrDetails[0]?.blockId == null) {
          //       this.facilityCreateForm.get('state_id')?.setValue(this.usrDetails[0]?.stateId ?? null), Promise.all([this.onNestedSlct(this.usrDetails[0]?.stateId ?? null,
          //         'state', true), this.onNestedSlct(this.usrDetails[0]?.districtId ?? null, 'district', true)]).then(() => {
          //           this.facilityCreateForm.get('district_id')?.setValue(this.usrDetails[0]?.districtId ?? null),
          //             setTimeout(() => {
          //               this.facilityCreateForm.get('state_id')?.enable(), this.facilityCreateForm.get('district_id')?.enable(),
          //                 this.facilityCreateForm.get('block_id')?.enable()
          //             }, 1000);
          //         });
          //     } else
          //       this.facilityCreateForm.get('state_id')?.setValue(this.usrDetails[0]?.stateId ?? null), Promise.all([this.onNestedSlct(this.usrDetails[0]?.stateId ?? null,
          //         'state', true), this.onNestedSlct(this.usrDetails[0]?.districtId ?? null, 'district', true)]).then(() => {
          //           this.facilityCreateForm.get('district_id')?.setValue(this.usrDetails[0]?.districtId ?? null),
          //             this.facilityCreateForm.get('block_id')?.setValue(this.usrDetails[0]?.blockId ?? null)
          //           setTimeout(() => {
          //             this.facilityCreateForm.get('state_id')?.enable(), this.facilityCreateForm.get('district_id')?.enable(),
          //               this.facilityCreateForm.get('block_id')?.enable()
          //           }, 1000);
          //         });
          //   }
          // // if (this.usrLvl == 'State')
          // //     this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null), this.facilityCreateForm.get('district_id')?.setValue(null),
          // //       this.onNestedSlct(usrLvlDtls?.stateId ?? null, 'state', true);
          // //   if (this.usrLvl == 'District') {
          // //     this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? 'null'), Promise.all([this.onNestedSlct(usrLvlDtls?.stateId ?? null,
          // //       'state', true), this.onNestedSlct(usrLvlDtls?.districtId ?? null, 'district', true)]).then(() => {
          // //         this.facilityCreateForm.get('district_id')?.setValue(usrLvlDtls?.districtId ?? 'null'),
          // //           setTimeout(() => { this.facilityCreateForm.get('district_id')?.disable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          // //       });
          // //   }
          // //   if (this.usrLvl == 'Block')
          // //     this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null), this.facilityCreateForm.get('district_id')?.setValue(usrLvlDtls?.districtId ?? null),
          // //       this.facilityCreateForm.get('block_id')?.setValue(usrLvlDtls?.blockId ?? null);
          //  }
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.facilityCreateForm.get('state_id')?.enable(), this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.facilityCreateForm.get('state_id')?.disable(), this.facilityCreateForm.get('district_id')?.enable(), this.facilityCreateForm.get('block_id')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.facilityCreateForm.get('state_id')?.disable(), this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.facilityCreateForm.get('state_id')?.disable(), this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.disable();
  }

  // locBinding() {
  //   if (this.usrLgnDtls) {
  //     let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
  //       usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
  //     this.usrLvl = usrLvlDtls?.userLevel ?? '', this.facilityCreateForm.get('country_id')?.setValue(cntryId);
  //     if (this.usrLvl == 'National')
  //       this.facilityCreateForm.get('country_id')?.setValue(cntryId ?? null), this.facilityCreateForm.get('country_id')?.disable(), this.onNestedSlct(cntryId ?? null, 'country', true);
  //     if (this.usrLvl == 'State')
  //       this.onNestedSlct(cntryId ?? null, 'country', true), this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null),
  //         this.onNestedSlct(usrLvlDtls?.stateId ?? null, 'state', true), this.facilityCreateForm.get('country_id')?.disable(),
  //         this.facilityCreateForm.get('state_id')?.disable();
  //     if (this.usrLvl == 'District') {
  //       /* this.onNestedSlct(cntryId ?? null, 'country', true), this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null),
  //         this.facilityCreateForm.get('country_id')?.disable(),
  //         this.facilityCreateForm.get('state_id')?.disable(), Promise.all([this.onNestedSlct(usrLvlDtls?.stateId ?? null,
  //           'state', true), this.onNestedSlct(usrLvlDtls?.districtId ?? null, 'district_id', true)]).then(() => {
  //             this.facilityCreateForm.get('district_id')?.setValue(usrLvlDtls?.districtId ?? null);
  //           });
  //       this.facilityCreateForm.get('district_id')?.disable() */
  //       this.facilityCreateForm.get('country_id')?.disable(), this.facilityCreateForm.get('state_id')?.disable(),
  //         this.facilityCreateForm.get('district_id')?.disable(), Promise.all([this.onNestedSlct(cntryId ?? null, 'country', true),
  //         this.onNestedSlct(usrLvlDtls?.stateId ?? null, 'state', true), this.onNestedSlct(usrLvlDtls?.districtId ?? null, 'district_id', true)]).
  //         then(() => {
  //           setTimeout(() => {
  //             this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null),
  //             this.facilityCreateForm.get('district_id')?.setValue(usrLvlDtls?.districtId ?? null);
  //           }, 500);
  //         });
  //     }
  //     if (this.usrLvl == 'Block')
  //       this.facilityCreateForm.get('state_id')?.setValue(usrLvlDtls?.stateId ?? null),
  //         this.facilityCreateForm.get('district_id')?.setValue(usrLvlDtls?.districtId ?? null),
  //         this.facilityCreateForm.get('block_id')?.setValue(usrLvlDtls?.blockId ?? null),
  //         this.facilityCreateForm.get('country_id')?.disable(), this.facilityCreateForm.get('state_id')?.disable(),
  //         this.facilityCreateForm.get('district_id')?.disable(), this.facilityCreateForm.get('block_id')?.disable();
  //   }
  // }
}