<div [formGroup]="overwFrm">
    <div class="row mt-1">
        <div class="col-xl-4 col-sm-12 col-xs-12 mb-2" style="position: relative;" *ngIf="dbStkPrmsn">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row mb-2">
                        <div class="col-xl-11 col-xs-12 mt-2">
                            <h5 class="mb-0">Stock Management </h5>
                        </div>
                        <div class="col-xl-1 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1"></div>
                                <div class="dropdown-basic" title="Filter">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample11"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1"
                                            (click)="onChkFltr('s')"> <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold" *ngIf="chsnInvFT!=''"><b>Facility Category(s):</b>
                                    {{chsnInvFT}}<br /></span>
                                <span class="font-weight-bold" *ngIf="chsnInvMTN!=''"><b>Material By {{isStkMatBdge == 'badge' ?
                                        'Tag' : 'Name'}}(s):</b> {{chsnInvMTN}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample11">
                                <div class="row col-xl-12">
                                    <div class="form-group col-xl-12 mt-2">
                                        <label for="tagName"><b>Facility Category:</b></label>
                                        <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="dfltStrTgsStk"
                                            [data]="lstFacTags" placeholder="" formControlName="stckFacCatId">
                                        </ng-multiselect-dropdown>
                                        <!-- (onSelect)="onChng($event, 'facTag', 'os')" (onSelectAll)="onChng($event, 'facTag', 'osa')"
                                                    (onDeSelect)="onChng($event, 'facTag', 'ds')" (onDeSelectAll)="onChng($event, 'facTag', 'dsa')" -->
                                    </div>
                                    <div class="form-group col-xl-12 mt-2">
                                        <span class="col-form-label"><b>Material By</b></span>&nbsp;
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="isStkMatBdge" value="badge"
                                                [(ngModel)]="isStkMatBdge" (click)="onClkRdb('materialBadge')"
                                                formControlName="isStkMatBdge" [checked]="isStkMatBdge=='badge'">
                                            <label class="form-check-label" for="mat">Tag</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="isStkMatBdge" value="name"
                                                [(ngModel)]="isStkMatBdge" (click)="onClkRdb('material')"
                                                [checked]="isStkMatBdge=='name'" formControlName="isStkMatBdge">
                                            <label class="form-check-label" for="mat">Name</label>
                                        </div>
                                        <div *ngIf="isStkMatBdge=='badge'">
                                            <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="slctdStkMat"
                                                placeholder="" (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags1"
                                                (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                                (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                                (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="stkMtrlBdge">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div *ngIf="isStkMatBdge=='name'">
                                            <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstAllMats" placeholder=""
                                                (onSelect)="onMultiSlct('s', 'f', $event)" [(ngModel)]="slctdStkMatName"
                                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="stkMtrlNme">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-sm-12 p-3">
                                    <div class="float-end">
                                        <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                            href="#multiCollapseExample11" role="button" aria-expanded="false"
                                            aria-controls="multiCollapseExample1" (click)="GetIndctrINV()">Filter</button>&nbsp;
                                        <button class="btn btn-sm btn-light" data-toggle="collapse"
                                            href="#multiCollapseExample11" role="button" aria-expanded="false"
                                            aria-controls="multiCollapseExample1" (click)="cnclFrm('stk')">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block p-3">
                    <div class="bar_chart">
                        <div class="progress mt-0 rounded">
                            <div *ngFor="let sm of lstIndSM; let i=index" role="progressbar" id="sm{{sm.label}}"
                                class="progress-bar shadow-sm progress-bar-striped progress-bar-animated pntr"
                                title="{{sm.toolText.split('<br>')}}" (click)="onClk(sm, 'sm')" style="width: 0%;"
                                [ngStyle]="{ 'backgroundColor': sm.color }">{{sm.value}}%</div>
                        </div>
                        <div class="mt-4 row">
                            <div class="col-xl-6 pntr" *ngFor="let sm of lstIndSM; let i=index">
                                <div [ngStyle]="{ 'color': sm.color }" class="pe-3" (click)="onClk(sm, 'sm')"
                                    title="{{sm.toolText.split('<br>')}}">
                                    <i class="fa fa-square" aria-hidden="true"></i>
                                    <span class="ms-2 small">{{sm.label}} ({{sm.value}}%)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ovrw-spnr text-center" *ngIf="stkIndcSpnr">
                <img [src]='ldrImg' id="spinner" /> <br>
                <p class="text-center"> <strong>Processing...</strong> </p>
            </div>
        </div>
        <div class="col-xl-4 col-sm-12 col-xs-12 mb-2" style="position: relative;" *ngIf="dbActPrmsn">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3  pr-4">
                    <div class="row mb-2">
                        <div class="col-xl-11 col-xs-12 mt-2">
                            <h5 class="mb-0">Activity </h5>
                        </div>
                        <div class="col-xl-1 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1"></div>
                                <div class="dropdown-basic" title="Filter">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample12"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold" *ngIf="chsnActFT!=''"><b>Facility Category(s):</b> {{chsnActFT}}<br /></span>
                                <span class="font-weight-bold" *ngIf="chsnActMTN!=''"><b>Material By {{isActvMatBdge == 'badge' ? 'Tag' : 'Name'}}(s):</b> {{chsnActMTN}}<br /></span>
                                <span class="font-weight-bold"><b>Period (since):</b>&nbsp;{{chsnPrd}}<br /></span>
                                <span class="font-weight-bold"><b>Txn Type:</b>&nbsp;{{chsnTxnType}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample12">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 px-2 border-0">
                                    <div class= "row col-xl-12">	
                                        <div class="form-group col-xl-12 mt-2">
                                            <label for="tagName"><b>Facility Category:</b></label>
                                            <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="dfltStrTgsAct"
                                                [data]="lstFacTags" placeholder="" formControlName="actvtyFacCatID">
                                            </ng-multiselect-dropdown>
                                            <!-- (onSelect)="onChng($event, 'facTag', 'os')" (onSelectAll)="onChng($event, 'facTag', 'osa')"
                                                (onDeSelect)="onChng($event, 'facTag', 'ds')" (onDeSelectAll)="onChng($event, 'facTag', 'dsa')" -->
                                        </div>				
                                        <div class="form-group col-xl-12 mt-2">
                                            <span class="col-form-label"><b>Material By</b></span>&nbsp;
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isActvMatBdge" value="badge" [(ngModel)]="isActvMatBdge"
                                                    (click)="onClkRdb('materialBadge')" [checked]="isActvMatBdge=='badge'" formControlName="isActvMatBdge">
                                                <label class="form-check-label" for="mat">Tag</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isActvMatBdge" value="name" [(ngModel)]="isActvMatBdge"
                                                    (click)="onClkRdb('material')" [checked]="isActvMatBdge=='name'" formControlName="isActvMatBdge">
                                                <label class="form-check-label" for="mat">Name</label>
                                            </div>
                                            <div *ngIf="isActvMatBdge=='badge'">
                                                <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="slctdActvtyMat" placeholder=""
                                                    (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags1"
                                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="actvtyMaterialBadge">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="isActvMatBdge=='name'">
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstAllMats" placeholder=""
                                                    (onSelect)="onMultiSlct('s', 'f', $event)" [(ngModel)]="slctdActvtyMatName"
                                                    (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                    (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="actvtyMaterialName">
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group col-xl-7 mt-2">
                                            <label for="textBox"><b>Date</b></label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" placeholder="Date"
                                                    bsDatepicker class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                                    onpaste="return false" [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                                    [maxDate]="dt2day" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                    onkeypress='return event.charCode == 8' formControlName="expireBefore" readonly>
                                                <div class="input-group-append" style="z-index: 0;">
                                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                        [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date" id="cdEB"
                                                        (click)="dpED.hide();clearBSDate('expireBefore')"> <i class="fa fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="form-group col-xl-6 mt-2">
                                            <label class="textBox" for="flexCheckDefault"><b>Period (since)</b></label>
                                            <select id="inputState" class="form-select form-select-sm" formControlName="actvtyRatePeriod">
                                                <!-- <option value="">Now</option> -->
                                                <option value="7">7 Days</option>
                                                <option value="15">15 Days</option>
                                                <option value="30">30 Days</option>
                                                <option value="3m">3 Months</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-6 col-sm-12 mt-2">
                                            <label class="textBox" for="flexCheckDefault"><b>Txn Type</b></label>
                                            <select id="inputState" class="form-select form-select-sm" formControlName="actvtyTxnType">
                                                <option value="0">ALL</option>
                                                <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-6 col-sm-12 mt-2"></div>
                                        <div class="col-xl-6 col-sm-12 mt-2r rt-2">
                                            <div class="float-end">
                                                <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                    href="#multiCollapseExample12" role="button" aria-expanded="false"
                                                    aria-controls="multiCollapseExample1" (click)="GetIndctrACT()">Filter</button>&nbsp;
                                                <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                    href="#multiCollapseExample12" role="button" aria-expanded="false"
                                                    aria-controls="multiCollapseExample1" (click)="cnclFrm('act')">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block p-3">
                    <div class="bar_chart">
                        <div class="progress mt-0 rounded">
                            <!-- [ngClass]="i==0 ? 'brdr-left bg-success' : 'bg-danger'" -->
                            <div *ngFor="let act of lstIndACT; let i=index" [ngStyle]="{ 'backgroundColor': act.color }"
                                class="progress-bar shadow-sm progress-bar-striped progress-bar-animated pntr"
                                role="progressbar" title="{{act.toolText.split('<br>')}}" id="act{{act.label}}"
                                (click)="onClk(act, 'act')" style="width: 0%;">{{act.value}}%</div>
                        </div>
                        
                        <div class="mt-4 row">
                            <div class="col-xl-6 pntr" *ngFor="let act of lstIndACT; let i=index">
                                <!-- [ngClass]="i==0 ? 'text-success' : 'text-danger'" -->
                                <div [ngStyle]="{ 'color': act.color }" class="pe-3" (click)="onClk(act, 'act')" title="{{act.toolText.split('<br>')}}">
                                    <i class="fa fa-square" aria-hidden="true"></i>
                                    <span class="ms-2 small">{{act.label}} ({{act.value}}%)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ovrw-spnr text-center" *ngIf="actIndcSpnr">
                <img [src]='ldrImg' id="spinner" /> <br>
                <p class="text-center"> <strong>Processing...</strong> </p>
            </div>
        </div>
        <div class="col-xl-4 col-sm-12 col-xs-12 mb-2" style="position: relative;" *ngIf="dbTmpPrmsn"> <!-- style="opacity: 0.1; cursor: not-allowed;" -->
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row mb-2">
                        <div class="col-xl-11 col-xs-12 mt-2">
                            <h5 class="mb-0">Temperature </h5>
                        </div>
                        <div class="col-xl-1 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1"></div>
                                <div class="dropdown-basic" title="Filter">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample13"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i> <!-- href="#multiCollapseExample13" -->
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold" *ngIf="chsnTempFT !=''"><b>Facility Category(s):</b> {{chsnTempFT}}<br /></span>
                                <span class="font-weight-bold" *ngIf="chsnTempAst !='' "><b>CCE Type(s):</b> {{chsnTempAst}}<br /></span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample13">
                                <div class="card card-body p-0 mb-0 pt-1 pl-2 pb-2 px-2 border-0">
                                    <div class= "row col-xl-12">	
                                        <div class="form-group col-xl-12 mt-2">
                                            <label for="tagName"><b>Facility Category:</b></label>
                                            <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="dfltStrTgsTemp"
                                                [data]="lstFacTags" placeholder="" formControlName="tempFacCatId">
                                            </ng-multiselect-dropdown>
                                            <!-- (onSelect)="onChng($event, 'facTag', 'os')" (onSelectAll)="onChng($event, 'facTag', 'osa')"
                                                (onDeSelect)="onChng($event, 'facTag', 'ds')" (onDeSelectAll)="onChng($event, 'facTag', 'dsa')" -->
                                        </div>	

                                        <!-- <div class="form-group col-xl-12 mt-2">
                                                <label for="textBox">Working status</label>
                                                <select class="form-select form-select-sm" formControlName="tempStatus">
                                                    <option value="">All</option>
                                                    <option *ngFor="let os of astStatusList" [value]="os.id">{{os.name}}
                                                    </option>
                                                </select>
                                        </div> -->

                                        <div class="form-group col-xl-12 mt-2">
                                            <!-- <label for="textBox">Type</label> -->
                                           <strong >CCE Type<span class="text-danger"><sup>*</sup></span></strong>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"
                                                formControlName="tempStatus"  [(ngModel)]="selectedTypes"  [ngClass]="{ 'is-invalid': isSbmtd && fc.tempStatus.errors }">
                                            </ng-multiselect-dropdown>
                                            <div *ngIf="isSbmtd && fc.tempStatus.errors" class="invalid-feedback">
                                                <div *ngIf="fc.tempStatus.errors?.required"> CCE Type is required</div>
                                            </div>

                                            <!-- (onSelect)="onChng('asetTypeId', $event, 'os')"
                                            (onSelectAll)="onChng('asetTypeId', $event, 'osa')"
                                            (onDeSelect)="onChng('asetTypeId', $event, 'ds')"
                                            (onDeSelectAll)="onChng('asetTypeId', $event, 'dsa')" -->
                                        </div>

                                        <div class="form-group col-xl-12 mt-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" checked="" autocomplete="off" id="flexCheckDefault"
                                                    formControlName="isUnknown">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    UnKnown
                                                </label>
                                            </div>
                                        </div>
                    
                                        <div class="form-group col-xl-6 col-sm-12 mt-2"></div>
                                        <div class="col-xl-6 col-sm-12 mt-2r rt-2">
                                            <div class="float-end">
                                                <button class="btn btn-sm btn-primary" (click)="isSbmtd=true;GetIndctrTemp()">Filter</button>&nbsp;
                                                <button class="btn btn-sm btn-primary" data-toggle="collapse" id="hideFilter"
                                                    href="#multiCollapseExample13" role="button" aria-expanded="false"
                                                    aria-controls="multiCollapseExample1" hidden>Filter</button>&nbsp;
                                                <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                    href="#multiCollapseExample13" role="button" aria-expanded="false"
                                                    aria-controls="multiCollapseExample1" (click)="isSbmtd=false;cnclFrm('tmp')">Reset</button>
                                            </div>
                                        </div>
                                    </div>
  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block p-3">
                    <div class="bar_chart">
                        <div class="progress mt-0 rounded">
                            <!-- [ngClass]="i==0 ? 'brdr-left bg-success' : 'bg-danger'" -->
                            <div *ngFor="let tmp of lstIndTMP; let i=index" [ngStyle]="{ 'backgroundColor': tmp.color }"
                                class="progress-bar shadow-sm progress-bar-striped progress-bar-animated pntr"
                                role="progressbar" title="{{tmp.toolText?.split('<br>')}}" id="tmp{{tmp.label}}"
                                (click)="onClk(tmp, 'tmp')" style="width: 0%;">{{tmp.value}}%</div>
                        </div>
                        
                        <div class="mt-4 row">
                            <div class="col-xl-6 pntr" *ngFor="let tmp of lstIndTMP; let i=index">
                                <div  [ngStyle]="{ 'color': tmp.color }"  title="{{tmp.toolText?.split('<br>')}}"
                                    class="pe-3" (click)="onClk(tmp, 'tmp')"> <!-- (click)="rngIndctr='t';onClk(tmp, 'tmp')" -->
                                    <i class="fa fa-square" aria-hidden="true"></i>
                                    <span class="ms-2 small">{{tmp.label}} ({{tmp.value}}%)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ovrw-spnr text-center" *ngIf="tmpIndcSpnr">
                <img [src]='ldrImg' id="spinner" /> <br>
                <p class="text-center"> <strong>Processing...</strong> </p>
            </div>
        </div>
    </div>
    <div class="row p-0 mt-2" *ngIf="!nodata">
        <div class="col-xl-12 col-sm-12 col-xs-12" style="position: relative;">
            <div class="card border-0 pb-3">
                <div class="card-header pb-2 pt-2 pl-4 pr-4">
                    <h5 class="mb-0">{{rngIndctr == 's' ? 'Stock Management' : rngIndctr == 'a' ? 'Activity' : 'Temperature'}}</h5>
                    <p class="mb-0" *ngIf="(chsnInvFT!=''||chsnInvMTN!='') && rngIndctr == 's'">
                        <span class="font-weight-bold" *ngIf="chsnInvFT!=''"><b>Facility Category(s):</b> {{chsnInvFT}}<br /></span>
                        <span class="font-weight-bold" *ngIf="chsnInvMTN!=''"><b>Material By {{isStkMatBdge == 'badge' ? 'Tag' : 'Name'}}(s):</b> {{chsnInvMTN}}</span>
                    </p>
                    <p class="mb-0" *ngIf="rngIndctr == 'a'">
                        <span class="font-weight-bold" *ngIf="chsnActFT!=''"><b>Facility Category(s):</b> {{chsnActFT}}<br /></span>
                        <span class="font-weight-bold" *ngIf="chsnActMTN!=''"><b>Material By {{isActvMatBdge == 'badge' ? 'Tag' : 'Name'}}(s):</b> {{chsnActMTN}}<br /></span>
                        <span class="font-weight-bold"><b>Period (since): </b>{{chsnPrd}}<br /></span>
                        <span class="font-weight-bold"><b>Txn Type: </b>{{chsnTxnType}}</span>
                    </p>
                    <p class="mb-0" *ngIf="rngIndctr == 't'">
                        <span class="font-weight-bold" *ngIf="chsnTempFT!=''"><b>Facility Category(s):</b> {{chsnTempFT}}<br /></span>
                        <span class="font-weight-bold" *ngIf="chsnTempAst!=''"><b>CCE Type(s): </b>{{chsnTempAst}}<br /></span>
                    </p>
                    <p class="mb-0">
                        <span class="font-weight-bold"><b>Status: </b>{{clrLabel}}<br /></span>
                    </p>
                    

                </div>
                <div class="card-body p-3 mt-3 border-0">
                    <div class="row">
                        <div class="col-xl-7 col-sm-12">
                            <div class="float-end">
                                <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                    <li class="nav-item" title="Map View"><a class="nav-link btn btn-sm border active" id="Map-tab" [hidden]="isCcpShow"
                                        data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                        aria-selected="true"><i class="fa fa-globe me-0" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="nav-item" title="List View"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                                        data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                                        aria-selected="false"><i class="fa fa-list me-0" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                            <h5 class="mb-4 text-center">By location </h5>
                            <div class="tab-content " id="top-tabContent">
                                <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                                    <div class="row centered-div">
                                        <div [hidden]="isHideIND">
                                            <h3><label style="text-align: center;">{{indTxt}}</label></h3>
                                            <div id="fc-map-ind" style="padding-left: 2% !important;"></div>
                                        </div>
                                        <button *ngIf="!isHideIND" id="btnState" (click)="onClkState()" hidden></button>
                                        <!-- <button *ngIf="isHideIND" (click)="isCcpShow=true;" hidden></button> -->
                                        <div [hidden]="!isHideIND">
                                            <h3><label id="lblState" style="text-align: center;"></label></h3>
                                            <div id="fc-map-state-ind"></div><br>
                                        <button  (click)="onClkDstrct()" id="btnDistrict"  hidden></button>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <!-- sample -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(115 102 255 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(115 102 255 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- green -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(26 175 93 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(26 175 93 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- red -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(217 83 79 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(217 83 79 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- yellow -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(250 212 46 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(250 212 46 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- blue -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(0 192 239 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(0 192 239 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- gray -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(108 117 125 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(108 117 125 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(108 117 125 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(108 117 125 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(108 117 125 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                                   <h3 *ngIf="isHideIND && !isCcpShow">{{stName}}</h3>
                                   <h3 *ngIf="isCcpShow">{{dstName}}</h3>
                                    <div class="row centered-div">
                                        <div class="text-right">
                                            <button class="btn btn-sm btn-success mb-3" (click)="exprtExl(isHideIND)"><i class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                                        </div>
                                        <div class="table_spacing table-responsive table-row-spacing">
                                            <table class="table table-striped table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Item No.</th>
                                                        <th class="text-center" *ngIf = "!isCcpShow">{{isHideIND ? 'District' : 'State'}}</th>
                                                        <th class="text-center" *ngIf = "isCcpShow">State</th>
                                                        <th class="text-center" *ngIf = "isCcpShow">District</th>
                                                        <th class="text-center" *ngIf = "isCcpShow">Facility Name</th>
                                                        <th class="text-center" *ngIf = "isCcpShow">Facility Category</th>
                                                        <th class="text-center" *ngIf = "!tempCCP">Value</th>
                                                        <th class="text-center" *ngIf = "tempCCP">Total</th>
                                                        <th class="text-center" *ngIf = "tempCCP">Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let item of lstT1;let i=index" [hidden]="isHideIND || isCcpShow ">
                                                    <tr *ngIf="item.value != null">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td class="text-center pntr" (click)="onClkIndState(item); onClkState();">{{item.name}}</td>
                                                        <td class="text-center">{{item.value != null ? item.value + '%' : ''}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngFor="let item of lstT2;let i=index" [hidden]="!isHideIND || isCcpShow">
                                                    <tr *ngIf="item.value != null">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td class="text-center pntr" (click)="onClkIndDstrct(item);onClkDstrct();">{{item.name}}</td>
                                                        <td class="text-center">{{item.value != null ? item.value + '%' : ''}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngFor="let item of lstT3;let i=index" [hidden]="!isCcpShow">
                                                    <tr *ngIf="(!tempCCP && item.value != null) || (tempCCP && item.value == null)"
                                                        [ngStyle]="{ 'backgroundColor': tempCCP ? ccpClr : null,
                                                            'color': tempCCP ? '#ffffff' : null }">
                                                    <!-- ng-style ="{'backgroundColor': tempCCP == true ? ccpClr : ''}"  -->
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td class="text-center">{{item.stateName}}</td>
                                                        <td class="text-center">{{item.districtName}}</td>
                                                        <td class="text-center">{{item.storeName}}</td>
                                                        <td class="text-center">{{item.storeCategory}}</td>
                                                        <td class="text-center" *ngIf = "!tempCCP">{{item.value != null ? item.value + '%' : ''}}</td>
                                                        <td class="text-center" *ngIf = "tempCCP">{{item.totalCount}}</td>
                                                        <td class="text-center" *ngIf = "tempCCP">{{item.count}}</td>

                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstT1.length==0" [hidden]="isHideIND || isCcpShow">
                                                    <tr>
                                                        <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstT2.length==0" [hidden]="!isHideIND || isCcpShow">
                                                    <tr>
                                                        <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstT3.length==0" [hidden]="!isCcpShow">
                                                    <tr>
                                                        <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>                     
                            </div>
                          
                        </div>
                        <div class="col-xl-5 col-sm-12" [hidden]="rngIndctr!=='s'">
                            <div>
                                <h5 class="mb-4 text-center">By material</h5>
                                <div class="float-xl-12"></div>
                                <div id="fc-bar-horz"></div>
                                <!-- <div id="fc-bar-horz" class="text-center mt-4" [ngStyle]="{ 'height': mtrlDivHt }"></div> -->
                                <!-- <div id="multi-bar" style="height: 500px;"></div> -->
                            </div>
                        </div>
                    </div>
                   
                    <div class="row" style="margin-top: 30px;">
                        <div class="text-right">
                            <div *ngIf = "usrLvl != 'State'">
                                <button *ngIf="isHideIND && !isCcpShow" class="btn btn-sm btn-light" (click)="isHideIND=false; tempCCP = false; loadMtrls('lsm')">Back</button>
                            </div>
                            <div *ngIf = "usrLvl != 'District'">
                                <button *ngIf="isCcpShow" class="btn btn-sm btn-light" (click)="isCcpShow=false; tempCCP = false; loadMtrls('ldm')">Back</button>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="ovrw-spnr text-center" *ngIf="mapIndcSpnr">
                <img [src]='ldrImg' id="spinner" /> <br>
                <p class="text-center"> <strong>Processing...</strong> </p>
            </div>

        </div>
        <!-- <div class="col-xl-12 col-sm-12 col-xs-12" *ngIf="hideMap">
                    <h5 class="mb-0"> No Data Found for  {{rngIndctr == 's' ? 'Stock Management' : rngIndctr == 'a' ? 'Activity' : 'Temperature'}}</h5>
                    
        </div> -->
    </div>
    <div class="tab-content" id="nodata" *ngIf="nodata" >
        <h3>There is no data</h3>
    </div>
</div>
<div id="tooltip" style="position: absolute; z-index: 9999"></div>