<form [formGroup]="shiplst" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-row row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Issuing facility</label>
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span><br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="issuingStoreId" type="text" placeholder="Filter by Issuing Facility"
                                formControlName="issuingStoreId" [typeaheadItemTemplate]="cstmStore"
                                typeaheadOptionField="storeName" [typeahead]="lstStrs" [adaptivePosition]="true"
                                class="form-control form-control-sm" (typeaheadOnSelect)="onChngFclty($event, 'slct')"
                                (change)="onChngFclty($event, 'chng')" [typeaheadScrollable]="true" id="issuingStoreId"
                                [attr.disabled]="usrStrLen==1?true:null" [typeaheadOptionsLimit]="500" />
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Receiving facility</label>
                            <ng-template #rcvngStore let-model="item">
                                <span class="font-weight-bold">{{model.mapped_store_name}}</span> <br />
                                <span class="fs-12">{{model.mapped_store_location}}</span>
                            </ng-template>
                            <input name="receivingStoreId" type="text" placeholder="Filter by Receiving Facility"
                                formControlName="receivingStoreId" [typeahead]="lstLinkedStrs"
                                [typeaheadItemTemplate]="rcvngStore" typeaheadOptionField="mapped_store_name"
                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                (typeaheadOnSelect)="onChngFclty2($event, 'slct')" (change)="onChngFclty2($event, 'chng')"
                                 id="receivingStoreId"  [typeaheadOptionsLimit]="500" />
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Status</label>
                            <select id="inputState" class="form-select form-select-sm" formControlName="status" #Status>
                                <option value="">All</option>
                                <option *ngFor="let os of shipStatusList" [value]="os.id">{{os.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">From</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" placeholder="From"
                                    [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="fromDate"
                                    [(ngModel)]="frmDate" [maxDate]="dt2day" readonly bsDatepicker>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i></button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('fromDate')"> <i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">To</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false"
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                    [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' [maxDate]="dt2day" bsDatepicker readonly
                                    formControlName="toDate"  [(ngModel)]="toDateF" placeholder="To" [minDate]="frmDate">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                        [attr.aria-expanded]="dpTD.isOpen"> <i class="fa fa-calendar"></i></button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpTD.hide();clearBSDate('toDate')"> <i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-4_5">
                            <label class="textBox">Material By</label>&nbsp;
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="category"
                                    [(ngModel)]="isStkMatBdge" (click)="lvlBdg1='';onClkRdb('category')"
                                    [checked]="isStkMatBdge=='category'" formControlName="isStkMatBdge">
                                <label class="form-check-label" for="mat">Category</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="badge"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('materialBadge')"
                                    formControlName="isStkMatBdge" [checked]="isStkMatBdge=='badge'">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="name"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('material')"
                                    [checked]="isStkMatBdge=='name'" formControlName="isStkMatBdge">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div class="w-67">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="stkMtrlCat"
                                    (onSelect)="onMultiSlct('s', 'c', $event)" (onSelectAll)="onMultiSlct('a', 'c', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'c', $event)" (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                                    [data]="lstMatMainCtgrs" *ngIf="isStkMatBdge=='category'">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdStkMat"
                                    (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags1" *ngIf="isStkMatBdge=='badge'"
                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="stkMtrlBdge">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats"
                                    (onSelect)="onMultiSlct('s', 'f', $event)" *ngIf="isStkMatBdge=='name'"
                                    (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="stkMtrlNme">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <!-- <div class="form-group col-xl-3">
                            <label for="textBox">Tracking #</label>
                            <input type="text" placeholder="Filter by Tracking #" formControlName="tracking"
                                class="form-control form-control-sm">
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-12">
                            <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1"
                                aria-expanded="false" aria-controls="box1">Advance Search</button>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div id="box1" class="collapse">
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">From expected fulfilment</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        placeholder="From expected fulfilment" class="form-control form-control-sm optinbg bsDP-bg"
                                        [dateCustomClasses]="cstmDt3" (bsValueChange)="onChngDtE($event)"
                                        #dpFEAD="bsDatepicker" readonly bsDatepicker [(ngModel)]="fromEADate"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' formControlName="fromExpectedArrvlDate">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFEAD.toggle()"
                                            [attr.aria-expanded]="dpFEAD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpFEAD.hide();clearBSDate('fromExpectedArrvlDate')"> <i
                                                class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- <input type="text" placeholder="From expected fulfilment" 
                                class="form-control" formControlName="fromExpectedArrvlDate"> -->
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox">To expected fulfilment</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        placeholder="To expected fulfilment" class="form-control form-control-sm optinbg bsDP-bg"
                                        [dateCustomClasses]="cstmDt4" (bsValueChange)="onChngDtET($event)"
                                        #dpTEAD="bsDatepicker" readonly bsDatepicker
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' formControlName="toExpectedArrvlDate"
                                        [minDate]="fromEADate">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTEAD.toggle()"
                                            [attr.aria-expanded]="dpTEAD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpTEAD.hide();clearBSDate('toExpectedArrvlDate')"> <i
                                                class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- <input type="text" placeholder="To expected fulfilment" 
                                class="form-control form-control-sm" formControlName="toExpectedArrvlDate"> -->
                            </div>
                        </div>
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">Transporter</label>
                                <ng-template #cstmTransporter let-model="item">
                                    <span class="font-weight-bold">{{model.carrierName}}</span>
                                    <br />
                                    <!-- <span class="fs-12">{{model.location}}</span> -->
                                </ng-template>
                                <input name="Transporter" type="text" placeholder="Transporter" id="Transporter"
                                    formControlName="transporter" [typeahead]="carrierLst" [typeaheadScrollable]="true"
                                    [typeaheadItemTemplate]="cstmTransporter" typeaheadOptionField="carrierName"
                                    [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true" 
                                    [typeaheadOptionsLimit]="500" />
                                <!-- <input type="text" placeholder="Transporter" class="form-control form-control-sm" formControlName="transporter"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button (click)="crntPage=1;onReset()" class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;LoadShipments()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="shipmentsList?.length!=0">
                <div class="col-xl-4 mb-2">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="shipmentsList?.length!=0">
                <div class="col-xl-6 mb-2">
                    {{pageEntrs}}
                    <!-- <span style="display: inline-block;" class="me-2">
                        <label tyle="display: inline-block;">Filter</label>
                    </span>
                    <span style="display: inline-block;">
                        <select class="form-select form-select-sm">
                            <option>5</option>
                            <option>10</option>
                        </select>
                    </span> -->
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-striped table-sm table-bordered" id="table_detail">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Shipment Id</th>
                            <th class="text-center">Indent Id</th>
                            <th class="text-center">No. of shipments</th>
                            <th>Receiving Facility</th>
                            <th>Issuing Facility</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="table_data"> <!-- *ngIf="shipmentsList?.length!=0" -->
                        <tr *ngFor="let item of shipmentsList | search:searchText:filterMetadata | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index"
                            (click)="onShipmentClk(item.cargoId)">
                            <td>
                                <!-- <button class="btn btn-sm rounded bg-light p-0" data-toggle="tooltip"
                                    data-placement="left" title="POIT STATE 10-RJ"><img
                                        src="../../../../assets/img/icon.png" alt="">
                                </button> -->
                            </td>
                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                            <td><a>{{item.cargoNo}}</a></td>
                            <td><a>{{item.bookingId}}</a></td>
                            <td><a>{{item.noOfItems}}</a></td>
                            <td>
                                <a>{{item.receivingStoreName}}</a>
                                <div class="small">{{item.receivingStoreLocation}}</div>
                            </td>
                            <td>
                                <a>{{item.issuingStoreName}}</a>
                                <div class="small">{{item.issuingStoreLocation}}</div>
                            </td>
                            <td><a>{{item.status}}</a></td>
                            <td>
                                <a>{{item.createdName}}</a>
                                <div class="small">{{item.createdOn | date: "d/M/yy hh:mm aa"}}</div>
                            </td>
                            <td class="text-center">
                                <span class="f-14">
                                    <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.sourceType==1"></i>
                                    <i class="fa fa-desktop" aria-hidden="true" *ngIf="item.sourceType==2"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="shipmentsList?.length==0 || count==0">
                        <tr>
                            <td colspan="10" class="text-center">
                                {{noRcrdsTxt}}
                            </td>
                        </tr>
                    </tbody>
                </table> <br />
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="shipmentsList.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>