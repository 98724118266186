import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-orders-create',
  templateUrl: './orders-create.component.html',
  styleUrls: ['./orders-create.component.css']
})

export class OrdersCreateComponent extends BaseComponent implements OnInit {

  lstStrs: any[] = []; lstLinkedStrs: any[] = []; issFacId: any; recFacId = ''; str = ''; lstAllMats: any[] = []; lstMats: any[] = [];
  ordrCreateForm: FormGroup; ordrType = '1'; isSbmtd = false; noRcrdsTxt = environment.TblNoRcrds; orderType = '2'; lstTags: any[] = [];
  lstOrdTyps: any[] = []; rsnsLst: any[] = []; facSlctTxt = environment.OrdrFacSlct; dt2Day: Date = new Date(); isAPICald = true;
 isRsnMndtry = false; str2 = ''; paramsTab = ''; isSUFclty = false; issPranth:any; isMinmax: boolean = false;

  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder,
    public dtPipe: DatePipe,
    private router: Router, private route: ActivatedRoute) {
    super(glblSvc);
    this.ordrCreateForm = this.formBuilder.group({ badgeId: [null], orderTypeId: [{ value: '1' }, Validators.required], srchMtrl: [''],
      kioskid: [null, Validators.required], srcType: [2], vkioskid: [null, Validators.required], comments: [null], arrivalDate: [''],
      orderReferenceNo: [''], updatedOn: [], receiptDate: [], productBookings: this.formBuilder.array([]), bookingBadge: [''],
      confirmEndDate: [], confirmStartDate: [], createdOn: [], isDeleted: [false], itemsCount: [100], transferReferenceNo: [null],
      transactionType: [1] }), this.glblSvc.sbjctStrList.subscribe(s => { this.lstStrs = s, this.usrStrLen = s.length;
        if (this.usrStrLen == 1) this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1'); }), this.isSUFclty = false,
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstOrdTyps = s.ordTypeLst, this.rsnsLst = s.rsnsLst?.filter((f: any) =>
      f.reasonType == environment.cnfgTrnsRsnType.new_indent) }), this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstTags =
      s?.filter(f => f.badgeTypeId == environment.tagTypes.order) });
    if (this.lstOrdTyps?.filter(a => a.id == 1).length != 0)
      this.ordrCreateForm.get('orderTypeId')?.setValue(1);
    else
      this.ordrCreateForm.get('orderTypeId')?.setValue(this.lstOrdTyps[0].id);
    this.isRsnMndtry = this.rsnsLst?.filter(a => a.isReasonMandatory == true).length > 0;
    if (localStorage.getItem('suFcltyId') != null && location.href.includes('setup-fclty-smry')) {
      if (this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId')).length > 0)
        this.changeFn(this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, '1', 'main', 'str1'), this.isSUFclty = true;
    }
  }

  ngOnInit(): void { 
    this.route.queryParams.subscribe(params => { this.paramsTab = params.tab; });
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  clearBSDate(ctrl: string) {
    this.ordrCreateForm.get(ctrl)?.setValue('');
  }

  change($event: any) {
    this.ordrType = $event.target.value;
  }

  onEditQty(matItem: any) {
   let qntyVal = matItem.value.quantity != '' ? +matItem.value.quantity : null;
    if (matItem?.get('recommendedQuantity')?.value != matItem?.get('quantity')?.value && matItem?.get('minMax')?.value==true)
      matItem.get('reason')?.setValidators([Validators.required]),  matItem.get('reason')?.updateValueAndValidity();
    else
      matItem.get('reason')?.setValidators(null), matItem.get('reason')?.updateValueAndValidity();
    let defVal = matItem?.get('quantity')?.value;
    matItem?.get('quantity')?.setValue(+defVal);matItem.get('isInvalidPttrn')?.setValue(false);
    if (matItem.value.handlingQuantity != null && qntyVal != null)
    // matItem.get('isInvalidPttrn')?.setValue(qntyVal % matItem.value.handlingQuantity != 0);
    matItem.get('isInvalidPttrn').setValidators([Validators.required ,Validators.pattern(`${qntyVal% matItem.value.handlingQuantity !=0}`)]), matItem?.get('isInvalidPttrn')?.updateValueAndValidity();
  }

  changeFn(event: any, ordrType: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    this.str = '', this.str2 = '';
    if (evnt == '' || evnt == undefined)
      [this.str, this.issFacId] = [event.value, ''];
    else {
      if (strType == 'main') {
        this.str = evnt;
        if (this.lstStrs?.filter(a => a.storeName == evnt).length != 0) {
          for (let s = 0; s < this.lstStrs.length; s++) {
            if (this.lstStrs[s].storeName == evnt) {
              this.issFacId = this.lstStrs[s].storeId;
              if (evntType === 'str1')
                this.ordrCreateForm.get('kioskid')?.setValue(evnt);
            }
          }
        }
        else
          this.issFacId = '', this.recFacId = '', this.ordrCreateForm.get('kioskid')?.setValue(null);
      }
      else {
        if (this.lstLinkedStrs?.filter(a => a.mapped_store_name == evnt).length != 0) {
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].mapped_store_name == event.value)
              this.recFacId = this.lstLinkedStrs[s].mapped_store_id;
          }
        }
        else
          this.recFacId = '';
      }
    }
    this.ordrCreateForm.get('vkioskid')?.setValue(null), this.lstMats = [], this.lstAllMats = [], 
      this.GetMatrls().controls.length = 0, this.linkedPranths(this.issFacId, ordrType);
  }

  onChngRecFclty(event: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    this.str2 = '';
    if (evnt == '' || evnt == undefined)
      this.recFacId = '';
    else {
      if (strType == 'main') {
        if (this.lstStrs?.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstStrs.length; s++) {
            if (this.lstStrs[s].storeName == event.value)
              this.issFacId = this.lstStrs[s].storeId;
          }
        else
          this.issFacId = '', this.recFacId = '', this.ordrCreateForm.get('vkioskid')?.setValue(null);
      }
      else {
        this.str2 = evnt;
        if (this.lstLinkedStrs?.filter(a => a.mapped_store_name == evnt).length != 0)
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].mapped_store_name == evnt)
              this.recFacId = this.lstLinkedStrs[s].mapped_store_id;
          }
          else
            this.recFacId = '', this.ordrCreateForm.get('vkioskid')?.setValue(null);
      }
    }
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0;
    if (this.recFacId != '')
      this.LoadMatsbyStoreId(this.issFacId, this.recFacId);
  }

  linkedPranths(kioskId: any, ordrType: any) {
    if (kioskId && this.isAPICald) {
      let ordType = (ordrType == 1 ? 'c' : (ordrType == 2 ? 'v' : '')), isSpnr = location.href.includes('setup-fclty-smry');      
      this.isAPICald = !this.isAPICald, this.lstLinkedStrs = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
          environment.apiMthds.getLinkedStores.replace('pranthId', kioskId).replace('ordType', ordType), null, 0, isSpnr).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.lstLinkedStrs = this.result.data != null ? this.result.data : [];
            if (this.lstLinkedStrs.length == 1 && ordType == 'c')
              this.ordrCreateForm.get('vkioskid')?.setValue(this.lstLinkedStrs[0]?.mapped_store_name), this.recFacId = this.lstLinkedStrs[0]?.
                mapped_store_id, this.LoadMatsbyStoreId(this.issFacId, this.recFacId), this.str2 = this.lstLinkedStrs[0]?.mapped_store_name;
            if (this.lstLinkedStrs.length == 1 && ordType == 'v')
              this.ordrCreateForm.get('kioskid')?.setValue(this.lstLinkedStrs[0]?.mapped_store_name), this.issFacId = this.lstLinkedStrs[0]?.
                mapped_store_id, this.LoadMatsbyStoreId(+this.recFacId, this.issFacId);
          }
        }
        this.isAPICald = true;
      });
    }
  }

  LoadMatsbyStoreId(issFacId: number, recFacId: any) {
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0, 
      this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMats.
        replace('sss', `${issFacId}`).replace('rrr', `${recFacId}`).replace(environment.noPgLen, ''), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats,
            this.issPranth = this.lstAllMats[0]?.pranthId;
          else{
            if (this.router.url.includes('ord-ord')) {
              this.glblSvc.onTstr('i', this.result.message);
            }
          }
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  slctdMat($event: any) { 
    let matName = $event.value; this.isMinmax = false;
    this.isMinmax = this.lstAllMats?.filter(f => f.productName == matName)[0]?.isMinMax;
    if (matName != '') {
      if (this.lstAllMats?.filter(f => f.productName == matName).length > 0) {
        let avlbStk = this.lstAllMats?.filter(f => f.productName == matName)[0].availableStock,
        hndlUnit = this.lstAllMats.filter(f => f.productName == matName)[0].handlingQuantity,
        stockIndicator = this.lstAllMats?.filter(f => f.productName == matName)[0].stockIndicator;
        this.GetMatRcmnd(this.recFacId, this.lstAllMats?.filter(f => f.productName == matName)[0].productId, matName, avlbStk, stockIndicator, hndlUnit); // this.issFacId
        this.ApplyValdtns(matName);
      }
    }
  }
  
  ApplyValdtns(name: any) {
     //valTT = this.ordrCreateForm.get('transType')?.value;
     this.GetMatrls().controls.forEach((e: any)=> {
      //  if(e?.name==name){
       let minVal = 1;
       if (e?.get('handlingQuantity')?.value != null)
         minVal = +e?.get('handlingQuantity')?.value;
         e?.get('quantity')?.setValidators([Validators.required, Validators.min(minVal), Validators.minLength(1), Validators.max(99999999), Validators.maxLength(8) ]); // Validators.pattern(environment.patterns.numericsby5)
         e?.get('quantity')?.updateValueAndValidity();
 
        // if( e?.get('minMax'))
        //   e?.get('reason')?.setValidators([Validators.required]);
        // else
        // e?.get('reason')?.setValidators(null);
 
        // e?.get('reason')?.updateValueAndValidity();
    })
  }

  onDelMtrls(tabIndex: any, matName: any) {
    this.GetMatrls().removeAt(tabIndex), this.lstMats.push(this.lstAllMats?.filter(f => f.productName == matName)[0]),
      this.lstMats.sort((a, b) => a.productId - b.productId);
  }

  GetMatRcmnd(strId: any, matId: any, matName: any, avlStock: any, stockIndicator: any, hndlUnit: any) {
    if (strId && matId && matName && this.isMinmax) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getRecmndMats.
          replace('sss', strId).replace('mmm', matId), null).then(() => {
        if (this.result) {
          this.lstMats = this.lstMats?.filter(f => f.productName != matName),
          this.GetMatrls().push(this.onAddFormCtrls(matName, matId, avlStock, this.result?.data?.recmndQuty, stockIndicator, this.isMinmax, hndlUnit)),
            this.ordrCreateForm.get('srchMtrl')?.setValue(''), this.lstMats = this.lstMats?.filter(f => f.productName != matName);
        }
      });
    }else{
      this.lstMats = this.lstMats?.filter(f => f.productName != matName),
      this.GetMatrls().push(this.onAddFormCtrls(matName, matId, avlStock, null, stockIndicator, this.isMinmax, hndlUnit)),
        this.ordrCreateForm.get('srchMtrl')?.setValue(''), this.lstMats = this.lstMats?.filter(f => f.productName != matName);
    }
  }

  GetMatrls(): FormArray {
    return this.ordrCreateForm.get('productBookings') as FormArray;
  }

  onAddFormCtrls(matName: string, mId: any, avlStock: any, recVal: any, stockIndicator: any, is_minmax: any, hndlUnit: any, indx: number = 0) {
    let matData = this.lstAllMats?.filter(f => f.productId === mId)[0];
    return this.formBuilder.group({ productId: [mId, [Validators.required]], name: [matName, [Validators.required]], ts: avlStock, others: [''],
      quantity: [recVal, [Validators.required, Validators.minLength(1), Validators.min(1), Validators.maxLength(10)]],
      reason: [''], recommendedQuantity: recVal, modifiedReason: [null], // Validators.pattern(environment.patterns.numericsby10)
      intransitStock: [matData.intransitStock], invMin: [matData.invMin], invMax: [matData.invMax], stockIndicator: stockIndicator, minMax: is_minmax,
      rcvngStoreAvlStock: [matData.rcvngStoreAvlStock], rcvngStoreIntransitStock: [matData.rcvngStoreIntransitStock], handlingQuantity: [hndlUnit], isInvalidPttrn: false,
      rcvngStoreMinStock: [matData.rcvngStoreMinStock], rcvngStoreMaxStock: [matData.rcvngStoreMaxStock] });
  }

  onClrData() {
    $('#Store').val(''), this.str = '', this.lstLinkedStrs = [], this.issFacId = '', this.lstMats = [], this.isSbmtd = false, this.onClrMatData();
  }

  onClrMatData() {
    this.lstMats = [], (<FormArray>this.ordrCreateForm.get('productBookings')).controls = [], this.ordrCreateForm.controls.srchMtrl.setValue(''),
      this.ordrCreateForm.controls.vkioskid.setValue(''); this.ordrCreateForm.controls.kioskid.setValue('');
    if (this.lstStrs.length == 1) this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1');
  }

  onSlctRR(evnt: any, tIndx: number) {
    let rcvdRsn = evnt?.target?.value;
      if (rcvdRsn != ''){
        if(`${this.rsnsLst?.filter(f => f.reasonName === rcvdRsn)[0].reasonName}`.toLowerCase().includes('others'))
        // this.GetMatrls().controls[tIndx].value.others.setValidators([Validators.required]),
        this.GetMatrls().controls[tIndx-1].get('others')?.setValidators([Validators.required]);
        else
        this.GetMatrls().controls[tIndx-1].get('others')?.setValidators(null);

        this.GetMatrls().controls[tIndx-1].get('others')?.updateValueAndValidity();
        // this.GetMatrls().controls[tIndx].value.others.updateValueAndValidity();
      }
  }

  onCreateOrder(ordrType: any) {
    this.checkFormValidations(this.ordrCreateForm), this.checkFormArrayValidations(this.ordrCreateForm.controls['productBookings']);
    if (this.ordrCreateForm.invalid || this.ordrCreateForm.controls['productBookings'].invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else {
      let payLoad = this.ordrCreateForm.getRawValue(), arDate = this.ordrCreateForm.get('arrivalDate')?.value != null && this.ordrCreateForm.get('arrivalDate')?.value != '' ?
          this.dtPipe.transform((this.ordrCreateForm.get('arrivalDate')?.value), this.dtF.ymd) + this.tmF.zeroTM : null, notRcmndCnt = 0;
        payLoad.receivingStoreId = ordrType == 1 ? (this.recFacId != '' ? +this.recFacId :null) : (this.issFacId != '' ? +this.issFacId : null), 
        // payLoad.receivingStoreId = this.lstLinkedStrs?.filter(f => f.mapped_store_name == this.ordrCreateForm.controls.vkioskid.value)[0].mapped_store_id,
        payLoad.issuingStoreId = ordrType == 1 ? (this.issFacId != '' ? +this.issFacId :null) : (this.recFacId != '' ? +this.recFacId : null), 
        payLoad.arrivalDate = arDate, payLoad.confirmEndDate = arDate, payLoad.confirmStartDate = arDate, payLoad.createdOn = arDate,
        payLoad.updatedOn = arDate, payLoad.receiptDate = arDate, payLoad.productBookings.forEach((e: any) => {
          e.reason = e.others != '' ? e.others : e.reason, delete e.name, delete e.ts, delete e.others, delete e.stockIndicator,
          e.quantity = (e.quantity != null && e.quantity != '') ? +e.quantity : 0, e.recommendedQuantity = (e.recommendedQuantity != null &&
            e.recommendedQuantity != '') ? +e.recommendedQuantity : 0;
          if (e.quantity != e.recommendedQuantity)
            notRcmndCnt++;
        });
      delete payLoad.srchMtrl, delete payLoad.kioskid, delete payLoad.vkioskid,
        payLoad.pranthId = this.issPranth, payLoad.sourceType = '2',
        payLoad.updatedBy = this.usrLgnDtls.userId, payLoad.createdBy = payLoad.updatedBy, 
        payLoad.orderTypeId = (payLoad.orderTypeId != null && payLoad.orderTypeId != '') ? +payLoad.orderTypeId : null ,
        payLoad.bookingBadge = (payLoad.bookingBadge != null && payLoad.bookingBadge != '')? +payLoad.bookingBadge : null, 
        payLoad.badgeId = (payLoad.badgeId != null && payLoad.badgeId != '')? +payLoad.badgeId : null;
      if (notRcmndCnt > 0 && this.isMinmax) {
        if (confirm(`The entered quantity of ${notRcmndCnt} material(s) do not match the recommended quantity. Do you wish to continue?`))
          this.SaveIndent(payLoad);
      }
      else
        this.SaveIndent(payLoad);
    }
  }

  SaveIndent(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.add, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK'){
          localStorage.removeItem("isIndntAPICald"),
          this.glblSvc.onTstr('s', this.result.message), localStorage.setItem('ordrCreatePL', JSON.stringify(payLoad)), 
          this.onReset(), this.glblSvc.sbjctTabsPage.next('indnt-list'), this.issPranth = '',
          localStorage.removeItem('isIndntAPICald');          
          if(this.paramsTab == 'i')
            this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'il', type: 'list' } }); // setTimeout(() => { window.location.reload(); }, 1000);
          else 
            localStorage.setItem('ordLst', '1'), this.router.navigate(['home/ord-ord'], { queryParams: { tab: 'list', type: 'i' } }); // setTimeout(() => { window.location.reload(); }, 1000);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onReset() {
    this.ordrCreateForm.reset(), this.ordrCreateForm.get('orderTypeId')?.setValue('1'), this.glblSvc.sbjctOrdId.next(0), this.GetMatrls().controls.length = 0;
    if (this.lstStrs.length == 1)
      this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1');
  }

}