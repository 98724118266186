<form [formGroup]="dbActvtyForm" autocomplete="off">
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <!-- <div class="form-group col-xl-3">
                    <strong class="col-form-label">Material By</strong><span class="text-danger"><sup>*</sup></span>
                    <div class="form-check form-check-inline mt-2 p-l-25">
                        <input class="form-check-input" type="radio" name="isMatBdge" value="badge"
                            (click)="onClkRdb('productBadgeId')" [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'"
                            formControlName="isMatBdge">
                        <label class="form-check-label" for="mat">Tag</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isMatBdge" value="name"
                            (click)="onClkRdb('productId')" [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'"
                            formControlName="isMatBdge">
                        <label class="form-check-label" for="mat">Name</label>
                    </div>
                    <div *ngIf="isMatBdge=='badge'">
                        <ng-multiselect-dropdown [settings]="ddlStngs" [(ngModel)]="slctdMat" [data]="lstMatTags"
                            formControlName="productBadgeId" (onSelect)="onMultiSlct('s', 'n', $event)"
                            (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productBadgeId.errors }">
                        </ng-multiselect-dropdown>
                        <div *ngIf="isSbmtd && fc.productBadgeId.errors" class="invalid-feedback">
                            <div *ngIf="fc.productBadgeId.errors?.required">Material tag is required</div>
                        </div>
                    </div>
                    <div *ngIf="isMatBdge=='name'">
                        <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstAllMats" [(ngModel)]="slctdMatName"
                            formControlName="productId" (onSelect)="onMultiSlct('s', 'f', $event)"
                            (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }">
                        </ng-multiselect-dropdown>
                        <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                            <div *ngIf="fc.productId.errors?.required">Material name is required</div>
                        </div>
                    </div>
                </div> -->

                <div class="form-group col-md-4_5 mt-1">
                    <strong class="col-form-label">Material By<span class="text-danger"><sup>*</sup></span></strong>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isMatBdge" value="category"
                            [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('category')"
                            [checked]="isMatBdge=='category'" formControlName="isMatBdge">
                        <label class="form-check-label" for="mat">Category</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isMatBdge" value="badge"
                            [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('productBadgeId')"
                            formControlName="isMatBdge" [checked]="isMatBdge=='badge'">
                        <label class="form-check-label" for="mat">Tag</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="isMatBdge" value="name"
                            [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('productId')"
                            [checked]="isMatBdge=='name'" formControlName="isMatBdge">
                        <label class="form-check-label" for="mat">Name</label>
                    </div>
                    <div class=""> <!-- w-67 -->
                        <ng-multiselect-dropdown [settings]="ddlStngs" formControlName="stkMtrlCat"
                            (onSelect)="onMultiSlct('s', 'c', $event)" (onSelectAll)="onMultiSlct('a', 'c', $event)"
                            (onDeSelect)="onMultiSlct('r', 'c', $event)" (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                            [data]="lstMatMainCtgrs" *ngIf="isMatBdge=='category'"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.stkMtrlCat.errors }">
                        </ng-multiselect-dropdown>
                        <ng-multiselect-dropdown [settings]="ddlStngs" [(ngModel)]="slctdMat"
                            (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags" *ngIf="isMatBdge=='badge'"
                            (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="productBadgeId"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productBadgeId.errors }">
                        </ng-multiselect-dropdown>
                        <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstAllMats"
                            (onSelect)="onMultiSlct('s', 'f', $event)" *ngIf="isMatBdge=='name'"
                            (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="productId"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }">
                        </ng-multiselect-dropdown>
                        <div *ngIf="isSbmtd && fc?.stkMtrlCat?.errors && isMatBdge=='category'" class="invalid-feedback">
                            <div *ngIf="fc?.stkMtrlCat?.errors?.required">Material category is required</div>
                        </div>
                        <div *ngIf="isSbmtd && fc?.productBadgeId?.errors && isMatBdge=='badge'" class="invalid-feedback">
                            <div *ngIf="fc?.productBadgeId?.errors?.required">Material tag is required</div>
                        </div>
                        <div *ngIf="isSbmtd && fc?.productId?.errors && isMatBdge=='name'" class="invalid-feedback">
                            <div *ngIf="fc?.productId?.errors?.required">Material name is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-xl-3 mt-2">
                    <label for="tagName"><b>Facility Category</b><span class="text-danger"><sup>*</sup></span></label>
                    <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstFacTags"
                        formControlName="facilityCategories" (onSelect)="onMultiSlctFT('s', $event)"
                        (onSelectAll)="onMultiSlctFT('a', $event)" (onDeSelect)="onMultiSlctFT('r', $event)"
                        (onDeSelectAll)="onMultiSlctFT('d', $event)" [(ngModel)]="slctdFac"
                        [ngClass]="{ 'is-invalid': isSbmtd && fc.facilityCategories.errors }">
                    </ng-multiselect-dropdown>
                    <div *ngIf="isSbmtd && fc.facilityCategories.errors" class="invalid-feedback">
                        <div *ngIf="fc.facilityCategories.errors?.required">Facility Category is required</div>
                    </div>
                </div>
                <div class="col-xl-2 text-end pt-4 mt-3">
                    <ul class="pull-right nav nav-pills nav-primary mb-3" id="pills-clrtabinfo" role="tablist">
                        <li class="nav-item"><a class="nav-link btn btn-sm border active" data-bs-toggle="tab"
                                role="tab" aria-controls="Map" aria-selected="true" (click)="onTypeSlct('d')"
                                #dayClick>Day</a>
                        </li>
                        <li class="nav-item"><a class="nav-link btn btn-sm border" data-bs-toggle="tab" role="tab"
                                aria-controls="Map" aria-selected="false" (click)="onTypeSlct('m')">Month</a></li>
                    </ul>
                </div>
                <div class="col-xl-2 form-group pt-4 mt-3">
                    <div *ngIf="dayPicker" class="input-group">
                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                            class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                showWeekNumbers: false, adaptivePosition: true }" readonly
                            placeholder="Select Date" [maxDate]="dt2day" id="datePickValue" name="dop" #dop
                            formControlName="date" [ngClass]="{ 'is-invalid': isSbmtd && fc.date.errors }">
                        <!-- ([ngModel])="frmDate"   [dateCustomClasses]="cstmDt" onkeypress='return event.charCode == 8' -->
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                [attr.aria-expanded]="dpFD.isOpen">
                                <i class="fa fa-calendar"></i>
                            </button>
                            <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                (click)="dpFD.hide();clearBSDate('date')">
                                <i class="fa fa-times"></i>
                            </button> -->
                        </div>
                        <div *ngIf="isSbmtd && fc.date.errors" class="invalid-feedback">
                            <div *ngIf="fc.date.errors?.required">Date is required</div>
                        </div>
                    </div>

                    <div *ngIf="mnthPicker" class="input-group">
                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                            class="form-control form-control-sm optinbg bsDP-bg" #dpMD="bsDatepicker"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                            placeholder="Select Month" [ngClass]="{ 'is-invalid': isSbmtd && fc.date.errors }"
                            id="datePickValue" name="dop" #dop formControlName="date" [maxDate]="dt2day" readonly>
                        <!-- onkeypress='return event.charCode == 8' ([ngModel])="frmDate"  [dateCustomClasses]="cstmDt"-->
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpMD.toggle()"
                                [attr.aria-expanded]="dpMD.isOpen">
                                <i class="fa fa-calendar"></i>
                            </button>
                            <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                (click)="dpMD.hide();clearBSDate('date')">
                                <i class="fa fa-times"></i>
                            </button> -->
                        </div>
                        <div *ngIf="isSbmtd && fc.date.errors" class="invalid-feedback">
                            <div *ngIf="fc.date.errors?.required">Date is required</div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-2 text-end pt-4 mt-3">
                    <button class="btn btn-light btn-sm" (click)="isSbmtd=false;lstGrid=[];onReset()">Reset</button>&nbsp;
                    <button class="btn btn-sm btn-primary" (click)="isSbmtd=true;dtDDMM='';LoadActivity()">Filter</button>
                </div>
                <div class="form-group col-xl-7 mt-2">
                    <div class="form-group col-xl-3">
                        <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                            aria-controls="box1">Advance Search</button>
                    </div>
                    <div id="box1" class="collapse">
                        <div class="form-row row mb-2">
                            <div class="form-group col-xl-4 mt-3">
                                <strong for="textBox">State</strong>
                                <select formControlName="stateId" class="form-select form-select-sm"
                                    (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                    <option value="null">All</option>
                                    <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-4 mt-3">
                                <strong for="textBox">District</strong>
                                <select formControlName="districtId" class="form-select form-select-sm"
                                    (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                    <option value="null">All</option>
                                    <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-4 mt-3">
                                <div>
                                    <strong for="textBox">Taluk/Block</strong>
                                    <select formControlName="blockId" class="form-select form-select-sm" #locBlck>
                                        <option value="null">All</option>
                                        <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-xl-5 mt-2 text-end">
                    <a [innerHTML]="dayMnth == 'd' ? dayTxt : mnthTxt"></a>
                </div>
            </div>
            <hr class=" p-0">
            <div>
                <div>
                    <div class="mt-3" *ngIf="lstGrid?.length !=0">
                        <div class="card p-0 mb-0">
                            <div class="card-header border-0 p-3">
                                <h6 class="mb-0">Activity</h6>
                            </div>
                            <div *ngIf="lstGrid?.length!=0">
                                <div class="card-body p-3 tab-card" *ngFor="let item of lstGrid">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div (click)="slctdTab='f';GetActChart();" class="col-sm-4 text-center pntr"
                                                [ngClass]="slctdTab == 'f' ? 'actDiv' : ''">
                                                <span class="f-big mb-2">{{item.activestores}}</span><br />Active
                                                Facilities
                                            </div>
                                            <div (click)="slctdTab='u';GetActChart();" class="col-sm-4 text-center pntr"
                                                [ngClass]="slctdTab == 'u' ? 'actDiv' : ''">
                                                <span class="f-big mb-2">{{item.activeusers}}</span><br />Active Users
                                            </div>
                                            <div (click)="slctdTab='t';GetActChart();" class="col-sm-4 text-center pntr"
                                                [ngClass]="slctdTab == 't' ? 'actDiv' : ''">
                                                <span class="f-big mb-2">{{item.transactions}}</span><br />Transactions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div *ngIf='isChrtEnable' class="p-3">
                                    <div class="float-end" *ngIf="lstChrt?.length!=0">
                                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo"
                                            role="tablist">
                                            <li class="nav-item" title="Map View"><a
                                                    class="nav-link btn btn-sm border active" id="Map-tab"
                                                    data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                                    aria-selected="true"><i class="fa fa-bar-chart me-0"
                                                        aria-hidden="true"></i></a>
                                            </li>
                                            <li class="nav-item" title="List View"><a class="nav-link btn btn-sm border"
                                                    id="Map-1-tab" data-bs-toggle="tab" href="#Map-1" role="tab"
                                                    aria-controls="Map-1" aria-selected="false"><i
                                                        class="fa fa-list me-0" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                    <div class="tab-content mt-3" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="Map" role="tabpanel"
                                            aria-labelledby="Map-tab">
                                            <div class="card-body text-center">
                                                <div id="chrt"></div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="Map-1" role="tabpanel"
                                            aria-labelledby="Map-1-tab">
                                            <div class="table_spacing table-responsive table-row-spacing">
                                                <div class="w-50 mx-auto">
                                                    <div class="text-right float-end" *ngIf="lstChrt?.length!=0">
                                                        <button class="btn btn-sm btn-success mb-3" (click)="exprtExl()"><i class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                                                    </div>
                                                <table class="table table-striped table-sm table-bordered text-center">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">S No.</th>
                                                            <th class="text-center">{{typeSlctd == 'd' ? 'Date' :
                                                                'Month'}}</th>
                                                            <th class="text-center">Count</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="lstChrt?.length!=0">
                                                        <tr *ngFor="let item of lstChrt;let i=index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{typeSlctd == 'd' ? (item.date | date:'dd-MM-YYYY') :
                                                                item.month}}</td>
                                                            <td>{{slctdTab == 'f' ? item.activestores : (slctdTab == 'u'
                                                                ? item.activeusers : item.transactions)}}</td>

                                                        </tr>
                                                    </tbody>
                                                    <tbody class="text-center pb-2" *ngIf="lstChrt?.length ==0">
                                                        {{noRcrdTxt}}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center pb-2" *ngIf="lstGrid?.length ==0">
                                {{noRcrdTxt}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>