<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card p-0 border-0 mb-2">
            <div class="card-body p-4 border-0 pb-2">
                <div class="row">
                    <div class="col-xl-12">
                        <form class="row" [formGroup]="invStkDvntFrm">
                            <div class="col-sm-3  mb-3">
                                <strong for="inputEmail3" class="col-form-label">Events</strong>
                                <select #AbnrmlType class="col-sm-10 form-select form-select-sm" formControlName="abnormalityType">
                                    <option value="200">Zero Stock</option>
                                    <option value="201">&lt; Min</option>
                                    <option value="202">&gt; Max</option>
                                </select>
                            </div>
                            <div class="col-sm-3  mb-3">
                                <strong for="inputEmail4" class="col-form-label">Facility category:</strong>
                                <select class="form-select form-select-sm" aria-label="Default select example"
                                    formControlName="includeStoreBadge" (change)="selected('inf', $event)">
                                    <option value="null">All</option>
                                    <option *ngFor="let ft of lstFacTags" [value]="ft.id">{{ft.name}}</option>
                                </select>
                            </div>
                            <!-- <div class="col-sm-3  mb-3">
                                <label for="inputEmail4" class="col-form-label">Material category:</label>
                                <select class="form-select form-select-sm" aria-label="Default select example"
                                    formControlName="includeProductBadge" (change)="selected('inp', $event)">
                                    <option value="null">All</option>
                                    <option *ngFor="let mt of lstMatTags" [value]="mt.id">{{mt.name}}</option>
                                </select>
                            </div> -->
                            <div class="form-group col-xl-4_5">
                                <strong class="col-form-label">Material By</strong>
                                <div class="form-check form-check-inline mt-2 p-l-25">
                                    <input class="form-check-input" type="radio" id="rbFac0" name="isMatBdge"
                                        [(ngModel)]="isMatBdge" (click)="onClkRdb('category')" value="category"
                                        [checked]="isMatBdge=='category'" formControlName="isMatBdge">
                                    <label class="form-check-label" for="fac">Category</label>
                                </div>
                                <div class="form-check form-check-inline mt-2 p-l-25">
                                    <input class="form-check-input" type="radio" id="rbFac1" name="isMatBdge"
                                        [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" value="badge"
                                        (click)="onClkRdb('includeProductBadge')" formControlName="isMatBdge">
                                    <label class="form-check-label" for="fac">Tag</label>
                                </div>
                                <div class="form-check form-check-inline mt-2 p-l-25">
                                    <input class="form-check-input" type="radio" id="rbFac2" name="isMatBdge"
                                        [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" value="name"
                                        (click)="onClkRdb('productIds')" formControlName="isMatBdge">
                                    <label class="form-check-label" for="fac">Name</label>
                                </div>
                                <div *ngIf="isMatBdge=='category'">
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="stkMtrlCat"
                                        [data]="lstMatMainCtgrs">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div *ngIf="isMatBdge=='badge'">
                                    <!-- <select class="form-select form-select-sm" aria-label="Default select example"
                                        formControlName="includeProductBadge" (change)="selected('inp', $event)" [(ngModel)]="slctMatId">
                                        <option value="">All</option>
                                        <option *ngFor="let mt of lstMatTags" [value]="mt.id">{{mt.name}}</option>
                                    </select> -->
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags"
                                        (onSelect)="onMultiSlct('s', 'b', $event)" (onSelectAll)="onMultiSlct('a', 'b', $event)"
                                        (onDeSelect)="onMultiSlct('r', 'b', $event)" (onDeSelectAll)="onMultiSlct('d', 'b', $event)"
                                        [(ngModel)]="slctMatTags" formControlName="includeProductBadge">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div *ngIf="isMatBdge=='name'">
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats"
                                        (onSelect)="onMultiSlct('s', 'n', $event)" (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                        (onDeSelect)="onMultiSlct('r', 'n', $event)" (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                        [(ngModel)]="slctdMatName" formControlName="productIds">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-xl-1_4" style="padding-top:2.5rem;">
                                <div class="float-end">
                                    <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;onReset()">Reset</button>&nbsp;
                                    <button class="btn btn-sm btn-primary" (click)="crntPage=1;LoadGrid()">Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-body p-4 border-0">
                <div class="row" *ngIf="lstAS.length!=0">
                    <div class="col-xl-4 mb-2">
                        <button class="btn btn-sm btn-success" (click)="onEE()">
                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="lstAS.length!=0">
                    <div class="col-xl-6 text-start">
                        {{pageEntrs}}
                        <!-- <span style="display: inline-block;">
                            <label tyle="display: inline-block; me-2">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                    </div>
                    <div class="col-xl-6">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="small">Stock Management items with zero stock, &lt; min. and &gt; max. are listed below. You can click on any given item to view the event history for that item.</div>
                <div class="row ps-0 pe-0 pb-0" *ngIf="lstAS.length!=0">
                    <div class="col-lg-6 col-xxl-6 pb-1"></div>
                </div>
                <div class="table_spacing table-responsive mt-3">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr class="brdrClr">
                                <th class="brdrClr">&nbsp;</th>
                                <th class="brdrClr">Item No.</th>
                                <th class="brdrClr">Material</th>
                                <th class="brdrClr">Facility</th>
                                <th class="text-center brdrClr">Total stock</th>
                                <th class="text-center brdrClr">Min.</th>
                                <th class="text-center brdrClr">Max.</th>
                                <th class="brdrClr">Duration</th>
                                <th class="brdrClr">Since</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of lstAS | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                                let i=index" [hidden]="lstAS.length==0">
                            <tr [ngStyle]="{ 'background': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                    'color': item.stockIndicator != null ? '#000000' : null }" class="expand-row brdrClr"
                                (click)="item.isShow=!item.isShow;loadProducts(item.storeId, item.productId, i)"> <!-- getClrCode(item.abnormalityType) -->
                                <td class="brdrClr">
                                    <a [routerLink]="" class="text-black">
                                        <span data-container="body" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Popover title">
                                            <i class="fa fa-external-link" aria-hidden="true"></i></span>
                                    </a>
                                </td>
                                <td class="brdrClr">{{(pageSize*(crntPage-1))+i+1}}</td>
                                <td class="brdrClr">{{item.productName}}</td>
                                <td class="brdrClr">{{item.storeName}} <p><small>{{item.state}},{{item.district}},{{item.city}}</small></p></td>
                                <td class="text-center brdrClr">{{item.currentStock}}</td>
                                <td class="text-center brdrClr">{{item.invMin}}</td>
                                <td class="text-center brdrClr">{{item.invMax}}</td>
                                <td class="brdrClr">{{item.duration}}</td>
                                <td class="brdrClr">{{(item.since != '' && item.since != null) ? (item.since | date: "dd-MM-yyyy") : ''}}</td>
                            </tr>
                            <tr *ngIf="item.isShow && AbnrmlType.value!='null' && item?.lstPrdcts?.length!=0" class="sub-table no-bg">
                                <td colspan="9" class="p-0">
                                    <h3>Recent {{AbnrmlType.value=='200'?'Zero Stock':(AbnrmlType.value=='201'?'&lt;
                                        Min':(AbnrmlType.value=='202'?'&gt; Max':''))}} for {{item.productName}}</h3>
                                </td>
                            </tr>
                            <tr *ngIf="item.isShow" class="sub-table no-bg">
                                <td colspan="9" class="p-0">
                                    <table class="table mb-0 table-striped">
                                        <thead class="bg-dark text-white">
                                            <tr>
                                                <th class="text-center">Sl No.</th>
                                                <th class="text-center">Material</th>
                                                <th class="text-center">Facility</th>
                                                <th class="text-center">From</th>
                                                <th class="text-center">Until</th>
                                                <th class="text-center">Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="item?.lstPrdcts?.length!=0">
                                            <tr *ngFor="let a of item?.lstPrdcts; let j=index">
                                                <th class="text-center">{{j+1}}</th>
                                                <td class="text-center">{{a.productName}}</td>
                                                <td class="text-center">{{a.storeName}}</td>
                                                <td class="text-center">{{a.fromDate | date: "dd/MM/yyyy hh:mm aa" }}</td>
                                                <td class="text-center">{{a.since | date: "dd/MM/yyyy hh:mm aa" }}</td>
                                                <td class="text-center">{{a.duration}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="item?.lstPrdcts?.length==0">
                                            <tr>
                                                <td colspan="8" class="text-center">
                                                    {{noRcrdsTxt}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                        <tbody [hidden]="lstAS.length!=0">
                            <tr>
                                <td colspan="9" class="text-center">
                                    {{noRcrdsTxt}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row ps-0 pe-0 pt-3 pb-0" *ngIf="lstAS.length!=0">
                    <div class="col-xl-6 text-start">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-6">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                    <div class="col-xl-6 text-start">
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }" class="clrCde p-1 me-2">Zero Stock</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }" class="clrCde p-1 me-2">Min</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }" class="clrCde p-1 me-2">Max</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>