<div class="card p-0 border-0">
    <div class="card-body p-2">
        <div class="row p-0 mt-2">
            <form [formGroup]="inddscpfrm" autocomplete="off">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-xl-3 mt-3">
                                    <label for="textBox">Facility</label>
                                    <ng-template #cstmStore let-model="item">
                                        <span class="font-weight-bold">{{model.storeName}}</span><br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="issuingStoreId" type="text" placeholder="Filter by Issuing Facility"
                                        formControlName="storeId" [typeaheadItemTemplate]="cstmStore"
                                        typeaheadOptionField="storeName" [typeahead]="lstPrnts" [adaptivePosition]="true"
                                        class="form-control form-control-sm" (typeaheadOnSelect)="changeFn($event, 'slct')"
                                        (change)="changeFn($event, 'chng')" [typeaheadScrollable]="true" id="issuingStoreId"
                                        [attr.disabled]="usrStrLen==1?true:null" [typeaheadOptionsLimit]="500" />
                                </div>
                               
                                <!-- [hidden]="invtrasLstFrm.get('storeId')?.value == null" -->
                                <!-- <div class="form-group col-xl-3" *ngIf="!shwAPrds" [hidden]="true">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.productName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstAllMats"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="productName" [adaptivePosition]="true"
                                        class="form-control form-control-sm" [typeaheadScrollable]="true"
                                        (typeaheadOnSelect)="changeFn2($event, 'byStr', 'onSlct')"  [attr.disabled]="usrStrLen==1?true:null"
                                        (change)="changeFn2($event, 'byStr', 'chng')" [typeaheadOptionsLimit]="500" >
                                </div> -->
                                <!-- [hidden]="invtrasLstFrm.get('storeId')?.value != null" -->
                                <!-- <div class="form-group col-xl-3" *ngIf="shwAPrds" [hidden]="true">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct1 let-model="item">
                                        <span class="font-weight-bold">{{model.name}}</span> <br />
                                    </ng-template>
                                    <input name="product" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstAllMats1"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct1"
                                        typeaheadOptionField="name" [adaptivePosition]="true"
                                        class="form-control form-control-sm" [typeaheadScrollable]="true"
                                        (typeaheadOnSelect)="changeFn2($event, 'all', 'onSlct')"  [attr.disabled]="usrStrLen==1?true:null"
                                        (change)="changeFn2($event, 'all', 'chng')" [typeaheadOptionsLimit]="500" >
                                </div> -->

                                <div class="form-group col-xl-4_5">
                                    <label class="col-form-label">Material By<span class="text-danger"></span></label>&nbsp;&nbsp;
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="isMatBdge" value="category"
                                            [(ngModel)]="isMatBdge" (click)="lvlBdg1='';onClkRdb('category')"
                                            [checked]="isMatBdge=='category'" formControlName="isMatBdge">
                                        <label class="form-check-label" for="mat">Category</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2 p-l-25">
                                        <input class="form-check-input" type="radio" id="rbMat1" name="isMatBdge" value="badge"
                                            [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" formControlName="isMatBdge"
                                            (click)="onClkRdb('productTags')">
                                        <label class="form-check-label" for="mat">Tag</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="rbMat2" name="isMatBdge" value="name"
                                            [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" formControlName="isMatBdge"
                                            (click)="onClkRdb('productId')">
                                        <label class="form-check-label" for="mat">Name</label>
                                    </div>
                                    <div class="w-67">
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="stkMtrlCat"
                                            (onSelect)="onMultiSlct('s', 'c', $event)" (onSelectAll)="onMultiSlct('a', 'c', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'c', $event)" (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                                            [data]="lstMatMainCtgrs" *ngIf="isMatBdge=='category'" [(ngModel)]="slctdMatName"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.stkMtrlCat.errors }">
                                        </ng-multiselect-dropdown>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMatrl"
                                            (onSelect)="onMultiSlct('s', 'm', $event)" [data]="lstMatTags" *ngIf="isMatBdge=='badge'"
                                            (onSelectAll)="onMultiSlct('a', 'm', $event)" (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)" formControlName="productTags"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productTags.errors }">
                                        </ng-multiselect-dropdown>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMaterials"
                                            (onSelect)="onMultiSlct('s', 'n', $event)" *ngIf="isMatBdge=='name'"
                                            (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="productId"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }" [(ngModel)]="slctdMatName">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <!-- <div *ngIf="isMatBdge=='badge'">
                                        <ng-multiselect-dropdown
                                            [settings]="dropdownSettings" [(ngModel)]="slctdMatrl"
                                            [data]="lstMatTags" formControlName="productTags"
                                            (onSelect)="onMultiSlct('s', 'm', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productTags.errors }">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="isSbmtd && fc.productTags.errors" class="invalid-feedback">
                                            <div *ngIf="fc.productTags.errors?.required">Material tag is required</div>
                                        </div>
                                    </div>
                                    <div *ngIf="isMatBdge=='name'">
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                            [data]="lstAllMaterials" formControlName="productId"                                                                        
                                            (onSelect)="onMultiSlct('s', 'n', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }"
                                            [(ngModel)]="slctdMatName">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                                            <div *ngIf="fc.productId.errors?.required">Material name is required</div>
                                        </div>
                                    </div> -->
                                </div>
                
                                <div class="form-group col-xl-3 mt-3">
                                    <label for="textBox">Discrepancy at</label>
                                    <select id="inputState" class="form-select form-select-sm" formControlName="discrepancyStatusId" #Status>
                                        <option value="">All</option>
                                        <option *ngFor="let os of ordLst" [value]="os.id">{{os.name}}</option>
                                    </select>
                                </div>
                                <div class="pt_2 col-xl-3 mt-3"><input class="" formControlName="isReleasIndent" type="checkbox" checked /> Exclude release Indents
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <button class="btn btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                                aria-controls="box1">Advance Search</button>
                        </div>
                    </div>
                    <div id="box1" class="collapse">
                        <div class="form-row row mb-2 mt-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">Indent Id</label>
                                <input type="text" placeholder="Indent Id" class="form-control" id="" formControlName="bookingId">
                            </div>
                            <div class="form-group col-md-3 pt-3">
                                <strong for="textBox">From</strong>
                                <div class="input-group">
                                    <!-- [dateCustomClasses]="cstmDt"  -->
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)"
                                        onkeypress='return event.charCode == 8' placeholder="From" readonly
                                        formControlName="fromDate" [(ngModel)]="frmDate" [maxDate]="dt2day">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                            [attr.aria-expanded]="dpFD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpFD.hide();clearBSDate('fromDate')">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>                                  
                            </div>
                            <div class="form-group col-md-3 pt-1">
                                <strong class="col-form-label">To</strong>
                                <div class="input-group">
                                    <!-- [dateCustomClasses]="cstmDt"  -->
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                        readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDtT($event)" onkeypress='return event.charCode == 8'
                                        formControlName="toDate" placeholder="To" [minDate]="frmDate" [maxDate]="dt2day">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                            [attr.aria-expanded]="dpTD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpTD.hide();clearBSDate('toDate')">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5" [hidden]="slctdStrId">
                                    <span class="pl-xl-3">Facility Category:
                                        <span *ngIf="isFacAll">ALL</span>
                                        <span *ngIf="!isFacAll && lstShowFacTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                                {{fc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstFacTags"
                                                                        formControlName="storeBadgeId"
                                                                        (onSelect)="onMultiSlct('s', 'f', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                                        [(ngModel)]="slctdFac">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onResetTags('f')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    role="button" data-bs-toggle="collapse"
                                                                    aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onTagsSave('f')">Save</button>
                                                                <button class="btn btn-sm btn-light"
                                                                    (click)="onCncl('f')" data-bs-toggle="collapse"
                                                                    aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-xl-6" [hidden]="true">
                                    <span class="pl-xl-3">Material Category:
                                        <span *ngIf="isMatAll">ALL</span>
                                        <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                                {{mc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="multiselect-dropdown">
                                                                    <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstMatTags"
                                                                        formControlName="productBadgeId"
                                                                        (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                        [(ngModel)]="slctdMat">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2"
                                                                    (click)="onResetTags('m')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2"
                                                                    href="#multiCollapseExample2" role="button"
                                                                    (click)="onTagsSave('m')">Save</button>
                                                                <button class="btn btn-sm btn-light"
                                                                    (click)="onCncl('m')" data-bs-toggle="collapse"
                                                                    aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;isExprt=false;lstGrid=[];onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;lstGrid=[];LoadDscrpances()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <hr />
        <!-- content panel start -->
        <div class="row mt-3">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-xl-6 mb-2">
                        <!-- <span style="display: inline-block;" class="me-2">
                            <label tyle="display: inline-block;">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                    </div>
                </div>
                <div class="card h-100 mb-0">
                    <div class="card-body p-3">
                        <div class="row" *ngIf="lstGrid?.length!=0">
                            <div class="col-xl-12 mb-2 text-start">
                                <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                    <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                </button>
                            </div>
                            <div class="col-xl-4 mb-2">
                                {{pageEntrs}}
                            </div>
                            <div class="col-xl-4 mb-2 text-center">
                            </div>
                            <div class="col-lg-4">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="table_spacing table-responsive table-row-spacing">
                            <table class="table table-sm table_spacing table-small-font p-0" id="table_detail">
                                <thead>
                                    <tr class="text-center">
                                        <th class="text-center">Item No.</th>
                                        <th>Indent Id</th>
                                        <th>Material</th>
                                        <th class="text-center">Recommended</th>
                                        <th>Ordered</th>
                                        <th class="text-center">Shipped</th>
                                        <th class="text-center">Fulfilled</th>
                                        <th>Status</th>
                                        <th>Receiving Facility</th>
                                        <th>Issuing Facility</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGrid?.length!=0">
                                    <tr *ngFor = "let item of lstGrid| paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems} ; let i = index" class="text-center">
                                        <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                        <td>{{item.bookingId}} <br>{{item.createdOn}}</td>
                                        <td>{{item.productName}}</td>
                                        <td>{{item.recommandedStock}}</td>
                                        <td>{{item.orderedStock}} <br> {{item.orderedDicrpncy}}</td>
                                        <td>{{item.shippedStock}} <br> {{item.shippedDicrpncy}}</td>
                                        <td>{{item.fulfilledStock}} <br> {{item.fulfilledDicrpancy}}</td>
                                        <td>{{item.status}} <br> {{item.updatedOn}}</td>
                                        <td>{{item.recevingStoreName}} <br> {{item.recevingStoreLocation}}</td>
                                        <td>{{item.issuingStoreName}} <br>{{item.issuingStoreLocation}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="lstGrid?.length==0">
                                    <tr>
                                        <td colspan="10" class="text-center">{{noRcrdTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstGrid?.length!=0">
                            <div class="col-xl-4 mb-2 text-start">
                                {{pageEntrs}}
                            </div>
                            <div class="col-xl-4 text-center">
                            </div>
                            <div class="col-lg-4">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row ps-0 pe-0 pt-3 pb-3">
                    <div class="col-lg-6 col-xxl-6 pb-3">
                    </div>
                    <div class="col-lg-6 col-xxl-6 pb-3">
                
                        <nav aria-label="Page navigation example ">
                            <ul class="pagination justify-content-xl-end  pagination-primary ">
                                <li class="page-item"><a class="page-link" aria-label="Previous" data-bs-original-title=""
                                        title=""><span aria-hidden="true">«</span><span class="sr-only">Previous</span></a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a></li>
                                <li class="page-item"><a class="page-link" aria-label="Next" data-bs-original-title="" title=""><span
                                            aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>