import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

declare let $: any;

@Component({
  selector: 'evin-facility-summary',
  templateUrl: './facility-summary.component.html',
  styleUrls: ['./facility-summary.component.css']
})

export class FacilitySummaryComponent extends BaseComponent implements OnInit, AfterViewInit {

  strId: any; strDetails: any; strMappingsLst: any[] = []; IssuFaciLst: any[] = []; RecFacLst: any[] = []; pageMatEntrs = ''; crntRFPage = 1;
  noRcrdsTxt = environment.TblNoRcrds; addStatus = true; crntMatPage = 1; pgSze = environment.tblPageSize; ttlItemsMat: any; lstMat: any[] = [];
  lstAllFacilities: any[] = []; mappingType: any = 'v'; strIds: any[] = []; lstFacTags: any[] = []; lstMatTags: any[] = []; mtrlList: any[] = [];
  lstSlctdMatTags: any[] = []; lstSlctdStkMatTags: any[] = []; slctdMat: any; slctdstrPrnth: any = ""; msdStngs: any = {
    idField: 'id',
    singleSelection: false, textField: 'name', selectAllText: 'ALL', unSelectAllText: 'NONE', itemsShowLimit: 3, allowSearchFilter: true
  };
  lstFreeMtrls: any[] = []; iCatId: any;
  isShowMat = true; isEdtAd = false; iCatlog: any; tabType: any; crntStkPage = 1; ttlStkItems: any; pageRFEntrs = ''; pageStkEntrs = '';
  lstStkMtrls: any[] = []; asstsList: any[] = []; strPrfle: any[] = []; rungTab = 'sm'; asetType: any[] = []; isIndntTab = false;
  isTransTab = false; isMatsTab = false; isRelShpTab = false; isCCETab = false; isPrflTab = false; astTypLst: any[] = []; rsFacId: any = ''; rsFacCatId: any = '';
  prmsns: any = null; fcltyLst: boolean = true; fcltyEdt: boolean = true; fcltyDlt: boolean = true; fcltydtl: boolean = true;
  fcltyDtlViewIndents: boolean = true; fcltyDtlEdtMtrl: boolean = true; fcltyDtlViewRltnshp: boolean = true; fcltyDtlAddRltnshp: boolean = true;
  fcltyDtlViewMtrl: boolean = true; fcltyDtlAddMtrl: boolean = true; fcltyDtlDltMtrl: boolean = true; fcltyDtlViewPrfl: boolean = true; fcltyDtlViewTransctn: boolean = true;
  indntPrmsns: any = null; transactnsPrmsns: any = null; assetsPrmsns: any = null; mtrlLst: boolean = true; cratMtrls: boolean = true; indntLst: boolean = true; cratIndnt: boolean = true;
  trnsactnsLst: boolean = true; cratTrnsactns: boolean = true; cceMngmntLst: boolean = true; fcltyDtlviewAssets: boolean = true; slctdMtrlsLst: any[] = []; mtrlId: any[] = []; slctdDmndtls: any;
  // Indents List
  crntILPage = 1; ttlILItems: any; pageILEntrs = ''; slctdFcltyId: any; ordersList: any[] = []; count: any;
  isFacAll = false; isOrdAll = false; lstShowFacTags: any[] = []; slctdFac: any[] = []; isAPICald = true; filterMetadata = { count: 0 };
  lstShowOrdTags: any[] = []; lstSlctdOrdTags: any[] = []; slctdOrd: any[] = []; lstStrs: any[] = []; lstSlctdFacTags: any[] = [];
  dt2day = new Date(); orderlstfrm = this.fb.group({
    expireBefore: [''], approvalStatus: [null], fromDate: [''], issueReferenceId: [''],
    issuingStoreName: [''], receiptReferenceId: [''], status: [''], recevingStoreName: [''], bookingId: [''], searchText: [], storeId: [''],
    storeBadgeId: [null], toDate: [this.dt2day], bookingBadgeId: [null], indentTypeId: ['1']
  }); ttlItems: any; lstOrdTyps: any[] = [];
  searchText: any; orderStatusList: any[] = []; lstTags: any[] = [];
  // Transactions List
  lstShowMatTags: any[] = []; lstAllMats1: any[] = []; shwAPrds = true; lstFCF: any[] = []; lstMats: any[] = []; lstAllMats: any[] = [];
  lstTrans: any[] = []; dstLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; lstMCF: any[] = []; lstTxnTyps: any[] = []; isPgLd = true;
  txnRsns: any[] = []; usrLvl = ''; pageTransEntrs = ''; crntTransPage = 1; frmDate = this.wkBkDt; ttlTransItems: any; isMatAll = false;
  invtrasLstFrm = this.fb.group({
    txnsToDate: [this.dt2day], txnReason: [''], productBadge: [null], storeId: [null], storeBadge: [null],
    productId: [null], txnType: [''], txnsFromDate: [null], isActualTxn: [false], state: [null], district: [null], country: [null],
    block: [null]
  }); isInitTxnDate = false; isShowDAT = false; slctdStrId: any; slctPrdId: any; isSUFclty = true;
  // CCE List
  assetslstfrm = this.fb.group({
    asetTypeId: [''], alarms: ['0'], relationships: [''], serialNumber: [''], approvalStatus: [8], storeId: [''],
    monitoringPoint: [''], statusId: [''], assetId: [''], assetTypes: [''], duration: ['']
  }); slctdAsstTypes: any[] = []; lstPrnts: any[] = [];
  astTypeMntrg: any[] = []; astTypeMntrd: any[] = []; pageCCEEntrs = ''; crntCCEPage = 1; ttlCCEItems: any; astStatusList: any[] = [];
  isShowAstdtls = true; toDate = ''; dratn: boolean = false; shwAlrmDrpdwn = true; paramsTab = ''; facltyDtlsFrm = this.fb.group({
    productId:
      [null, Validators.required], includeProductBadge: [''], minStock: [''], maxStock: [''], mtrlFrm: this.fb.array([])
  });
  // Materials
  isSbmtd = false; slctdMatId: any; lstMatTagsStk: any[] = []; slctMat: any = ''; mtrlGtLst: any[] = []; rlnFacCatId: any = '';
  issFacSlctdId: any = ''; crntRlnPage = 1; pageRlnEntrs = ''; ttlItemsRln: any; rsFacIdToAd: any = ''; slctdMltplMtrls: any[] = []; chkdLst: any[] = [];


  @ViewChild('indLst') indLst: any; @ViewChild('txnLst') txnLst: any; @ViewChild('stkTab', { static: false }) stkTab: any; @ViewChild('indTab', { static: false }) indTab: any;
  @ViewChild('txnTab', { static: false }) txnTab: any; @ViewChild('mtrlTab', { static: false }) mtrlTab: any; @ViewChild('rlnshpTab', { static: false }) rlnshpTab: any;

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder,
    private actRte: ActivatedRoute,
    private ngbMdl: NgbModal,
    private router: Router, private route: ActivatedRoute) {
    super(glblSvc);
    this.glblSvc.sbjctStrId.subscribe(s => {
      if (s)
        this.strId = s;
      else
        this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } });
    });
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
        this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product);
    }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astTypLst = s.astTypLst, this.astTypeMntrd = this.astTypLst.filter(f => f.deviceTypeId == 2), this.astTypeMntrg = this.astTypLst.
        filter(f => f.deviceTypeId == 1), this.lstTxnTyps = s.txnTypeLst, this.txnRsns = s.rsnsLst?.filter((f: any) => f.reasonType ===
          environment.cnfgTrnsRsnType.discard || f.reasonType === environment.cnfgTrnsRsnType.stk_edit), this.orderStatusList = s.stsLst?.
            filter((f: any) => f.statusType === 'Order'), this.astStatusList = s.stsLst.filter((f: any) => f.statusType === 'Asset'),
        this.lstOrdTyps = s.ordTypeLst
    }), this.glblSvc.sbjctStrList.subscribe(s => {
      this.lstStrs = s, this.usrStrLen = s.length;
      if (this.usrStrLen == 1) this.onChngFclty(this.lstStrs[0].storeName, 'str1'), this.slctdFcltyId = this.lstStrs[0].storeId;
    });
    this.lstMatTagsStk = this.lstMatTags; this.route.queryParams.subscribe(params => {
      this.paramsTab = params.tab;
      if (params.type == 'list' && params.tab == 'il') {
        let el: HTMLElement = this.indLst.nativeElement;
        el.click(), this.LoadOrders();
      }
      if (params.type == 'list' && params.tab == 'tl') {
        let el: HTMLElement = this.txnLst.nativeElement;
        el.click(), this.LoadStoreTransactions(false);
      }
      if (localStorage.getItem('suFcltyId') != null) {
        if (this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId')).length > 0)
          this.onChngFclty(this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, 'str1'),
            this.changeFn(this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, 'str1'), this.isSUFclty = true,
            this.onChngFcltyCCE(this.lstStrs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, 'str1');

      }
    });
  }

  ngOnInit(): void {
    this.slctdDmndtls = JSON.parse(<string>localStorage.getItem('slctdDmnDtls'));
    this.actRte.queryParams.subscribe(params => { this.tabType = params.tab; }), this.slctdAsstTypes = this.astTypLst.filter(e => e.id != 1),
      this.slctdAsstTypes.filter(s => this.asetType.push(s.id));
    if (this.usrLgnDtls) {
      if (this.usrLgnDtls?.permissions != null) {
        let astDtlsPrmsns = this.usrLgnDtls?.permissions.filter((pm: any) => pm.parentModuleId === 1)[0]?.modulePermissionRoles.
          filter((mm: any) => mm.moduleId === 1)[0]?.permissionRoles.filter((pr: any) => pr.permissionId === 15)[0]?.configuredValue;
        this.isShowAstdtls = astDtlsPrmsns != null ? !astDtlsPrmsns.value : true;
      }
    }
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.indntPrmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Indents : null;
    this.transactnsPrmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Stock_Management : null;
    this.assetsPrmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Stock_Management : null;

    this.prmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_st_fc_li')
        this.fcltyLst = e?.action;
      if (e?.menuCode == 'mm_st_fc_ed')
        this.fcltyEdt = e?.action;
      if (e?.menuCode == 'mm_st_fc_dt')
        this.fcltyDlt = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_vsm')
        this.fcltydtl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_id')
        this.fcltyDtlViewIndents = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_em')
        this.fcltyDtlEdtMtrl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_vm')
        this.fcltyDtlViewMtrl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_am')
        this.fcltyDtlAddMtrl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_dm')
        this.fcltyDtlDltMtrl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_pf')
        this.fcltyDtlViewPrfl = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_tx')
        this.fcltyDtlViewTransctn = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_vr')
        this.fcltyDtlViewRltnshp = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_ar')
        this.fcltyDtlAddRltnshp = e?.action;
      if (e?.menuCode == 'mm_st_ma_li')
        this.mtrlLst = e?.action;
      if (e?.menuCode == 'mm_st_ma_cm-bu')
        this.cratMtrls = e?.action;
      if (e?.menuCode == 'mm_st_fc_fd_va')
        this.fcltyDtlviewAssets = e?.action;

    });
    this.indntPrmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_id_ids_li')
        this.indntLst = e?.action;
      if (e?.menuCode == 'mm_id_ids_ci')
        this.cratIndnt = e?.action;
    });
    this.transactnsPrmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_sm_tx_li')
        this.trnsactnsLst = e?.action;
      if (e?.menuCode == 'mm_sm_tx_ct')
        this.cratTrnsactns = e?.action;
    });
    this.assetsPrmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_cm_li')
        this.cceMngmntLst = e?.action;
    });

    if (localStorage.getItem('suFcltyId') != null && localStorage.getItem('suFcltyPrnthId') != null) {
      this.strId = localStorage.getItem('suFcltyId'), this.slctdstrPrnth = localStorage.getItem('suFcltyPrnthId'), setTimeout(() => {
        this.glblSvc.sbjctSpnr.next(true), Promise.all([this.LoadStoreData(this.strId, false)]).then(() => { setTimeout(() => { this.glblSvc.sbjctSpnr.next(false); }, 500); });
      }, 1000);
    }
    else
      this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } });
  }

  ngAfterViewInit() {
    let el: HTMLElement = this.paramsTab == 's' ? this.stkTab.nativeElement : (this.paramsTab == 'i' ? this.indTab.nativeElement :
      (this.paramsTab == 't' ? this.txnTab.nativeElement : (this.paramsTab == 'm' ? this.mtrlTab.nativeElement : (this.paramsTab == 'r' ?
        this.rlnshpTab.nativeElement : null))));
    if (el != null)
      el.click();
  }

  // initRows() {
  //   return this.fb.group({
  //     minValue: [""], maxValue:[""]
  //   });
  // }

  GetMatrls(): FormArray {
    return this.facltyDtlsFrm.get('mtrlFrm') as FormArray;
  }

  onAddFormCtrls(matName: string, mId: any, min: any, max: any, indx: number = 0) {
    let matData = this.lstFreeMtrls.filter(f => f.product_name === matName)[0];
    return this.fb.group({
      productId: [mId, [Validators.required]], name: [matName, [Validators.required]],
      minValue: [min], maxValue: [max]
    });
  }
  // onClrMatData() {
  //   (<FormArray>this.mtrlFrm.get('addAllMaterial')).controls = [];
  // }


  tabClick(tab: any) {
    if (tab == 'i')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'i', type: 'list' } });
    else if (tab == 'ii')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'il', type: 'list' } });
    else if (tab == 'ci')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'i', type: 'create' } });
    else if (tab == 't')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 't', type: 'list' } });
    else if (tab == 'tl')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'tl', type: 'list' } });
    else if (tab == 'tc')
      this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'tc', type: 'create' } });

  }

  onMainTabClk(tab: any) {
    if (tab == 's')
      this.LoadMatsSM(this.strId, false);
    else if (tab == 'i') {
      if (this.indntLst) {
        this.tabClick('ii');
        this.LoadOrders();
      }
      else if (this.cratIndnt) {
        document.getElementById('contact-top-tab-3')?.click();
      }
    }
    else if (tab == 't') {
      if (this.trnsactnsLst) {
        this.tabClick('tl');
        this.LoadStoreTransactions();
      }
      else if (this.cratTrnsactns)
        document.getElementById('contact-top-tab-30')?.click();
    }
    else if (tab == 'm')
      this.GetAllMtrlLst(), this.GetMatLst();
    else if (tab == 'r')
      this.LoadStrMpngs('v', this.strId, false), this.LoadStrMpngs('c', this.strId, false);
    else if (tab == 'a')
      this.glblSvc.sbjctAsstStrId.next(this.strId);
    //  this.LoadAssets();
    else if (tab == 'p')
      this.GetStrPrfle();
    else
      this.GetAllMtrlLst();
  }

  get fc() { return this.facltyDtlsFrm.controls; }

  LoadStoreData(strId: any, shwSpnr: boolean = true) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getStrById.replace('sss', strId), null, 7,
      shwSpnr).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.strDetails = this.result.data;
        }
      });
  }

  LoadStrMpngs(type: any, strId: any, shwSpnr: boolean = true, rlnID = '') {
    this.strMappingsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.strMappings, environment.apiMthds.getLinkedStores.
      replace('pranthId', strId).replace('ordType', type) + rlnID, null, 0, shwSpnr).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (type == 'c')
              this.RecFacLst = [], this.RecFacLst = this.result.data != null ? this.result.data : [];
            else
              this.IssuFaciLst = [], this.IssuFaciLst = this.result.data != null ? this.result.data : [];
          }
        }
      });
  }

  rlnFacChange(evnt: any, type: any) {
    if (type == 'c')
      this.rlnFacCatId = evnt.target.value, this.LoadStrMpngs('c', this.strId, false, this.rlnFacCatId);
    else
      this.issFacSlctdId = evnt.target.value, this.LoadStrMpngs('v', this.strId, false, this.issFacSlctdId);
  }

  delRlnMpngStre(stId: any, type: any) {
    if (confirm('Are you sure want to delete the store relationship ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.strMappings, environment.apiMthds.delById + stId, null, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message);
            if (type == 'c')
              this.LoadStrMpngs('c', this.strId, false);
            else
              this.LoadStrMpngs('v', this.strId, false);
          } else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  resetFacCatRln(type: any) {
    if (type == 'c')
      this.rlnFacCatId = '', this.LoadStrMpngs('c', this.strId, false, this.rlnFacCatId);
    else
      this.issFacSlctdId = '', this.LoadStrMpngs('v', this.strId, false, this.issFacSlctdId);
  }

  LoadMatsSM(sId: number, shwSpnr: boolean = true) {
    let matIds: any[] = [];
    this.lstSlctdStkMatTags.filter(e => { matIds.push(e.id) });
    if (sId) {
      this.lstStkMtrls = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.fcltyStkMangment.
        replace('sss', `${sId}`).replace('rrr', '0').replace('pageNo', `${this.crntStkPage - 1}`).replace('pageSize', `${this.pgSze}`).
        replace('null', (matIds.length != 0 ? matIds.toString() : 'null')), 0, 7, shwSpnr).then(() => { // .replace('&page=pageNo&size=pageSize', '')
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.data)
                this.lstStkMtrls = this.result.data?.productsDTOs ?? [], this.ttlStkItems = this.result.data?.totalRecordCount ?? 0,
                  this.pageStkEntrs = this.GetPageSizeTxt(this.crntStkPage, this.pgSze, this.ttlStkItems);
            }
          }
        });
    }
  }

  fltrStkVal() {
    this.LoadMatsSM(this.strId, false);
  }

  onChngTAH(event: any) {
    if (this.lstFreeMtrls.filter(f => f.product_name === event.value).length > 0)
      this.slctdMatId = this.lstFreeMtrls.filter(f => f.product_name === event.value)[0].id;
    else
      this.facltyDtlsFrm.get('productId')?.setValue(null);
  }

  saveMat() {
    this.isSbmtd = true, this.checkFormValidations(this.facltyDtlsFrm);
    if (this.facltyDtlsFrm.invalid)
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
    else {
      let frmCtrls = this.facltyDtlsFrm.getRawValue(), payLoad: any;
      if (this.isEdtAd) {
        payLoad = {
          productId: this.lstMat.filter(f => f.name === frmCtrls.productId)[0]?.id ?? 0, currentStock: 0, daysOfStock: 0,
          minStock: frmCtrls.minStock == 'null' ? 0 : +frmCtrls.minStock, maxStock: frmCtrls.maxStock == 'null' ? 0 : +frmCtrls.maxStock,
          storeId: this.strId, id: this.iCatlog, pranthId: this.slctdstrPrnth, totalStock: 0, inTransitStock: 0
        };
      }
      else {
        payLoad = {
          productId: frmCtrls.productId == 'null' ? 0 : +this.slctdMatId, totalStock: 0, storeId: this.strId,
          minStock: frmCtrls.minStock == 'null' ? 0 : +frmCtrls.minStock, maxStock: frmCtrls.maxStock == 'null' ? 0 : +frmCtrls.maxStock,
          currentStock: 0, daysOfStock: 0, inTransitStock: 0, pranthId: this.slctdstrPrnth
        };
      }
      if (payLoad.productId != 0 || !payLoad.productId) {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.saveRupdate, payLoad, 0).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              this.GetMatLst(), this.isShowMat = true, this.facltyDtlsFrm.get('productId')?.setValue(''), this.slctdMatId = 0,
                this.facltyDtlsFrm.get('minStock')?.setValue(''), this.facltyDtlsFrm.get('maxStock')?.setValue('');
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      } else {
        this.glblSvc.onTstr('i', 'Please select material');
      }
    }
  }

  onAddMat() {
    this.lstFreeMtrls = [], this.lstMat = [], this.GetMatrls().clear();
    $("#flexCheckDefault11").prop("checked", false);

    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getPrductsBybadgId.replace('sss', this.strId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.lstMat = this.result.data != null ? this.result.data : [];
          // if (this.mtrlList.length > 0)
          //   this.lstMat.forEach(e => { if (this.mtrlList.filter(f => f.productid === e.product_id).length == 0) this.lstFreeMtrls.push(e); });
          // else
          //   this.lstFreeMtrls = this.lstMat;
          this.lstFreeMtrls = this.lstMat;


          this.facltyDtlsFrm.patchValue({ productId: null, minStock: null, maxStock: null });
          this.lstFreeMtrls.forEach((e: any) => {
            this.GetMatrls().push(this.onAddFormCtrls(e.product_name, e.product_id, 0, 0))
          })
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  onMatEdit(e: any) {
    this.lstFreeMtrls = [], this.iCatlog = e.icatalogue_id;
    if (this.mtrlList.length > 0)
      this.lstMat.forEach(e => { if (this.mtrlList.filter(f => f.productid === e.product_id).length == 0) this.lstFreeMtrls.push(e); });
    else
      this.lstFreeMtrls = this.lstMat;
    this.facltyDtlsFrm.patchValue({ productId: e.productname, minStock: e.minstock, maxStock: e.maxstock });
    if (this.isEdtAd) // while editing materials
      this.GetMatrls().controls.length = 0, this.GetMatrls().push(this.onAddFormCtrls((e?.productname ?? ''), (e?.productid ?? null),
        (e?.minstock ?? 0), (e?.maxstock ?? 0)))
  }

  onMatDel(id: any) {
    if (confirm("Are you sure want to delete.?")) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.invntry, environment.apiMthds.delById + id, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Records deleted")
              this.glblSvc.onTstr('s', this.result.message), this.GetMatLst();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onChngRT($event: any) {
    this.mappingType = $event.target.value;
  }

  onSlct($event: any, item: any) {
    if ($event.target.checked)
      item.flag = true, this.strIds.push(item.storeId);
    else
      item.flag = false, this.strIds.filter((a: any, index: any) => { if (a == item.storeId) this.strIds.splice(index, 1); })
  }

  onSlctAll($event: any) {
    this.strIds = [];
    if ($event.target.checked)
      this.lstAllFacilities.filter(a => { a.flag = true, this.strIds.push(a.storeId); })
    else
      this.lstAllFacilities.filter(a => { a.flag = false });
  }
  onSlctAllMtrl($event: any) {
    this.chkdLst = [];
    var ele: any = document.getElementsByName('chk');

    if ($event.target.checked) {
      this.GetMatrls().value.filter((a: any) => { a.flag = true, this.chkdLst.push(a) });
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == 'checkbox')
          ele[i].checked = true;
      }
    }
    else {
      this.GetMatrls().value.filter((a: any) => { a.flag = false });
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == 'checkbox')
          ele[i].checked = false;
      }
    }
  }

  onEditQty($event: any, obj: any, ind: number) {
    let pId = this.chkdLst?.some(f => f.productId == obj.value.productId);
    if (pId)
      this.chkdLst.filter(f => { if (f.productId == obj.value.productId) { f.minValue = obj.value.minValue, f.maxValue = obj.value.maxValue } })
    else
      obj.get('minValue')?.updateValueAndValidity(),
        obj.get('maxValue')?.updateValueAndValidity();
  }

  onChkChnge($event: any, obj: any, ind: number) {
    let temp: any[] = [];
    this.GetMatrls().value.forEach((e: any) => { temp.push(e) });
    var bb = temp.findIndex(f => f.productId == obj.value.productId);

    if ($event.target.checked)
      this.chkdLst.push(temp[bb]);
    else {
      let cc = this.chkdLst.findIndex(f => f.productId == temp[bb].productId)
      this.chkdLst.splice(cc, 1);
    }
  }

  addMultplMtrl() {
    let ch: any[] = [];
    if (this.chkdLst.length == 0) {
      this.glblSvc.onTstr('i', 'Please choose at least one record');
      return;
    }
    this.chkdLst.forEach((e: any) => {
      ch.push({
        productId: e.productId, totalStock: null, storeId: this.strId, minStock: +e.minValue ?? null, maxStock: +e.maxValue ?? null,
          currentStock: null, daysOfStock: null, inTransitStock: null, pranthId: this.slctdDmndtls?.pranthId, id: this.iCatId
      })
    })

    if (!this.isEdtAd)
      ch.forEach(e => { delete e?.id });

    setTimeout(() => {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
        environment.apiMthds.saveMtrlsForStrs, ch, 0).then(() => {
          if (this.result) {
            if (this.result?.status == 'OK')
              this.slctdMtrlsLst = [], this.lstFreeMtrls = [], this.chkdLst = [], this.isShowMat = true, this.GetMatLst(), this.GetMatrls().controls = [];
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    }, 1000);










    // let payLoad: any[] = [];
    // this.slctdMtrlsLst.filter(e => payLoad.push({
    //   productId: e.id, totalStock: null, storeId: this.strId,
    //   minStock: e.min ?? null, maxStock: e.max ?? null, currentStock: null, daysOfStock: null, inTransitStock: null, pranthId: this.slctdDmndtls?.pranthId
    // }));

    // this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
    //   environment.apiMthds.saveMtrlsForStrs, payLoad, 0).then(() => {
    //     if (this.result) {
    //       if (this.result?.status == 'OK')
    //         console.log(this.result),
    //           this.slctdMtrlsLst = [], this.lstFreeMtrls =[], this.GetMatrls().controls=[], this.onAddMat();
    //       else
    //         this.glblSvc.onTstr('e', this.result.message);
    //     }
    //   });
  }

  onChange($event: any) {
    this.mappingType = $event.target.value;
  }

  onMultiSlct(type: string, ctrl: any, item: any) {
    if (ctrl == 'stk') {
      if (type == 's') {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
      else if (type == 'r')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
      else if (type == 'a')
        this.lstSlctdMatTags = this.lstMatTags;
      else
        this.lstSlctdMatTags = [];
    }
    else {
      if (type == 's') {
        if (this.lstSlctdStkMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdStkMatTags.push(item);
      }
      else if (type == 'r')
        this.lstSlctdStkMatTags = this.lstSlctdStkMatTags.filter(f => f.id != item.id);
      else if (type == 'a')
        this.lstSlctdStkMatTags = this.lstMatTags;
      else
        this.lstSlctdStkMatTags = [];
    }
  }

  onStkReset(type: any) {
    if (type == 'stk')
      this.lstSlctdStkMatTags = [], this.slctdMat = '', this.LoadMatsSM(this.strId, false);
  }



  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => { /* if (result == 'done') //   return; // your code */ }, (r) => { console.log(r) });
  }

  GetAllMtrlLst(shwSpnr: boolean = true) {
    let matIds: any = [];
    this.lstSlctdStkMatTags.filter(e => { matIds.push(e.id) }), this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
      environment.apiMthds.stpFcGetAlprdctsBystrId.replace('sss', matIds).replace('ttt', this.strId), null, 7, shwSpnr).then(() => {
        if (this.result) {
          if (this.result?.status == 'OK')
            this.ttlItemsMat = this.result.data?.totalRecordCount ?? 0, this.pageMatEntrs = this.GetPageSizeTxt(this.crntMatPage, this.pgSze,
              this.ttlItemsMat), this.mtrlList = this.result?.data?.productDetails != null ? this.result?.data?.productDetails : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
  }

  GetMatLst(shwSpnr: boolean = true) {
    let crntPgIndx = this.crntMatPage - 1, bdgId = this.facltyDtlsFrm.value?.includeProductBadge == undefined ? '' :
      this.facltyDtlsFrm.value?.includeProductBadge; // this.lstSlctdStkMatTags.filter(e => { matIds.push(e.id) });
    this.mtrlGtLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.stpFcGetAlprdctsBystrId.
      replace('sss', bdgId).replace('ttt', this.strId).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pgSze}`), null, 7,
      shwSpnr).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.ttlItemsMat = this.result.data?.totalRecordCount ?? 0,
              this.pageMatEntrs = this.GetPageSizeTxt(this.crntMatPage, this.pgSze, this.ttlItemsMat),
              this.mtrlGtLst = this.result.data?.productDetails != null ? this.result.data.productDetails : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
  }

  GetStrPrfle(shwSpnr: boolean = true) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.stpFcGetPrfle.replace('sss', this.strId),
      null, 7, shwSpnr, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.strPrfle = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
  }

  onCreate() {
    let payLoad = {
      createdBy: this.usrLgnDtls?.userId, mapped_store_ids: this.strIds, updatedBy: this.usrLgnDtls?.userId,
      mappingType: $('#rltnType').val(), storeId: this.strId
    };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.strMappings, environment.apiMthds.creatStrRelation, payLoad, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message), this.mappingType = 'v', this.strIds = [], this.addStatus = true,
            this.lstAllFacilities.filter(a => { a.flag = false }), this.LoadStrMpngs('v', this.strId), this.LoadStrMpngs('c', this.strId);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  loadFacilities(shwSpnr: boolean = true, storeId: any = 0, storeBadgeId: any = 0) {
    let crntPgIndx = this.crntRlnPage - 1;
    this.lstAllFacilities = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getAllStrByFilter.
      replace('ppp', `${this.chsnDmnId}`).replace('sss', storeId).replace('false', 'true').replace('mpdStrddddd', this.strId).
      replace('bbb', storeBadgeId).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pgSze}`), null, 7, shwSpnr, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.ttlItemsRln = this.result.data?.totalRecordCount ?? 0,
                this.pageRlnEntrs = this.GetPageSizeTxt(this.crntRlnPage, this.pgSze, this.ttlItemsRln),
                this.result.data?.storeFiltersDTOs.forEach((e: any) => { Object.assign(e, { flag: false }) }),
                this.lstAllFacilities = this.result.data?.storeFiltersDTOs != null ? this.result.data?.storeFiltersDTOs : [];
            // this.totalItems = this.result.data?.totalRecordCount ?? 0,
            // this.pageEntrs = `${((this.crntPage * this.pageSize) - (this.pageSize - 1))}-${((this.crntPage * this.pageSize) < (this.totalItems)) ? (this.crntPage * this.pageSize) : (this.totalItems)} of ${this.totalItems}`;
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  onAddRlnFac() {
    this.loadFacilities(true, this.rsFacIdToAd, this.rsFacCatId);
  }

  resStrChng(evnt: any) {
    if (this.rsFacId != '') {
      if (this.lstStrs.filter(f => f.storeName == evnt.value)) {
        this.lstStrs.filter(f => { if (f.storeName == evnt.value) { this.rsFacIdToAd = f.storeId } })
      }
    } else { this.rsFacIdToAd = '', this.rsFacId = '' }
  }

  rltdFacStrsFltr() {
    this.loadFacilities(true, this.rsFacIdToAd, this.rsFacCatId);
  }

  resetFcaFlds() {
    this.rsFacId = "", this.rsFacIdToAd = "", this.rsFacCatId = "", this.loadFacilities();
  }

  createStrRelation() {
    if (this.strIds.length != 0) {
      let payLoad = {
        createdBy: this.usrLgnDtls?.userId, mappingType: $('#rltnType').val(), mapped_store_ids: this.strIds,
        updatedBy: this.usrLgnDtls?.userId, storeId: this.strId
      };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.strMappings, environment.apiMthds.creatStrRelation, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message), this.lstAllFacilities.filter(a => { a.flag = false }), this.mappingType = 'v',
              this.strIds = [], this.addStatus = true, this.glblSvc.sbjctSpnr.next(true), Promise.all([this.LoadStrMpngs('v', this.strId, false),
              this.LoadStrMpngs('c', this.strId, false)]).then(() => { this.glblSvc.sbjctSpnr.next(false); });
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
    else
      this.glblSvc.onTstr('e', 'Select related facility');
  }

  setPage(pagNo: number, type: string): void {
    if (type == 'sm')
      this.crntStkPage = pagNo, this.LoadMatsSM(this.strId);
    if (type == 'm')
      this.crntMatPage = pagNo, this.GetAllMtrlLst();
    if (type == 'il')
      this.crntILPage = pagNo, this.LoadOrders();
    if (type == 't')
      this.crntILPage = pagNo, this.LoadStoreTransactions();
    if (type == 'R')
      this.crntRlnPage = pagNo, this.loadFacilities();
  }
  // Indents List
  onChngFclty(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == '' || evnt == undefined)
      this.slctdFcltyId = '', this.orderlstfrm.get('storeId')?.setValue(''), this.orderlstfrm.get('recevingStoreName')?.setValue('');
    else {
      if (this.lstStrs?.filter(a => a.storeName == evnt).length != 0) {
        for (let s = 0; s < this.lstStrs.length; s++) {
          if (this.lstStrs[s].storeName == evnt) {
            this.slctdFcltyId = this.lstStrs[s].storeId, this.orderlstfrm.get('recevingStoreName')?.setValue(evnt);
            if (evntType === 'str1')
              this.orderlstfrm.get('storeId')?.setValue(evnt);
          }
        }
      }
      else
        this.slctdFcltyId = '', this.orderlstfrm.get('storeId')?.setValue(''), this.orderlstfrm.get('recevingStoreName')?.setValue('');
    }
  }

  onOrdIdClk(orderId: number) {
    if (orderId > 0)
      this.glblSvc.sbjctOrdId.next(orderId), localStorage.setItem('ordrId', `${orderId}`), this.router.navigate(['home/ord-ord'], { queryParams: { tab: 'sts', type: 'i' } });
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  onEE(type: string = 'i') {
    if (type == 't') {
      if (this.invtrasLstFrm.get('txnsFromDate')?.value == null || this.invtrasLstFrm.get('txnsToDate')?.value == null ||
        this.invtrasLstFrm.get('txnsFromDate')?.value == '' || this.invtrasLstFrm.get('txnsToDate')?.value == '')
        this.glblSvc.onTstr('w', 'Please choose both dates..');
      else {
        let dtDiff = this.CheckDaysDiff(new Date(this.invtrasLstFrm.get('txnsFromDate')?.value), new Date(this.invtrasLstFrm.get('txnsToDate')?.value));
        if (dtDiff >= 8)
          this.glblSvc.onTstr('i', 'Date range should not exceed more than 1 week..');
        else {
          let payLoad = this.invtrasLstFrm.getRawValue(), fT: any[] = [], mT: any[] = [], ddlVal = $('#ee').val();
          this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // .name
            payLoad.pranthId = this.chsnDmnId, payLoad.txnsFromDate = payLoad.txnsFromDate ? this.glblSvc.
              setDtFrmt(payLoad.txnsFromDate, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.txnsToDate = payLoad.txnsToDate ?
                this.glblSvc.setDtFrmt(payLoad.txnsToDate, this.dtF.ymd) + this.tmF.lastTM : null, payLoad.storeId = payLoad.storeId ?
                  this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null, payLoad.storeBadge = fT.length != 0 ?
                    fT : null, payLoad.productBadge = mT.length != 0 ? mT : null, payLoad.txnReason = payLoad.txnReason == '' ? null :
                      payLoad.txnReason, payLoad.isActualTxn = this.isInitTxnDate;
          if (ddlVal == 'nb') {
            this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.exprttrans.
              replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).
              replace('nnn', this.usrLgnDtls.userName).replace('eml', this.usrLgnDtls.email), payLoad).then(() => {
                if (this.result)
                  this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
              });
          }
          else {
            this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.exprtTransWB.
              replace('rrr', `${this.usrLgnDtls}`).replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).
              replace('eml', this.usrLgnDtls.email), payLoad).then(() => {
                if (this.result)
                  this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
              });
          }
        }
      }
    }
    else {
      let payLoad = this.orderlstfrm.getRawValue();
      payLoad.storeId = this.slctdFcltyId != null && this.slctdFcltyId != undefined && this.slctdFcltyId != '' ? +this.slctdFcltyId : null,
        payLoad.storeBadgeId = (payLoad.storeBadgeId != '' && payLoad.storeBadgeId != null) ? +payLoad.storeBadgeId : null,
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.pranthId = this.chsnDmnId, this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.
          exprtIndnts.replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).replace('eml', this.usrLgnDtls.email).
          replace('rrr', `${this.chsnDmnId}`), payLoad, 11).then(() => {
            if (this.result)
              this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
          });
    }
  }

  onReset(type: string = 'i') {
    if (type == 't') {
      if (this.usrLvl == 'National')
        this.invtrasLstFrm.get('state')?.setValue(null), this.invtrasLstFrm.get('district')?.setValue(null),
          this.invtrasLstFrm.get('block')?.setValue(null), this.invtrasLstFrm.get('state')?.enable(),
          this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
      if (this.usrLvl == 'State')
        this.invtrasLstFrm.get('district')?.setValue(null), this.invtrasLstFrm.get('block')?.setValue(null),
          this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.enable(), this.invtrasLstFrm.get('block')?.disable();
      if (this.usrLvl == 'District')
        this.invtrasLstFrm.get('block')?.setValue(null), this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(),
          this.invtrasLstFrm.get('block')?.enable();
      if (this.usrLvl == 'Block')
        this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
      this.invtrasLstFrm.patchValue({ txnReason: '', storeId: null, productId: null, txnType: null, isActualTxn: false }),
        this.lstShowFacTags = [], this.lstShowMatTags = [], this.slctdStrId = null, this.slctPrdId = null, this.shwAPrds = true,
        this.isShowDAT = false; // txnsFromDate: [this.wkBkDt], txnsToDate: [this.dt2day]
      if (this.lstStrs.length == 1)
        this.invtrasLstFrm.get('storeId')?.setValue(this.lstStrs[0].storeName), this.slctdStrId = this.lstStrs[0].storeId, this.shwAPrds = false,
          this.LoadMatsbyStoreId(this.slctdStrId);
      this.LoadStoreTransactions();
    }
    else if (type == 'ce') {
      this.assetslstfrm.reset(), this.assetslstfrm.get('asetTypeId')?.setValue(''), this.assetslstfrm.get('relationships')?.setValue(''),
        this.assetslstfrm.get('approvalStatus')?.setValue(''), this.assetslstfrm.get('alarms')?.setValue('0'), this.slctdFcltyId = null,
        this.asetType = [], this.LoadAssets();
    }
    else {
      this.slctdFcltyId = '', this.orderlstfrm.reset(), this.orderlstfrm.get('status')?.setValue(''), this.orderlstfrm.get('indentTypeId')?.setValue(1);
      if (this.lstStrs.length == 1)
        this.slctdFcltyId = this.lstStrs[0].storeId, this.orderlstfrm.get('recevingStoreName')?.setValue(this.lstStrs[0].storeName),
          this.orderlstfrm.get('storeId')?.setValue(this.lstStrs[0].storeName);
      this.LoadOrders(), this.lstShowFacTags = [], this.lstShowOrdTags = [], this.slctdOrd = [...[]], this.slctdFac = [...[]];
    }
  }

  LoadOrders(shwSpnr: boolean = true) {
    if (localStorage.getItem('isIndntAPICald') == null) { // if (this.isAPICald) {
      let crntPgIndx = this.crntILPage - 1, payLoad = this.orderlstfrm.getRawValue();
      payLoad.storeId = this.slctdFcltyId != '' ? +this.slctdFcltyId : null, localStorage.setItem('isIndntAPICald', '1'), // payLoad.status = payLoad.status != '' ? +payLoad.status : null,
        payLoad.storeBadgeId = (payLoad.storeBadgeId != '' && payLoad.storeBadgeId != null) ? +payLoad.storeBadgeId : null,
        payLoad.bookingBadgeId = (payLoad.bookingBadgeId != '' && payLoad.bookingBadgeId != null) ? +payLoad.bookingBadgeId : null,
        payLoad.bookingId = (payLoad.bookingId != '' && payLoad.bookingId != null) ? +payLoad.bookingId : null,
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.pranthId = this.chsnDmnId, delete payLoad.searchText,
        payLoad.indentTypeId = (payLoad.indentTypeId != null && payLoad.indentTypeId != '') ? +payLoad.indentTypeId : null;
      if (payLoad.indentTypeId == null && this.lstOrdTyps.length > 0)
        payLoad.indentTypeId = this.lstOrdTyps[0].id;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.getAllBookings.
        replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pgSze}`), payLoad, 0, shwSpnr).then(() => {
          if (this.result) {
            if (this.result.data)
              this.ttlILItems = this.result.data?.totalRecordsCount ?? 0, this.ttlItems = this.ttlILItems,
                this.pageILEntrs = this.GetPageSizeTxt(this.crntILPage, this.pgSze, this.ttlILItems);
            if (this.result.status == 'OK') {
              if (this.result.data)
                this.ordersList = this.result.data?.bookingFilterDTOs;
            }
          }
          setTimeout(() => { localStorage.removeItem('isIndntAPICald'); }, 500);
        });
    }
  }

  clearBSDate(ctrl: string, type: string) {
    if (type == 'i')
      this.orderlstfrm.get(ctrl)?.setValue('');
    else
      this.invtrasLstFrm.get(ctrl)?.setValue('');
  }
  // Indents List
  // Trans List
  changeFn(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == "" || evnt == undefined)
      this.shwAPrds = true, this.slctdStrId = null;
    else {
      if (this.lstStrs.filter(a => a.storeName == evnt).length != 0)
        this.slctdStrId = this.lstStrs.filter(a => a.storeName == evnt)[0]?.storeId, this.shwAPrds = false, this.invtrasLstFrm.get('storeId')?.setValue(evnt);
      else
        this.slctdStrId = null, this.shwAPrds = true, this.invtrasLstFrm.get('storeId')?.setValue(null);
    }
    this.invtrasLstFrm.get('productId')?.setValue(''), this.slctPrdId = null, this.LoadMatsbyStoreId(this.slctdStrId);
  }

  LoadMatsbyStoreId(sId: number) {
    if (sId && localStorage.getItem('isMatCald') == null) {
      let txnType = this.invtrasLstFrm.get('txnType')?.value;
      this.lstMats = [], this.lstAllMats = [], localStorage.setItem('isMatCald', '1'), this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
        environment.apiMthds.invMatbyStoreId.replace('sss', `${sId}`).replace('ttt', `${txnType == '' ? 0 : txnType}`), null).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.data)
                this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          }
          setTimeout(() => { localStorage.removeItem('isMatCald'); }, 500);
        });
    }
  }

  changeFn2(event: any, type: any, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == "" || evnt == undefined)
      this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
    else {
      if (type == 'byStr') {
        if (this.lstMats.filter(a => a.productName == evnt).length != 0)
          this.slctPrdId = this.lstMats.filter(a => a.productName == evnt)[0]?.productId;
        else
          this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
      }
      else {
        if (this.lstAllMats1.filter(a => a.name == evnt).length != 0)
          this.slctPrdId = this.lstAllMats1.filter(a => a.name == evnt)[0]?.id;
        else
          this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
      }
    }
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdMat = [...this.lstShowMatTags];
  }

  LoadBatches(indx: any, kId: any, mId: any, tId: any) {
    this.lstTrans[indx].lstBats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.txn, environment.apiMthds.
      getTxnBatchbyMatId.replace('kkk', kId).replace('mmm', mId).replace('ttt', tId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.lstTrans[indx].lstBats = this.result.data;
        }
      });
  }

  LoadStoreTransactions(shwSpnr: boolean = true) {
    let crntPgIndx = this.crntTransPage - 1, payLoad = this.invtrasLstFrm.getRawValue(), fT: any[] = [], mT: any[] = [];
    this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // .name
      payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null,
      payLoad.pranthId = this.chsnDmnId, payLoad.isActualTxn = this.isInitTxnDate,
      payLoad.txnsToDate = payLoad.txnsToDate ? this.glblSvc.setDtFrmt(payLoad.txnsToDate, this.dtF.ymd) + this.tmF.lastTM : null, // payLoad.txnType = payLoad.txnType == '' ? null : +payLoad.txnType;
      payLoad.storeBadge = fT.length != 0 ? fT : null, payLoad.productBadge = mT.length != 0 ? mT : null, this.lstTrans = [],
      payLoad.txnReason = payLoad.txnReason == '' ? null : payLoad.txnReason;
    if (this.isPgLd)
      payLoad.txnsFromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.txnsFromDate = payLoad.txnsFromDate ? this.glblSvc.setDtFrmt(payLoad.txnsFromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
    if (localStorage.getItem('isTransAPICald') == null) {
      localStorage.setItem('isTransAPICald', '1'), this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.transList.
        replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pgSze}`), payLoad, 0, shwSpnr).then(() => {
          this.isPgLd = false;
          if (this.result) {
            if (this.result.status == 'OK') {
              this.ttlTransItems = this.result.data?.totalRecordCount ?? 0, this.pageTransEntrs = this.GetPageSizeTxt(this.crntTransPage, this.pgSze, this.ttlTransItems);
              let result = this.result.data?.storesTransactionDTOs != null ? this.result.data?.storesTransactionDTOs : [];
              if (result)
                result.forEach((e: any) => { Object.assign(e, { isB: false, lstBats: [] }); }), this.lstTrans = result,
                  this.isShowDAT = this.isInitTxnDate;
            }
          }
          setTimeout(() => { localStorage.removeItem('isTransAPICald') }, 500);
        });
    }
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFCF.length === this.lstSlctdFacTags.length;
    else
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMCF.length === this.lstSlctdMatTags.length;
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

  onMultiSlctT(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFCF;
      else
        this.lstSlctdMatTags = this.lstMCF;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.invtrasLstFrm.get('district')?.setValue(null),
          this.invtrasLstFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.invtrasLstFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.invtrasLstFrm.get('district')?.enable(),
                  this.invtrasLstFrm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.invtrasLstFrm.get('block')?.enable();
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.invtrasLstFrm.get('district')?.setValue('null'), this.invtrasLstFrm.get('block')?.setValue('null'),
          this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
      else
        this.invtrasLstFrm.get('block')?.setValue('null'), this.invtrasLstFrm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.usrLvl == 'National')
      this.invtrasLstFrm.get('state')?.enable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
    if (this.usrLvl == 'State')
      this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.enable(), this.invtrasLstFrm.get('block')?.disable();
    if (this.usrLvl == 'District')
      this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.enable();
    if (this.usrLvl == 'Block')
      this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
  }
  // Trans List
  // CCE List
  LoadAssets(shwSpnr: boolean = true) {
    let payload = this.facltyDtlsFrm.getRawValue();
    payload.asetTypeId = payload.asetTypeId != '' ? +payload.asetTypeId : null, payload.storeId = +this.strId, payload.monitoringPoint = null,
      payload.statusId = payload.approvalStatus != '' ? payload.approvalStatus : null, payload.assetId = null, payload.assetTypes = this.asetType,
      this.asstsList = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.getassetdetailsbyfilter.
        replace('pageNo', (this.crntCCEPage - 1).toString()).replace('pageSize', this.pgSze.toString()), payload, 2, shwSpnr, 3).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (this.result.status == 'OK') {
                this.asstsList = this.result?.data?.assetsList != null ? this.result?.data?.assetsList : [], this.ttlCCEItems =
                  this.result.data?.totalCount ?? 0, this.pageCCEEntrs = this.GetPageSizeTxt(this.crntCCEPage, this.pgSze, this.ttlCCEItems);
              }
            }
          }
        })
  }

  selectedAlaram() {
    this.dratn = this.assetslstfrm.get('alarms')?.value == '1';
  }

  exportAstLst() {
    let payload = this.assetslstfrm.getRawValue();
    payload.asetTypeId = payload.asetTypeId != '' ? +payload.asetTypeId : null, payload.storeId = payload.storeId != '' ? this.slctdFcltyId :
      null, payload.monitoringPoint = null, payload.statusId = payload.approvalStatus != '' ? payload.approvalStatus : null, payload.assetId =
      null, payload.assetTypes = this.asetType, this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.
        exprtAstLst, payload, 3, true, 3).then(() => {
          if (this.result) {
            if (this.result.returnCode == 1 && this.result.status == "OK")
              this.glblSvc.onTstr('s', this.result.message);
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
        })
  }

  onClick(assetId: any) {
    if (assetId > 0)
      this.glblSvc.sbjctAssetId.next(assetId), this.router.navigate(['home/asst-ast-dtls']);
  }

  onAsstTypeChng(type: string, item: any, evntType: any) {
    if (type == 'asetTypeId') {
      if (evntType == 'os')
        this.asetType.push(item.id)
      else if (evntType == 'osa')
        this.asetType = [], this.astTypLst.filter(e => this.asetType.push(e.id))
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id)
      else
        this.asetType = [];
    }
  }

  onChngFcltyCCE(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if (evnt == '' || evnt == undefined)
      this.slctdFcltyId = null, this.assetslstfrm.get('storeId')?.setValue('');
    else {
      if (this.lstPrnts.filter(a => a.storeName == evnt).length != 0)
        for (let s = 0; s < this.lstPrnts.length; s++) {
          if (this.lstPrnts[s].storeName == evnt)
            this.slctdFcltyId = this.lstPrnts[s].storeId;
          if (evntType === 'str1')
            this.assetslstfrm.get('storeId')?.setValue(evnt);
        }
      else
        this.slctdFcltyId = null, this.assetslstfrm.get('storeId')?.setValue('');
    }
  }

  onChngReltn(event: any) {
    this.shwAlrmDrpdwn = event.target.value != '2';
  }

  onChngSNO() {
    let snVal = this.assetslstfrm.get('serialNumber')?.value;
    this.assetslstfrm.get('serialNumber')?.setValue(`${snVal}`.trim());
  }
  // CCE List
}