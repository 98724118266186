import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-tags',
  templateUrl: './cnfg-tags.component.html',
  styleUrls: ['./cnfg-tags.component.css']
})

export class CnfgTagsComponent extends BaseComponent implements OnInit {

  lstAllTags: any[] = []; lstMoreTags: any[] = []; lstAllMoreTags: any[] = []; tagCnt = 0; lstTabsData: any; lstTabsAddData: any; mcId = '';
  facName = ''; ptTxt = ''; stTxt = ''; otTxt = ''; rtTxt = ''; utTxt = ''; mtTxt = ''; irTxt = ''; noRcrdTxt = environment.TblNoRcrds;
  usrPrmsn: any = null; cnfgTagsPrmsn = true; enbleAddBtn = false;

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    glblSvc.sbjctMstrData.subscribe(s => this.lstMatMainCtgrs = s.matCats), glblSvc.sbjctBdgsList.subscribe(s => this.lstAllTags = s);
  }

  ngOnInit(): void {
    this.LoadData(), this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls?.
      domainPermissions : null;
    if (this.usrPrmsn != null)
      this.usrPrmsn?.Configurations?.filter((e: any) => { ((e.menuCode == 'mm_cf_ta_ac') ? (this.cnfgTagsPrmsn = e.action) : ''); });
  }

  LoadData() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
        environment.cnfgTypes.badge, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data)
            this.setTagsData(this.result?.data);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  GetMoreITs() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.badge, environment.apiMthds.getMoreIdntTags, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          let apiRslt = this.result?.data ?? [];
          this.lstAllMoreTags = [], this.lstMoreTags = [], apiRslt.forEach((e: any) => {
            if (this.lstTabsData.lstIR.filter((f: any) => f.badgeName === e.name).length === 0)
              this.lstAllMoreTags.push(e), this.lstMoreTags.push(e);
          });
        }
      }
    });
  }

  setTagsData(result: any) {
    this.lstTabsData = { lstPT: result?.productBadges ?? [], lstST: result?.storeBadges ?? [], lstUT: result?.userBadges ?? [],
      lstOT: result?.bookingBadges ?? [], lstMT: result?.producerBadges ?? [], lstRT: result?.routeBadges ?? [], lstIR: result?.
      storeBadgeRanks ?? [] }, this.lstTabsAddData = { productBadges: [], storeBadges: [], bookingBadges: [], routeBadges: [],
      userBadges: [], producerBadges: [], storeBadgeRanks: [] }, this.GetMoreITs();
  }

  onChngRnk(evnt: any, tagName: string, indx: number) {
    let tagData: any = this.lstTabsData.lstIR.filter((f: any) => f.badgeName === tagName)[0], val = evnt?.target?.value ?? null;
    if (val) {
      if (tagData?.id) {
        tagData.rank = +val;
        if (this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === tagData.storeBadgeId).length === 0)
          this.lstTabsAddData.storeBadgeRanks.push({ id: tagData.id, storeBadgeId: tagData.storeBadgeId, rank: +val });
        else
          this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === tagData.storeBadgeId)[0].rank = +val;
      }
      else {
        let newID: any = this.lstAllMoreTags.filter(f => f.name === tagName)[0].id;
        if (newID)
          this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === newID)[0].rank = +val;
      }
    }
    else {
      if (tagData?.id) {
        if (this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === tagData.storeBadgeId).length === 0)
          this.lstTabsAddData.storeBadgeRanks.push({ id: tagData.id, storeBadgeId: tagData.storeBadgeId, rank: null });
        else
          this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === tagData.storeBadgeId)[0].rank = null;
      }
      else {
        let newID: any = this.lstAllMoreTags.filter(f => f.name === tagName)[0].id;
        if (newID)
          this.lstTabsAddData.storeBadgeRanks.filter((f: any) => f.storeBadgeId === newID)[0].rank = null;
      }
    }
  }

  onSlctFclty(evnt: any) {
    let tagData: any = this.lstMoreTags.filter(f => f.name === evnt?.value);
    if (tagData.length > 0) {
      tagData = tagData[0], this.lstTabsData.lstIR.push({ id: null, badgeName: tagData.name, rank: null }), this.lstTabsAddData.
        storeBadgeRanks.push({ id: null, storeBadgeId: tagData.id, rank: null }), this.lstMoreTags = this.lstMoreTags.filter(f =>
        f.name != evnt?.value);
    }
    this.facName = '';
  }

  enbleAddBtnFn(event: any) {
    this.enbleAddBtn = (event?.target?.value?.trim().length > 0);
    /* if (event?.target?.value?.trim().length > 0)
      this.enbleAddBtn = true;
    else
      this.enbleAddBtn = false; */
  }

  onAddDel(ctrl: string, rowVal: any, actType: string) {
    let btId = (ctrl == 'productBadges' ? 1 : (ctrl == 'storeBadges' ? 2 : (ctrl == 'userBadges' ? 3 : ctrl == 'bookingBadges' ? 4 :
      (ctrl == 'producerBadges' ? 5 : (ctrl == 'routeBadges' ? 6 : 7))))), type = (ctrl == 'productBadges' ? 'lstPT' : (ctrl ==
      'storeBadges' ? 'lstST' : (ctrl == 'userBadges' ? 'lstUT' : ctrl == 'bookingBadges' ? 'lstOT' : (ctrl == 'producerBadges' ?
      'lstMT' : (ctrl == 'routeBadges' ? 'lstRT' : 'lstIR'))))), allBdgeMstrs = JSON.parse(<string>localStorage.getItem('appBdgs'));
    if (btId) {
      if (actType == 'a') {
        rowVal = `${rowVal}`.trim();
        if (this.lstTabsData[type].filter((f: any) => (f.name == rowVal)).length == 0)
          this.lstTabsAddData[ctrl].push({ name: rowVal, badgeTypeId: btId, badgeCategoryId: ctrl == 'productBadges' ? this.mcId : null });
        else
          this.glblSvc.onTstr('w', 'Tag is already exists');
      }
      else {
        if (rowVal.id) {
          if (confirm('Are you sure, you want to delete.?')) {
            this.FetchData(environment.apiCalls.del, btId < 7 ? environment.apiPaths.badge : environment.apiPaths.indntBdgeRank,
                environment.apiMthds.delById + rowVal.id, null, 2, true, 1).then(() => {
              if (this.result) {
                if (this.result.status == 'OK') {
                  if (btId < 7)
                    this.lstTabsData[type] = this.lstTabsData[type].filter((f: any) => f.name != rowVal.name);
                  else
                    this.lstTabsData[type] = this.lstTabsData[type].filter((f: any) => f.badgeName != rowVal.badgeName);
                  this.glblSvc.onTstr('s', this.result.message), this.LoadData();
                  if (allBdgeMstrs) {
                    this.glblSvc.getAPI(environment.apiPaths.badge, environment.apiMthds.getAll, 2, 1).subscribe(s => {
                      if (s)
                        allBdgeMstrs = s?.data ?? [], localStorage.setItem('appBdgs', JSON.stringify(allBdgeMstrs)),
                          this.glblSvc.sbjctBdgsList.next(allBdgeMstrs);
                    });
                  }
                }
                else
                  this.glblSvc.onTstr('e', this.result.message);
              }
            });
          }
        }
        else {
          this.lstTabsData[type] = this.lstTabsData[type].filter((f: any) => (btId < 7 ? f.name : f.badgeName) != (btId < 7 ? rowVal.name :
            rowVal.badgeName)), this.lstTabsAddData[ctrl] = this.lstTabsAddData[ctrl].filter((f: any) => (btId < 7 ? f.name : f.badgeName) !=
            (btId < 7 ? rowVal.name : rowVal.badgeName));
          if (btId == 7)
            this.lstMoreTags.push(this.lstAllMoreTags.filter(f => f.name == rowVal.badgeName)[0]), this.lstMoreTags.sort((a, b) => a.id - b.id);
        }
      }
      if (actType == 'a')
        this.onSave();
    }
  }

  onSave() {
    let payLoad: any = this.lstTabsAddData, rankCnt = 0;
    Object.assign(payLoad, { updatedBy: this.usrLgnDtls?.userId }), payLoad.storeBadgeRanks.forEach((e: any) => {
      if (e.rank == null || e.rank == undefined || e.rank == '' || e.rank == 0)
        rankCnt++;
    });
    if (rankCnt > 0)
      this.glblSvc.onTstr('w', 'Rank values should not be empty');
    else {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.tagCnfg + environment.apiMthds.
          saveRupdate, JSON.stringify(payLoad)).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message), this.tagCnt = 0;
            let allBdgeMstrs = JSON.parse(<string>localStorage.getItem('appBdgs'));
            if (allBdgeMstrs)
              this.glblSvc.getAPI(environment.apiPaths.badge, environment.apiMthds.getAll, 2, 1).subscribe(s => {
                if (s)
                  allBdgeMstrs = s?.data ?? [], localStorage.setItem('appBdgs', JSON.stringify(allBdgeMstrs)),
                    this.glblSvc.sbjctBdgsList.next(allBdgeMstrs);
              });
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
      setTimeout(() => {this.LoadData(); }, 1000);
    }
  }

}