import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { identity } from 'rxjs';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-assets',
  templateUrl: './cnfg-assets.component.html',
  styleUrls: ['./cnfg-assets.component.css']
})

export class CnfgAssetsComponent extends BaseComponent implements OnInit, AfterViewChecked {

  comuChnlLst: any[] = []; configAssetsForm: FormGroup; isSbmtd = false; defaultAsetCnfgData: any; astTypLst: any; activeTab: any;
  manufCLst: any[] = []; snsrLst: any[] = []; asetMdlLst: any[] = []; seleAsetId: any; asetData: any;
  noRcrdsTxt = environment.TblNoRcrds; dacd: any[] = []; sdcl: any; useDefaultConfiguration: any; defaultMonitoringPoint: any;
  copyModals: any[] = []; activeTab1: any; tab = 'A'; usrPrmsn: any = null; cnfgCCEPrmsn = true;


  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder, private readonly changeDetectorRef: ChangeDetectorRef) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.comuChnlLst = s.comuChnlLst, this.astTypLst = s.astTypLst }),
      this.seleAsetId = this.astTypLst[0]?.id, this.activeTab = this.astTypLst[0]?.name, this.defaultMonitoringPoint = this.astTypLst.filter((a: any) => a.id == this.seleAsetId)[0].defaultMonitoringPoint;
    this.configAssetsForm = this.formBuilder.group({
      configurationPullUrl: [], communicationChannelId: ['', Validators.required], alarmPushUrl: ['', Validators.required],
      disableAssetManagement: [false], enableAssetManagement: [false], enableAssetManagementLogistics: [false],
      alarmFrequencyNotificationNumber: [null], smsGatewayKeyword: [], smsGatewayPhoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      smsGatewaySenderId: [], lowBatteryAlarmThresholdLimit: [null], lowAlarmTemperatureDuration: ['', Validators.required], lowAlarmTemperature: ['', Validators.required],
      languageId: [], highWarningTemperatureDuration: [], highWarningTemperature: [0], highAlarmTemperatureDuration: ['', Validators.required],
      highAlarmTemperature: ['', Validators.required], deviceReadyStatusPushUrl: [], deviceAlarmPushNotification: [false],
      lowWarningTemperature: [], lowWarningTemperatureDuration: [], nameSpace: [], countryId: [],
      phoneNumbersToSendSmsNotification: [], powerOutageAlarmDuration: [], pushInterval: ['', Validators.required], samplingInterval: ['', Validators.required],
      wifiPassword: [], wifiSecurity: [], wifiSsid: [], alarmFrequencyNotificationDuration: [],
      temperaturePushUrl: ['', Validators.required], statsPushUrl: [], statsPushNotification: [false],
      temperatureIncursionExcursionPushNotification: [false], temperaturePushNotification: [false],
      defaultMonitoringPoint: [this.defaultMonitoringPoint ? this.defaultMonitoringPoint : null],
      model: [], modelFlag: [], vendorFlag: [],
      sensorDefaultConfigurationList: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.LoadConfigAssDefData(), this.getNestedData('mL', this.seleAsetId), this.getNestedData('sL', this.seleAsetId),
      this.getNestedData('amL', this.seleAsetId);

      this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
      if (this.usrPrmsn != null) {
        let cnfgLst: any[] = this.usrPrmsn?.Configurations;
        cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_cm_ac') ? (this.cnfgCCEPrmsn = e.action) : '') });
      }
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  get fc() { return this.configAssetsForm.controls; }

  onTabSelection(event: any) {
    this.seleAsetId = this.astTypLst.filter((a: any) => a.name == event)[0]?.id; this.activeTab = event;
    this.getNestedData('mL', this.seleAsetId), this.getNestedData('sL', this.seleAsetId), this.getNestedData('amL', this.seleAsetId);
    this.configAssetsForm.get('defaultMonitoringPoint')?.setValue(null);
  }

  getNestedData(type: any, seleAsetId: any) {
    this.configAssetsForm.get('defaultMonitoringPoint')?.setValue(this.astTypLst.filter((a: any) => a.id == seleAsetId)[0]?.
      defaultMonitoringPoint), this.manufCLst = [], this.snsrLst = [], this.asetMdlLst = [], this.asetData = {}, this.FetchData(environment.
        apiCalls.get, (type == 'mL' ? environment.apiPaths.getAsetTyp : (type == 'sL' ? environment.apiPaths.getAsetModl : environment.
        apiPaths.asetMdl)), (type == 'mL' ? environment.apiMthds.getAsetTyp : (type == 'sL' ? environment.apiMthds.getAsetMdl : environment.
        apiMthds.getAsetMdlTyp)).replace('id', seleAsetId), null, (type == 'mL' || type == 'sL' ? 2 : (type == 'amL' ? 13 : 13)), true,
          (type == 'mL' || type == 'sL' ? 3 : (type == 'amL' ? 2 : 2))).then(() => {
      if (this.result) {
        if (type == 'mL')
          this.manufCLst = this.result.data != null ? this.result.data : [];
        else if (type == 'sL')
          this.snsrLst = this.result.data != null ? this.result.data : [];
        else {
          // this.asetMdlLst = this.result.data != null ? this.result.data : [];               
          let result = this.result.data != null ? this.result.data : [];
          result.forEach((e: any) => { Object.assign(e, { isChkd: e.vendorIsActive }); }), this.asetMdlLst = result;
          this.copyModals = [...this.asetMdlLst];
        }
      }
    });
  }

  LoadConfigAssDefData() {
    this.GetDefaultConfgs().controls.length = 0, this.FetchData(environment.apiCalls.get, environment.apiPaths.asetDefCnfg, environment.apiMthds.getOne, null, 2, true, 3).then(() => {
      if (this.result) {
        this.defaultAsetCnfgData = this.result.data
        if (this.defaultAsetCnfgData) {
          this.dacd = this.defaultAsetCnfgData, this.sdcl = this.defaultAsetCnfgData?.sensorDefaultConfigurationList[0],
            this.updateForm(this.dacd);
          this.activeTab1 = this.defaultAsetCnfgData?.sensorDefaultConfigurationList[0].sensorId;
          if (this.defaultAsetCnfgData?.sensorDefaultConfigurationList.length != this.GetDefaultConfgs().controls.length)
            this.sortAryLst(this.defaultAsetCnfgData?.sensorDefaultConfigurationList, 'sensorId')
          this.defaultAsetCnfgData?.sensorDefaultConfigurationList.forEach((data: any) => {
            this.GetDefaultConfgs().push(this.onAddFormCtrls(data));
          })
        }
      }
    });
  }

  updateForm(d: any) {
    this.configAssetsForm.patchValue({
      configurationPullUrl: d.configurationPullUrl, communicationChannelId: d.communicationChannelId,
      disableAssetManagement: d.disableAssetManagement, enableAssetManagement: d.enableAssetManagement,
      enableAssetManagementLogistics: d.enableAssetManagementLogistics,
      alarmPushUrl: d.alarmPushUrl, alarmFrequencyNotificationNumber: d.alarmFrequencyNotificationNumber,
      smsGatewayKeyword: d.smsGatewayKeyword, smsGatewayPhoneNumber: d.smsGatewayPhoneNumber,
      smsGatewaySenderId: d.smsGatewaySenderId, lowBatteryAlarmThresholdLimit: d.lowBatteryAlarmThresholdLimit,
      lowAlarmTemperatureDuration: d.lowAlarmTemperatureDuration, lowAlarmTemperature: d.lowAlarmTemperatureDuration, languageId: d.languageId,
      highWarningTemperatureDuration: d.highWarningTemperatureDuration, highWarningTemperature: d.highWarningTemperature,
      highAlarmTemperatureDuration: d.highAlarmTemperatureDuration, highAlarmTemperature: d.highAlarmTemperature,
      deviceReadyStatusPushUrl: d.deviceReadyStatusPushUrl, deviceAlarmPushNotification: d.deviceAlarmPushNotification,
      lowWarningTemperature: d.lowWarningTemperature, lowWarningTemperatureDuration: d.lowWarningTemperatureDuration, nameSpace: d.nameSpace,
      countryId: d.countryId, phoneNumbersToSendSmsNotification: d.phoneNumbersToSendSmsNotification, powerOutageAlarmDuration: d.powerOutageAlarmDuration,
      pushInterval: d.pushInterval, samplingInterval: d.samplingInterval, wifiPassword: d.wifiPassword, wifiSecurity: d.wifiSecurity,
      wifiSsid: d.wifiSsid, alarmFrequencyNotificationDuration: d.alarmFrequencyNotificationDuration,
      temperaturePushUrl: d.temperaturePushUrl, statsPushUrl: d.statsPushUrl, statsPushNotification: d.statsPushNotification,
      temperatureIncursionExcursionPushNotification: d.temperatureIncursionExcursionPushNotification,
      temperaturePushNotification: d.temperaturePushNotification
    });
  }

  onSaveConfigAssets() {
    this.checkFormValidations(this.configAssetsForm);
    if (this.configAssetsForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.configAssetsForm.getRawValue(), sdcL = payLoad.sensorDefaultConfigurationList;
      
      payLoad.updatedBy = this.usrLgnDtls?.userId,
     
      payLoad.alarmFrequencyNotificationDuration = payLoad.alarmFrequencyNotificationDuration != '' ? +payLoad.alarmFrequencyNotificationDuration : null;
      payLoad.alarmFrequencyNotificationNumber = payLoad.alarmFrequencyNotificationNumber != '' ? +payLoad.alarmFrequencyNotificationNumber : null;
      payLoad.communicationChannelId = payLoad.communicationChannelId != '' ? +payLoad.communicationChannelId : null;
      payLoad.highAlarmTemperature = payLoad.highAlarmTemperature != '' ? +payLoad.highAlarmTemperature : null;
      payLoad.highAlarmTemperatureDuration = payLoad.highAlarmTemperatureDuration != '' ? +payLoad.highAlarmTemperatureDuration : null;
      payLoad.highWarningTemperature = payLoad.highWarningTemperature != '' ? +payLoad.highWarningTemperature : null;
      payLoad.highWarningTemperatureDuration = payLoad.highWarningTemperatureDuration != '' ? +payLoad.highWarningTemperatureDuration : null;
      payLoad.lowAlarmTemperature = payLoad.lowAlarmTemperature != '' ? +payLoad.lowAlarmTemperature : null;
      payLoad.lowAlarmTemperatureDuration = payLoad.lowAlarmTemperatureDuration != '' ? +payLoad.lowAlarmTemperatureDuration : null;
      payLoad.lowBatteryAlarmThresholdLimit = payLoad.lowBatteryAlarmThresholdLimit != '' ? +payLoad.lowBatteryAlarmThresholdLimit : null;
      payLoad.lowWarningTemperature = payLoad.lowWarningTemperature != '' ? +payLoad.lowWarningTemperature : null;
      payLoad.lowWarningTemperatureDuration = payLoad.lowWarningTemperatureDuration != '' ? +payLoad.lowWarningTemperatureDuration : null;
      payLoad.powerOutageAlarmDuration = payLoad.powerOutageAlarmDuration != '' ? +payLoad.powerOutageAlarmDuration : null;
      payLoad.pushInterval = payLoad.pushInterval != '' ? +payLoad.pushInterval : null;
      payLoad.samplingInterval = payLoad.samplingInterval != '' ? +payLoad.samplingInterval : null;
      payLoad.wifiPassword = 'ABCDE', payLoad.wifiSecurity = 'Security', payLoad.wifiSsid = 'SSID'
      , delete payLoad.model, delete payLoad.defaultMonitoringPoint, delete payLoad.modelFlag, 
      delete payLoad.vendorFlag;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.asetDefCnfg, environment.apiMthds.saveRupdate, payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message)
            if (this.result.message === 'Record saved successfully')
              this.LoadConfigAssDefData(), this.getNestedData('mL', this.seleAsetId), this.getNestedData('sL', this.seleAsetId),
                this.getNestedData('amL', this.seleAsetId);
          } else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  selectAll($event: any) {
    this.copyModals.filter(it => {
      it.vendorIsActive = $event.target.checked;
    });
  }

  GetDefaultConfgs(): FormArray {
    return this.configAssetsForm.get('sensorDefaultConfigurationList') as FormArray;
  }

  onAddFormCtrls(a: any) {
    return this.formBuilder.group({
      alarmFrequencyNotificationDuration: a.alarmFrequencyNotificationDuration,
      alarmFrequencyNotificationNumber: a.alarmFrequencyNotificationNumber,
      createdBy: a.createdBy,
      createdOn: a.createdOn,
      deviceAlarmPushNotification: a.deviceAlarmPushNotification,
      highAlarmTemperature: a.highAlarmTemperature,
      highAlarmTemperatureDuration: a.highAlarmTemperatureDuration,
      highWarningTemperature: a.highWarningTemperature,
      highWarningTemperatureDuration: a.highWarningTemperatureDuration,
      id: a.id,
      isDeleted: a.isDeleted,
      lowAlarmTemperature: a.lowAlarmTemperature,
      lowAlarmTemperatureDuration: a.lowAlarmTemperatureDuration,
      lowWarningTemperature: a.lowWarningTemperature,
      lowWarningTemperatureDuration: a.lowWarningTemperatureDuration,
      pushInterval: a.pushInterval,
      samplingInterval: a.samplingInterval,
      sensorId: a.sensorId,
      statsPushNotification: a.statsPushNotification,
      temperatureAlarmPushNotifications: a.temperatureAlarmPushNotifications,
      temperatureIncursionExcursionPushNotification: a.temperatureIncursionExcursionPushNotification,
      temperaturePushNotification: a.temperaturePushNotification,
      updatedBy: a.updatedBy,
      updatedOn: a.updatedOn,
      useDefaultConfiguration: a.useDefaultConfiguration
    });
  }

  //checkBox Active
  checkBoxActive(event: any, index: number, idNumber: number){
    if(idNumber === 1){
      this.copyModals[index].vendorIsActive = event.target.checked;
    } else{
      this.copyModals[index].modelIsActive = event.target.checked;
    }
  }
  //Tab Save 
  saveManufaturere(tabName: any) {
    if (tabName == 'Temperature Logger') {
      let tempPayLoad = [];
      for (let i = 0; i < this.copyModals.length; i++) {       
          tempPayLoad.push({
            assetModelId: this.copyModals[i].modelId,
            assetModelIsActive: this.copyModals[i].modelIsActive,
            defaultSensor: this.copyModals[i].defaultCensor,
            updatedBy: this.usrLgnDtls?.userId,
            vendorId: this.copyModals[i].assetVendorId,
            vendorIsActive: this.copyModals[i].vendorIsActive
          })            
      }
      //Temp Insertion
      this.FetchData(environment.apiCalls.post, environment.apiPaths.asetMdl, environment.apiMthds.svRupdate, tempPayLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message);
          } else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });



    } else {

      let checkedPayload = [];
      for (let i = 0; i < this.asetMdlLst.length; i++) {
        checkedPayload.push({
          id: this.asetMdlLst[i].assetVendorId,
          isActive: this.asetMdlLst[i].isChkd
        })
      }   

      let monitoringPoint = this.configAssetsForm.value.defaultMonitoringPoint;
      if(monitoringPoint != null){
        let payLoad = {
          assetTypeId: this.seleAsetId,
          defaultMonitoringPoint: monitoringPoint,
          updatedBy: this.usrLgnDtls?.userId,
          vendorList: checkedPayload
        }
        this.FetchData(environment.apiCalls.post, environment.apiPaths.asetVendor, environment.apiMthds.svRupdate, payLoad).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              this.glblSvc.onTstr('s', this.result.message);
            } else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      } else{
        this.glblSvc.onTstr('e', 'Select default monitoring point');
      }
     
    }
  }

  //Temparature change
  onDefaultSensorChange(event: any, index: any){
    this.copyModals[index].defaultCensor = event.target.value;
  }
}