<form [formGroup]="orderlstfrm" autocomplete="off" class="p-2">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-row row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Facility</label>
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Facility"
                                formControlName="storeId" [typeahead]="lstStrs" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName" id="Facility"
                                (typeaheadOnSelect)="onChngFclty($event, 'slct')" (change)="onChngFclty($event, 'chng')"
                                [adaptivePosition]="true" [attr.disabled]="usrStrLen==1?true:null" 
                                [typeaheadOptionsLimit]="500" class="form-control form-control-sm" />
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Status</label>
                            <select id="inputState" class="form-select form-select-sm" formControlName="status">
                                <option value="">All</option>
                                <option *ngFor="let os of orderStatusList" [value]="os.name">{{os.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">From</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="fromDate"
                                    [(ngModel)]="frmDate" placeholder="From" [maxDate]="dt2day" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('fromDate')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">To</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                    [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)"
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="toDate"
                                    bsDatepicker [minDate]="frmDate" [maxDate]="dt2day" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                        [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpED.hide();clearBSDate('toDate')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row row mb-2">
                        <div class="form-group col-md-4_5">
                            <label class="textBox">Material By</label>&nbsp;
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="category"
                                    [(ngModel)]="isStkMatBdge" (click)="lvlBdg1='';onClkRdb('category')"
                                    [checked]="isStkMatBdge=='category'" formControlName="isStkMatBdge">
                                <label class="form-check-label" for="mat">Category</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="badge"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('materialBadge')"
                                    formControlName="isStkMatBdge" [checked]="isStkMatBdge=='badge'">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="name"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('material')"
                                    [checked]="isStkMatBdge=='name'" formControlName="isStkMatBdge">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div class="w-67">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="stkMtrlCat"
                                    (onSelect)="onMultiSlct('s', 'c', $event)" (onSelectAll)="onMultiSlct('a', 'c', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'c', $event)" (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                                    [data]="lstMatMainCtgrs" *ngIf="isStkMatBdge=='category'">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdStkMat"
                                    (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags1" *ngIf="isStkMatBdge=='badge'"
                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="stkMtrlBdge">
                                </ng-multiselect-dropdown>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats"
                                    (onSelect)="onMultiSlct('s', 'f', $event)" *ngIf="isStkMatBdge=='name'"
                                    (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="stkMtrlNme">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-12">
                    <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                        aria-controls="box1">Advance Search</button>
                </div>
            </div>
            <div class="clearfix"></div>
            <div id="box1" class="collapse">
                <div class="form-row row mt-2">
                    <div class="form-group col-xl-3">
                        <label for="textBox">Indent Id</label>
                        <input type="text" placeholder="Indent Id" class="form-control form-control-sm" id="bookingID"
                            formControlName="bookingId">
                    </div>
                    <div class="form-group col-xl-3" *ngIf ="false">
                        <label for="textBox">Issue reference #</label>
                        <input type="text" placeholder="Issue reference #" class="form-control form-control-sm" id="issueReference"
                            formControlName="issueReferenceId">
                    </div>
                    <div class="form-group col-xl-3" *ngIf="false">
                        <label for="textBox">Receipt reference #</label>
                        <input type="text" placeholder="Receipt reference #" class="form-control form-control-sm" id=""
                            formControlName="receiptReferenceId">
                    </div>
                    <div class="form-group col-xl-3" hidden>
                        <label for="textBox">Approval status</label>
                        <select id="inputState" class="form-select form-select-sm" formControlName="approvalStatus">
                            <option value="null">All</option>
                            <option *ngFor="let os of orderStatusList" [value]="os.name">{{os.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-5">
                        <span class="pl-xl-3">Facility Category:
                            <span *ngIf="isFacAll">ALL</span>
                            <span *ngIf="!isFacAll && lstShowFacTags.length!=0">
                                <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                    {{fc.name}}
                                </span>
                            </span>
                        </span>&nbsp;
                        <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                            <i class="fa fa-filter"></i>
                        </span>
                        <div class="row">
                            <div class="col">
                                <div class="collapse multi-collapse" id="multiCollapseExample1">
                                    <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <div class="">
                                                        <select class="form-select form-select-sm" formControlName="storeBadgeId">
                                                            <option value="null">-Select-</option>
                                                            <option *ngFor="let sb of lstFacTags" [value]="sb.id">{{sb.name}}</option>
                                                        </select>                                                        
                                                        <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                            [data]="lstFacTags" formControlName="storeBadgeId"                                                                        
                                                            (onSelect)="onMultiSlct('s', 'f', $event)"
                                                            (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                            (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                            (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                            [(ngModel)]="slctdFac">
                                                        </ng-multiselect-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-outline-light me-2"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample1" role="button"
                                                        href="#multiCollapseExample1"
                                                        (click)="onResetTags('f')">Reset</button>
                                                    <button class="btn btn-sm btn-primary me-2" role="button"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample1"
                                                        href="#multiCollapseExample1"
                                                        (click)="onTagsSave('f')">Save</button>
                                                    <button class="btn btn-sm btn-light" (click)="onCncl('f')"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample1" role="button"
                                                        href="#multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-3 mb-3">
                        <label for="inputEmail4" class="col-form-label">Indent category:</label>
                        <div>
                            <select class="form-select form-select-sm" aria-label="Default select example" formControlName="bookingBadgeId">
                                <option value="null">All</option>
                                <option *ngFor="let ot of lstTags" [value]="ot.id">{{ot.name}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-xl-6">
                        <span class="pl-xl-3">Indent Category:
                            <span *ngIf="isOrdAll">ALL</span>
                            <span *ngIf="!isOrdAll && lstShowOrdTags.length != 0">
                                <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowOrdTags">
                                    {{mc.name}}
                                </span>
                            </span>
                        </span>&nbsp;
                        <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                            <i class="fa fa-filter"></i>
                        </span>
                        <div class="row">
                            <div class="col">
                                <div class="collapse multi-collapse" id="multiCollapseExample2">
                                    <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <div class="">  
                                                        <select class="form-select form-select-sm" formControlName="bookingBadgeId">
                                                            <option value="null">-Select-</option>
                                                            <option *ngFor="let sb of lstTags" [value]="sb.id">{{sb.name}}</option>
                                                        </select>                                       
                                                        <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                            [data]="lstTags" formControlName="bookingBadgeId"
                                                            (onSelect)="onMultiSlct('s', 'm', $event)"
                                                            (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                            (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                            [(ngModel)]="slctdOrd">
                                                        </ng-multiselect-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-outline-light me-2"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample2" role="button"
                                                        href="#multiCollapseExample2"
                                                        (click)="onResetTags('m')">Reset</button>
                                                    <button class="btn btn-sm btn-primary me-2"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample2"
                                                        href="#multiCollapseExample2" role="button"
                                                        (click)="onTagsSave('m')">Save</button>
                                                    <button class="btn btn-sm btn-light" (click)="onCncl('m')"
                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                        aria-controls="multiCollapseExample2" role="button"
                                                        href="#multiCollapseExample2">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;LoadOrders()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="ordersList?.length!=0">
                <div class="col-xl-4 mb-2">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>
            <div class="row mb-2" *ngIf="ordersList?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                    <!-- <span style="display: inline-block;" class="me-2">
                        <label tyle="display: inline-block;">Filter</label>
                    </span>
                    <span style="display: inline-block;">
                        <select class="form-select form-select-sm">
                            <option>5</option>
                            <option>10</option>
                        </select>
                    </span> -->
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-striped table-sm table-bordered" id="table_detail">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Indent Id</th>
                            <th class="text-center">Items</th>
                            <th>Status</th>
                            <th>Facility</th>
                            <th>Created</th>
                            <th>Issuing Facility</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="table_data">
                        <tr *ngFor="let item of ordersList | search:searchText:filterMetadata |
                            paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                            let i=index" (click)="onOrdIdClk(item.bookingId)">
                            <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                            <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                            <td class="text-center">{{item.bookingId}}</td>
                            <td class="text-center">{{item.bookingItemsCount}}</td>
                            <td>{{item.status}}</td>
                            <td>
                                <div class="small"><a>{{item.recevingStoreName}}</a></div>
                                <div class="small">{{item.recevingStoreLocation}}</div>
                            </td>
                            <td>
                                <a>{{item.createdBy}}</a>
                                <div class="small">{{item.createdOn | date: "dd/MM/yyyy hh:mm aa"}}</div>
                            </td>
                            <td>
                                <a>{{item.issuingStoreName}}</a>
                                <div class="small">{{item.issuingStoreLocation}}</div>
                            </td>
                            <td class="text-center" style="width: 7%;">
                                <span class="f-14">
                                    <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.sourceType==1"></i>
                                    <i class="fa fa-desktop" aria-hidden="true" *ngIf="item.sourceType==2"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="ordersList?.length==0 || count==0">
                        <tr>
                            <td colspan="9" class="text-center">
                                {{noRcrdsTxt}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="ordersList?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>