import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { CnclOrderComponent } from 'src/app/Components/evin/Pop-ups/cncl-order/cncl-order.component';

declare let $: any;

@Component({
  selector: 'evin-shipments-status',
  templateUrl: './shipments-status.component.html',
  styleUrls: ['./shipments-status.component.css']
})

export class ShipmentsStatusComponent extends BaseComponent implements OnInit {

  shipmentStatus: any; shipmentProgress: any; lstMats: any[] = []; midWdthVal = 16.5; cargoId: any; lstAllComments: any[] = []; comment: any;
  noRcrdsTxt = environment.TblNoRcrds; shpmntstatus = this.formBuilder.group({ cargoId: [''] }); shipmentHistory: any[] = []; cmntStat = false;
  userlgndtls : any = null; mmIdDtlsAc: boolean = true; mmIdDtlsCs: boolean = true; mmIdDtlsEc: boolean = true; mmIdDtlsEt: boolean = true;
  mmIdDtlsFs: boolean = true;

  constructor(public glblSvc: GlobalService
    , private ngbMdl: NgbModal
    , public formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
  }

  ngOnInit(): void {
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_sh_sd_ac') this.mmIdDtlsAc = e.action;
      if (e.menuCode == 'mm_id_sh_sd_cs') this.mmIdDtlsCs = e.action; if (e.menuCode == 'mm_id_sh_sd_ec') this.mmIdDtlsEc = e.action;
      if (e.menuCode == 'mm_id_sh_sd_et') this.mmIdDtlsEt = e.action; if (e.menuCode == 'mm_id_sh_sd_fs') this.mmIdDtlsFs = e.action;}) : null;
    }
    this.glblSvc.sbjctShipmentId.subscribe((s: any) => { this.cargoId = s;
      $(this).nextAll().removeClass("active"), $("#line").css("background", `#fff`), $("#line-progress").css("width", `0%`);
      if (s > 0)
        this.LoadShipmentStatus(this.cargoId), this.LoadMats(this.cargoId), this.loadComments(this.cargoId); //setTimeout(() => { this.orderS(); }, 1000);
      else
        this.router.navigate(['home/ord-ship'], { queryParams: { tab: 'list' } });
    });
  }

  LoadShipmentStatus(cargoId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cargo, environment.apiMthds.getCargosById.replace('sss', cargoId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.shipmentStatus = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
      this.LoadProgressbar(this.shipmentStatus.bookingId);
    });
  }

  LoadMats(cargoId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cargo, environment.apiMthds.getProductsByCargoId.replace('sss', cargoId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.result.data.forEach((e: any) => { Object.assign(e, { lstB: [] }); }), this.lstMats = this.result.data != null ? this.result.data : [];
          // else
          //   this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadBatches(productId: any, indx: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cargo, environment.apiMthds.getBatMatByCargoId.replace('sss', this.cargoId).replace('mmm', productId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstMats[indx]['lstB'] = this.result.data != null ? this.result.data : [];
      }
    });
  }

  LoadProgressbar(bookingId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.bookingtracking, environment.apiMthds.getshipmentstatusbar.
        replace('ooo', bookingId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.shipmentProgress = this.result.data != null ? this.result.data : [];
        setTimeout(() => { this.orderS(); }, 1000);
      }
    });
  }

  orderS() {
    if (this.shipmentStatus?.status != 'Cancelled') {
      // for (let i = 0; i < this.shipmentProgress.length; i++) {
        let val = ((this.shipmentProgress.filter((f: any) => f.active == true).length - 1) * 50);  //+ this.midWdthVal;
        $(this).nextAll().removeClass("active"), $("#line").css("background", `#fff`), $("#line-progress").css("width", `${val}%`)
        return;
      // }
    }
    else {
      $(this).nextAll().removeClass("active"), $("#line").css("background", `#ff0000`), $("#line-progress").css("width", `75%`)
      return;
    }
  }

  LoadHstry() {
    this.FetchData(environment.apiCalls.get, '', environment.apiMthds.getShipmenthistory.replace('sss', this.cargoId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.shipmentHistory = this.result.data != null ? this.result.data : [];
      }
    });
  }

  loadComments(cargoId: any) {
    this.lstAllComments = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.booking, environment.apiMthds.getComments.replace('type', 'Cargo').replace('typeId', cargoId), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllComments = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  saveComments() {
    let payLoad = { bookingId: null, cargoId: this.cargoId, createdBy: this.usrLgnDtls.userId,
      comments: this.comment, createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, entryFrom: 'Cargo' };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.saveComments, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'message added successfully')
          this.glblSvc.onTstr('s', this.result.message), this.loadComments(this.cargoId), this.comment = '', this.cmntStat = false;
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  cancelComment() {
    this.cmntStat = false, this.comment = '';
  }

  onInvc() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.cargo, environment.apiMthds.getInvcShip +
        this.shipmentStatus?.cargoNo, {}).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.OpenFileInNewTab(this.result?.data?.downloadurlpath);
      }
    });
  }

  onCncl() {
    if (this.shipmentStatus.bookingId) {
      const modalRef = this.ngbMdl.open(CnclOrderComponent, { scrollable: true, size: 'md', backdrop: 'static', animation: true });
      modalRef.componentInstance.bkngId = this.shipmentStatus.bookingId;
      modalRef.componentInstance.page = 'Cargo';
      modalRef.result.then((result: any) => {
        if (result == 'done')
          this.router.navigate(['home/ord-ship'], { queryParams: { tab: 'sts' } }), this.ngOnInit();
      }, (r: any) => { console.log(r) });
    }
  }

}