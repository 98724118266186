<body style="background-color: #ffffff !important;">
    <div class="row scroll">
        <div class="col-xl-7 col-md-7">
            <img class="bg-img-cover bg-center" src="assets/naya-imgs/login_bg.jpg" alt="Evin login page"
                style="width: 100%; height: 100vh;">
        </div>
        <div class="col-xl-5 col-md-5 col-sm-12 p-0 bg_img_cover_clr">
            <div class="login-card" style="align-items: baseline !important;">
                <div>
                    <div>
                        <a class="logo mb-1">
                            <img class="img-fluid" src="assets/naya-imgs/avin-login-logo.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main shadow">
                        <!-- <form class="theme-form" [formGroup]="restPswdFrm"> -->
                            <h4>Reset Password</h4>
                            <div class="form-group mt-2">
                                <label class="col-form-label pt-0">Enter OTP</label>
                                <div class="row">
                                    <div class="col">
                                        <input class="form-control form-control-sm text-center opt-text" maxlength="2" id="otp1"
                                            type="text" [(ngModel)]="o1" [ngClass]="{ 'is-invalid': isSave && o1.length != 2 }"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onOTP(1)">
                                    </div>
                                    <div class="col">
                                        <input class="form-control form-control-sm text-center opt-text" maxlength="2" id="otp2"
                                            type="text" [(ngModel)]="o2" [ngClass]="{ 'is-invalid': isSave && o2.length != 2 }"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onOTP(2)">
                                    </div>
                                    <div class="col">
                                        <input class="form-control form-control-sm text-center opt-text" autocomplete="new-password" maxlength="2" id="otp3"
                                            type="text" [(ngModel)]="o3" [ngClass]="{ 'is-invalid': isSave && o3.length != 2 }"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onOTP(3)">
                                    </div>
                                    <!-- <div *ngIf="isSave && (o1==''||o2==''||o3=='')" class="invalid-feedback col-md-12">
                                        Please Enter OTP
                                    </div> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-2">
                                    <div class="float-start" *ngIf="!showReSnd">
                                        Resend option will be appear in {{tmr}} seconds..
                                    </div>
                                    <div class="float-start" *ngIf="showReSnd">
                                        <span class="reset-password-link">If you didn't receive OTP? 
                                        <a class="btn-link text-danger" (click)="onResend()">Resend</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">New Password</label>
                                <input class="form-control" id="rstNewPswd"  type="password" autocomplete="new-password"
                                    [ngClass]="{ 'is-invalid': isSave && newPW == '' }" [(ngModel)]="newPW">
                                <div class="show-hide">
                                    <span [ngClass]="isShwHde ? 'hide' : 'show'" (click)="onShwHde()"></span>
                                </div>
                                <!-- <div *ngIf="isSave && newPW==''" class="invalid-feedback col-md-12">
                                    Please fill New Password
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Retype Password</label>
                                <input class="form-control" type="password" [(ngModel)]="rtPW" placeholder=""
                                    [ngClass]="{ 'is-invalid': isSave && rtPW == '' }">
                                <!-- <div *ngIf="isSave && newPW==''" class="invalid-feedback col-md-12">
                                    Please fill Retype Password
                                </div> -->
                            </div>
                            <div class="col-12 mt-2">
                                <button class="btn btn-primary btn-sm float-end mt-1 ms-2" type="submit" (click)="isSave=true;onSave()">Save</button>
                                <button class="btn btn-light btn-sm float-end mt-1" type="button" (click)="onBack()">Cancel</button>
                            </div>
                            <div class="clearfix"></div>
                        <!-- </form> -->
                    </div>
                    <p class="small text-center mt-3 text-muted">© 2021 ELECTRONIC VACCINE INTELLIGENCE NETWORK</p>
                </div>
            </div>
        </div>
    </div>
</body>