import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})

export class OrdersComponent extends BaseComponent implements OnInit {

  paramTab: any; paramType: any; isShowCOD: boolean = true; userIndent: any; userlgndtls: any = null; mmIdLst: boolean = true;
  cnfgVal: any = null; isOrdLstHdn: boolean = false; isOrdCrtHdn: boolean = false; mmCrtId: boolean = true;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public glblSvc: GlobalService) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab, this.paramType = params.type; });
  }

  ngOnInit(): void {      
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_ids_li') this.mmIdLst = e?.action;
      if (e.menuCode == 'mm_id_ids_ci') this.mmCrtId = e?.action;}) : null;
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null;
      this.isOrdLstHdn = this.cnfgVal?.permissionRoles[11]?.configuredValue?.value ?? false,
      this.isOrdCrtHdn = this.cnfgVal?.permissionRoles[9]?.configuredValue?.value ?? false;
    }
    if (!this.mmIdLst && this.mmCrtId && !this.isOrdCrtHdn)
      this.onTabSelection('new'), setTimeout(() => { document.getElementById('creat-order-tab')?.click(); }, 1000);
      // if(this.mmIdLst || !this.isOrdLstHdn)
      // this.onTabSelection('list');
      // else if(this.mmCrtTxn || !this.isOrdCrtHdn)
      // this.onTabSelection('new');
    if (this.paramTab == 'list' && this.paramType == 'i' && localStorage.getItem('ordLst') != null)
      setTimeout(() => { localStorage.removeItem('ordLst'), location.reload(); }, 200);
  }

  onTabSelection(tabName: string) {
    this.updateQueryParams({ tab: tabName }); // , this.glblSvc.sbjctTabsPage.next(`indnt-${tabName}`);   
    // if (this.usrLgnDtls) {
    //   if (this.usrLgnDtls.permissions != null) {
    //     let crtOrdPrmsns = this.usrLgnDtls.permissions.filter((pm: any) => pm.parentModuleId === 1)[0]?.modulePermissionRoles.
    //       filter((mm: any) => mm.moduleId === 1)[0]?.permissionRoles.filter((pr: any) => pr.permissionId === 4)[0]?.configuredValue;
    //     this.isShowCOD = crtOrdPrmsns != null ? !crtOrdPrmsns.value : true;
    //   }
    // }
    if(tabName == 'list')
      window.location.reload();
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}